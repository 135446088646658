import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'ui-redesign/components';
import { Text, Title, Image, SquaredButton } from 'ui-redesign/elements';

import { GrowSection, ContentWrapper } from './FarmGrow.styled';

const Grow = ({ title, body, callToAction, mediaContent, isDesktop }) => (
  <GrowSection>
    <ContentSection>
      <ContentWrapper>
        <Title modifiers={['inverted', 'lineHeightNormal']} as='h3' content={title} />
        <Image picture={mediaContent?.fields?.file?.url} nativeWidth={isDesktop ? 2000 : 970} />
      </ContentWrapper>
      <ContentWrapper>
        <Text modifiers='inverted' content={body} isHTML />
        <SquaredButton
          as='button'
          label={callToAction?.fields?.linkText || callToAction?.fields?.text}
          href={callToAction?.fields?.linkUrl || callToAction?.fields?.url}
          modifiers='secondary'
        />
      </ContentWrapper>
    </ContentSection>
  </GrowSection>
);

Grow.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
  mediaContent: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default Grow;
