import React, { useState, useEffect, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { openCart } from 'redux/cartCheckout/cartCheckout';
import { getLoginState } from 'redux/user';

import { Image, Icon } from 'ui-redesign/elements';
import Logo from './assets/logo.svg';
import NavStyled, { NavButtonsWrappers, Spacer, CartWrapper, IconWrapper } from './Navigation.styled';
import Sandwich from './Sandwich';
import Menu from './Menu';

import { ReactComponent as ChevronLeft } from 'ui-redesign/elements/icon/assets/left.svg';
import { ReactComponent as ChevronRight } from 'ui-redesign/elements/icon/assets/right.svg';
import { ReactComponent as CartIcon } from 'ui-redesign/elements/icon/assets/cart.svg';
import { ReactComponent as CartFilledIcon } from 'ui-redesign/elements/icon/assets/filled-cart.svg';
import { ReactComponent as UserIcon } from 'ui-redesign/elements/icon/assets/user.svg';
import { ReactComponent as ChevronIcon } from 'ui-redesign/elements/icon/assets/chevron-thin.svg';
import { ReactComponent as SearchIcon } from 'ui-redesign/elements/icon/assets/search.svg';

import paths from 'constants/paths';
import { useToggleBodyScrolling } from 'utils/hooks';
import retry from 'utils/retry';

const Search = lazy(() => retry(() => import('./Search')));
const AccountMenu = lazy(() => retry(() => import('./AccountMenu')));

const Navigation = () => {
  const history = useHistory();
  const cart = useSelector((state) => state.cartCheckout);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const [isSearchExpanded, setIsSearchExpanded] = useState(location.pathname.includes(paths.SEARCH_RESULTS));
  const isLoggedIn = useSelector(getLoginState);
  const isFromMobile = useSelector((state) => state.user.isFromMobile);
  useToggleBodyScrolling(isMenuOpen);

  // close menu if menu is open when route changes
  useEffect(() => {
    setIsMenuOpen(false);
    setIsSubMenuOpen(false);

    if (!location.pathname.includes(paths.SEARCH_RESULTS)) {
      setIsSearchExpanded(false);
    }
  }, [location]);

  const checkoutItemQty = cart.checkout.lineItems.reduce((total, item) => {
    total += item.quantity;
    return total;
  }, 0);

  const cartItemQty = checkoutItemQty || 0;

  const handleCartOpen = () => {
    dispatch(openCart());
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleSearchExpanded = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };

  const goBack = () => history?.goBack?.();

  const goForward = () => history?.goForward?.();

  return (
    <>
      <Spacer />
      <NavStyled>
        {!isFromMobile && <Sandwich isMenuOpen={isMenuOpen} onClick={handleMenuToggle} />}
        {isFromMobile && (
          <NavButtonsWrappers>
            <Icon modifiers='small' onClick={goBack}>
              <ChevronLeft />
            </Icon>
            <Icon modifiers='small' onClick={goForward}>
              <ChevronRight />
            </Icon>
          </NavButtonsWrappers>
        )}

        <Link to={paths.HOME}>
          <Image picture={Logo} alt='logo' />
        </Link>

        <>
          <Menu isOpen={isMenuOpen} />
          <div className='NavigationAccount' data-nw='account-menu-icon'>
            <IconWrapper onClick={handleSubMenuToggle}>
              <Icon modifiers={['small', isLoggedIn && 'brandStroke']} onClick={handleSubMenuToggle}>
                <UserIcon />
              </Icon>
              <Icon modifiers={['small', isSubMenuOpen ? 'rotate90' : 'rotate270']} onClick={handleSubMenuToggle}>
                <ChevronIcon />
              </Icon>
            </IconWrapper>
            <AccountMenu isOpen={isSubMenuOpen} />
          </div>
          <Icon modifiers='small' onClick={toggleSearchExpanded}>
            <SearchIcon></SearchIcon>
          </Icon>
          <CartWrapper data-nw='cart-button'>
            <Icon modifiers='small' onClick={handleCartOpen}>
              {cartItemQty > 0 ? <CartFilledIcon /> : <CartIcon />}
            </Icon>
            <span className='cart-icon__qty'>{cartItemQty <= 99 ? cartItemQty : '99+'}</span>
          </CartWrapper>
        </>
      </NavStyled>
      <Search onClose={toggleSearchExpanded} isExpanded={isSearchExpanded} />
    </>
  );
};

Navigation.defaultProps = {};
Navigation.propTypes = {};

export default Navigation;
