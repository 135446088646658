import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { LinkStyled } from './Link.styled';

const Link = ({ content, modifiers, as, href, target, children }) => {
  const history = useHistory();

  const handleClick = () => {
    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isNewTab = target?.includes('blank');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

    if (isNewTab) {
      window.open(href);
    } else if (isHardCodedProdLink) {
      const pathPattern = /lettucegrow.com(\/\S+)/;
      const path = href.match(pathPattern);
      path && path[1] && history.push(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && history.push(href);
    }
  };

  const handleEnter = (ev) => {
    if (ev?.keyCode === 13) {
      handleClick();
    }
  };

  return (
    <LinkStyled modifiers={modifiers} as={as} target={target} onClick={handleClick} tabIndex={0} onKeyDown={handleEnter}>
      {content || children}
    </LinkStyled>
  );
};

Link.propTypes = {
  content: PropTypes.any,
  as: PropTypes.string,
  modifiers: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.any,
};

export default Link;
