import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { addDiscount, getCheckout } from 'redux/cartCheckout/cartCheckout';
import { setReservationCode } from 'redux/reservation/reservation';
import { setUserValue } from 'redux/user';

/**
 * * useQueryParams - Custom hook to grab query params and store values in redux on page load
 * shouldMonitor - sref_id, res, pc
 * */
export default function useQueryParams(shouldMonitor = []) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const checkout = useSelector(getCheckout);
  const isCheckoutLoading = useSelector((state) => state.cartCheckout.isLoading);
  const searchParams = queryString.parse(location.search || '');
  const [hasAddedPromoListener, setHasAddedPromoListener] = useState(false);
  let params;

  // location does not parse queries after hash/anchor - so check the hash property as well for queries
  if (location.hash) {
    const query = location.hash.split('?');
    params = { ...searchParams, ...queryString.parse(query[1] || '') };
  } else {
    params = searchParams;
  }

  const reservation = useSelector((state) => state.reservation);
  const { isCatalogFetched } = useSelector((state) => state.catalog);

  // Check if promo code or email is present and store in redux
  useEffect(() => {
    if (!shouldMonitor.includes('pc')) return;
    // TODO: can I remove pc logic?
    if (params.pc) {
      dispatch(addDiscount({ code: params.pc }));
    }
    if (params.email) dispatch(setUserValue({ label: 'email', value: params.email }));
    // eslint-disable-next-line
  }, [params.pc]);

  useEffect(() => {
    if (shouldMonitor.includes('res') && isCatalogFetched && params.res && params.res !== reservation.code) {
      history.push(location.pathname);
      dispatch(setReservationCode(params.res));
    }
  }, [shouldMonitor, dispatch, history, location.pathname, params.res, reservation.code, isCatalogFetched]);

  useEffect(() => {
    const handleCoupon = (event) => {
      const existingCode = checkout.discountApplications.find((discount) => discount.code === event.detail.coupon_code);
      !existingCode && !isCheckoutLoading && dispatch(addDiscount({ code: event.detail.coupon_code }));
    };
    // handle the generation of the coupon code from Yotpo
    if (!hasAddedPromoListener && params.sref_id && shouldMonitor.includes('sref_id')) {
      window.document.addEventListener('swell:referred:customer:discount', handleCoupon);
      setHasAddedPromoListener(true);
    }
    return () => {
      hasAddedPromoListener && window.document.removeEventListener('swell:referred:customer:discount', handleCoupon);
    };
  }, [checkout.discountApplications, dispatch, hasAddedPromoListener, isCheckoutLoading, params.sref_id, shouldMonitor]);

  return { promoCode: params.pc, source: params.utm_source, res: params.res, referralCode: params.sref_id };
}
