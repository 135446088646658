import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const modifiersButtonInput = {
  disabled: () => ({
    styles: `
      opacity: 0.2;
      pointer-events: none;
    `,
  }),
};

export const modifiersIncrementInput = {
  fluid: () => ({
    styles: `
      width: 99%;
      margin: 0 auto;
      display: block;
      .Input {
        width: 100%;
      }
    `,
  }),
};

export const IncreaseInputStyled = styled.div.attrs({
  className: 'IncreaseInput',
})`
  position: relative;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.farmProject.brand_primary};

  // loading state
  .Button {
    height: 1.65rem;
  }

  ${applyStyleModifiers(modifiersIncrementInput)}
`;

export const Input = styled.input.attrs({
  className: 'Input',
})`
  height: 2.1rem;
  text-align: center;
  box-sizing: border-box;
  padding: 0.25rem 2rem;
  border: none;
  color: ${({ theme }) => theme.farmProject.dark_gray};
  background-color: transparent;
  pointer-events: none;
`;

export const BtnInput = styled.button.attrs({
  className: 'BtnInput',
})`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translate(-50%, -50%);

  span {
    font-size: 1.125rem;
    display: block;
    color: ${({ theme }) => theme.farmProject.green};
    position: relative;
    bottom: 0.13rem;
    cursor: pointer;
  }

  &:last-of-type {
    left: auto;
    right: -0.25rem;
    span {
      bottom: 0;
    }
  }

  ${applyStyleModifiers(modifiersButtonInput)}
`;
