import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const modifiers = {
  brandColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.brand_primary};
    `,
  }),
  accentColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.accent_primary};
    `,
  }),
  darkGrayColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.dark_gray};
    `,
  }),
  fontFamilyText: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family_text};
    `,
  }),
  underline: () => ({
    styles: `
      text-decoration: underline;
    `,
  }),
  small: () => ({
    styles: `
      font-size: 0.7rem;
    `,
  }),
  tiny: () => ({
    styles: `
      font-size: 0.65rem;
    `,
  }),
};

export const LinkStyled = styled.a.attrs({
  className: 'Link',
})`
  font-family: ${({ theme }) => theme.farmProject.font_family};
  font-size: 0.8rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05rem;
  text-decoration: none;
  text-align: left;
  color: ${({ theme }) => theme.farmProject.gray_3};
  padding: 0;
  margin: 0;
  cursor: pointer;

  p {
    text-decoration: underline;
  }

  :hover,
  :hover *,
  :focus * {
    color: ${({ theme }) => theme.farmProject.brand_primary};
    transition: all 0.2s ease;
  }
  ${applyStyleModifiers(modifiers)};
`;
