import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

import { SlimBanner } from 'ui-redesign/components';
import { Text, Icon } from 'ui-redesign/elements';
import { ReactComponent as SavingsIcon } from 'ui-redesign/elements/icon/assets/savings.svg';

import { useQueryParams, useContent, useBreakpoint } from 'utils/hooks';
import { resetReservation } from 'redux/reservation/reservation';
import { getCheckout } from 'redux/cartCheckout/cartCheckout';
import { PROMO_BANNER } from 'constants/contentful';

const PromoBanner = ({ isAbbreviated }) => {
  let [text, setText] = useState('');

  const { promoCode } = useQueryParams('pc');
  const cart = useSelector(getCheckout);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const content = useContent(PROMO_BANNER);
  const viewportSize = useBreakpoint();

  useEffect(() => {
    if (!content?.[0]) return;
    if (!!promoCode && cart.userErrors?.find((error) => error.field?.[0]?.value === 'discountCode')) {
      if (isAbbreviated || viewportSize === 'SM') {
        setText('OOPS, THAT CODE CANNOT BE APPLIED');
      } else {
        // if promo code in url is not valid
        setText('OOPS, THAT GIFT CARD CANNOT BE REDEEMED. <a href="/contact-us">CONTACT US</a> IF YOU NEED ASSISTANCE. ');
      }
    } else if (reservation.hasExpired) {
      setText('OOPS, THAT LINK HAS EXPIRED. <a href="/contact-us">CONTACT US</a> IF YOU NEED ASSISTANCE. ');
    } else if (!_isEmpty(cart.discountApplications)) {
      // generic code message for promo or credit
      if (isAbbreviated || viewportSize === 'SM') {
        setText(`${promoCode || cart.discountApplications[0].code} HAS BEEN APPLIED!`);
      } else {
        setText(`CONGRATS, YOUR CODE ${promoCode || cart.discountApplications[0].code} HAS BEEN APPLIED!`);
      }
    }
  }, [promoCode, cart, content, reservation, isAbbreviated, viewportSize]);

  useEffect(() => {
    return () => {
      if (reservation.hasExpired) {
        // reset on unmount so we don't show expired message again
        dispatch(resetReservation());
      }
    };
    /* eslint-disable-next-line */
  }, []);

  if (!content?.[0] || (_isEmpty(cart.discountApplications) && _isEmpty(cart.userErrors) && !reservation.hasExpired)) return null;

  return (
    <SlimBanner modifiers='lightGreen'>
      {!_isEmpty(cart.discountApplications) && (
        <Icon modifiers='small'>
          <SavingsIcon></SavingsIcon>
        </Icon>
      )}
      <Text content={text} isHTML modifiers={['brandFont', 'small']}></Text>
    </SlimBanner>
  );
};

PromoBanner.defaultProps = {};
PromoBanner.propTypes = {
  isAbbreviated: PropTypes.bool,
};

export default PromoBanner;
