import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StarRating, Accordion } from 'ui-redesign/components';
import { Text, Image, Icon } from 'ui-redesign/elements';

import {
  ReviewContentStyled,
  AnswerNumberCta,
  Answer,
  CustomerInfo,
  CustomerAvatar,
  Logo,
  CustomerInfoContent,
  ImageWrappersReview,
} from './ReviewContent.styled';

import { ReactComponent as ReviewIcon } from 'ui-redesign/elements/icon/assets/review-icon.svg';
import { ReactComponent as Check } from 'ui-redesign/elements/icon/assets/circle-check.svg';
import { ReactComponent as LogoIcon } from 'assets/images/loading.svg';

import { formatShortDate } from 'utils/date-utils';

const ReviewContent = ({ review, isOnModal, setModalContent }) => {
  const [commentsVisible, setCommentsVisible] = useState({});

  const toggleCommentsVisible = (reviewId) => () => {
    let updatedVisibleCommentsByReviewId;
    if (commentsVisible[reviewId]) {
      updatedVisibleCommentsByReviewId = { ...commentsVisible, [reviewId]: false };
    } else {
      updatedVisibleCommentsByReviewId = { ...commentsVisible, [reviewId]: true };
    }
    setCommentsVisible(updatedVisibleCommentsByReviewId);
  };

  const onOpenPictureModal = (content) => {
    if (content && setModalContent) setModalContent(content);
  };

  return (
    <ReviewContentStyled>
      <StarRating readOnly rating={review?.score} />
      <Text isHTML modifiers={['brandFont', 'bold', 'xLarge']} content={review?.title} />
      <Text isHTML content={review?.content} />
      {!isOnModal && review?.images_data?.length > 0 && (
        <ImageWrappersReview>
          {review.images_data.map((image) => (
            <Image key={image.thumb_url} onClick={() => onOpenPictureModal(review)} picture={image.thumb_url} alt='user review' />
          ))}
        </ImageWrappersReview>
      )}
      {review?.comment?.content && (
        <AnswerNumberCta onClick={toggleCommentsVisible(review?.id)}>
          <Icon>
            <ReviewIcon />
          </Icon>
          <Text modifiers={['brandFont', 'noLineHeight']} content='(1) Comment' />
        </AnswerNumberCta>
      )}
      <Accordion
        isOpen={review?.comment?.id && commentsVisible[review?.id]}
        content={
          <Answer key={review?.comment?.id}>
            <CustomerInfo>
              <CustomerAvatar>
                <Logo>
                  <LogoIcon />
                </Logo>
                <Icon>
                  <Check />
                </Icon>
              </CustomerAvatar>
              <CustomerInfoContent>
                <Text modifiers={['noLineHeight', 'brandColor']} content='Lettuce Grow' />
                <Text modifiers={['noLineHeight', 'brandFont']} content={formatShortDate(review?.comment?.created_at)} />
              </CustomerInfoContent>
            </CustomerInfo>
            <Text content={review?.comment?.content} />
          </Answer>
        }
      />
    </ReviewContentStyled>
  );
};

ReviewContent.propTypes = {
  review: PropTypes.object,
  isOnModal: PropTypes.bool,
  setModalContent: PropTypes.func,
};

ReviewContent.defaultProps = {
  review: {},
  isOnModal: false,
  setModalContent: null,
};

export default ReviewContent;
