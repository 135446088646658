import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getDayMonthFormat } from 'utils/date-utils';
import { Title, Text } from 'ui-redesign/elements';
import { useBreakpoint } from 'utils/hooks';
import { AddToCart } from 'ui-redesign/components';
import { TextTooltipWrapper } from 'ui-redesign/components';
import growingEnvironments from 'constants/growingEnvironments';

import {
  HeroProductDetailStyled,
  BackgroundMobile,
  BackgroundDesktop,
  ImageWrapper,
  HeroInfoWrapper,
  ProductInfo,
} from './HeroProductDetail.styled';
import itemAvailabilities from 'constants/itemAvailabilities';

const HeroProductDetail = ({ name, item, headline, introImage, introImageMobile, introSentence, children, indoorApproved }) => {
  const screenSize = useBreakpoint();
  const messages = useSelector((state) => state.contentful.messages);
  const userEnvironment = useSelector((state) => state.user.userSetEnvironment);
  const isMobile = screenSize === 'SM' || screenSize === 'MD';
  const isNotInSeason = item?.availability === itemAvailabilities.OUT_OF_SEASON;
  const isBuyableSeedNotSeason = isNotInSeason && !item?.inStockDate && !!item?.inSeasonDate;
  const isCompleteOutSeason = isNotInSeason && !item?.inStockDate && !item?.inSeasonDate;
  const isSeedOutOfStock = isNotInSeason && !!item?.inStockDate;

  if (!item) return null;

  let seasonWrapper = '';
  if (isBuyableSeedNotSeason) {
    const helpText = (messages || {}).LG_buyableSeedlingHelpText || '';
    const bestAfterDate = getDayMonthFormat(item.inSeasonDate);
    const growsBestText = helpText.replace('DATE_TAG', bestAfterDate);
    seasonWrapper = <TextTooltipWrapper label={`${messages?.LG_seasonalSeedling} ${bestAfterDate}`} tooltipText={growsBestText} />;
  } else if (isSeedOutOfStock) {
    const availableDate = getDayMonthFormat(item.inStockDate);
    seasonWrapper = <TextTooltipWrapper label={`Available ${availableDate}`} />;
  } else if (item.shipsOnDate) {
    const shipsOnDate = getDayMonthFormat(item.shipsOnDate, true);
    seasonWrapper = <TextTooltipWrapper label={`Ships ${shipsOnDate}`} />;
  }
  if (userEnvironment === growingEnvironments.INDOOR && !indoorApproved) {
    seasonWrapper = <TextTooltipWrapper label='Not recommended for indoor growing' labelModifiers={['small', 'brandColor']} />;
  }

  return (
    <HeroProductDetailStyled>
      <ImageWrapper>
        {isMobile ? (
          <BackgroundMobile nativeWidth={970} picture={introImageMobile?.fields?.file?.url} />
        ) : (
          <BackgroundDesktop nativeWidth={2000} picture={introImage?.fields?.file?.url} />
        )}
      </ImageWrapper>
      <HeroInfoWrapper>
        <ProductInfo>
          <Title as='h2' modifiers={['secondary']} content={name?.toUpperCase()} />
          <Text as='span' modifiers={['brandFont', 'xLarge']} content={headline} />
          <Text modifiers='noLineHeight' content={introSentence} />
        </ProductInfo>
        {children}
        <AddToCart item={item} showPrice={true} isSoldOut={isSeedOutOfStock} isOutOfSeason={isCompleteOutSeason} />
        {seasonWrapper}
      </HeroInfoWrapper>
    </HeroProductDetailStyled>
  );
};

HeroProductDetail.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  headline: PropTypes.string,
  introImage: PropTypes.object,
  introImageMobile: PropTypes.object,
  introSentence: PropTypes.string,
  indoorApproved: PropTypes.bool,
  children: PropTypes.object,
};

export default HeroProductDetail;
