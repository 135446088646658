import { getLoginState, userLogin, setUserValue, userLogout, fetchMyFarm, fetchMyOrders } from 'redux/user';
import { fetchCatalog } from 'redux/catalog';
import { environment } from 'utils/envConfig';
import { getStorage } from 'utils/storageManager';

import { identify } from 'utils/klaviyo-utils';

/**
 * * Redux Middleware for User actions
 *
 */

const userMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const onUserLogin = action.type === userLogin.toString() && getState().user.isGuest === false;
  const onIsUnguest = action.type === setUserValue.toString() && action.payload.label === 'isGuest' && action.payload.value === false;
  if (onUserLogin || onIsUnguest) {
    identify({ user: getState().user, customer: getState().customer });

    if (environment === 'production') {
      const lgUserId = getStorage('lgClientId');
      lgUserId &&
        window.LogRocket &&
        window.LogRocket.identify(lgUserId, {
          email: getState().user.email,
          name: getState().user.firstName + ' ' + getState().user.lastName,
        });
    }

    dispatch(fetchMyFarm());
    dispatch(fetchMyOrders());
  }
  // if logging out from checkuot page ensure address verification flag is reset
  if (action.type === userLogout.toString()) {
    dispatch(fetchCatalog(getState().user.zip, getState().user.email, getLoginState(getState())));
  }
};

export default userMiddleware;
