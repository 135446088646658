import React from 'react';
import styled from 'styled-components/macro';

// Styled "Section"
const Main = styled.main`
  height: 50vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    object-fit: contain;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Log error
    import('utils/errorHandler').then((logError) => logError?.default(error, errorInfo));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Main>
          <p>We're sorry - something's gone wrong.</p>
          <p>Please try to refresh the page or call us for help - (512) 234-4001</p>
        </Main>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
