import { createSlice, createSelector } from '@reduxjs/toolkit';

import { AXIOS } from 'redux/middleware/apiMiddleware';
import { YOTPO_WIDGET, YOTPO_QUESTIONS, YOTPO_PRODUCTS } from 'constants/thirdPartyUrls';
import paths from 'constants/paths';
import { lgUrlPrefix } from 'utils/envConfig';

/**
 * * UGC reducer - yotpo integrated reviews, questions, answers, social
 *
 * @param state
 *
 */

// export const YOTPO_APP_KEY = 'i3bQNyNgKXeH5K0FIN17FwHuR91dWGIxnlgH45oO'; // dev app key
export const YOTPO_APP_KEY = 'jL6ppQ9ezsuJXIojSAtNnlm2hd3DX8lAzrj7pAE2';

export const YOTPO_FARMSTAND_ID = 'generic-farmstand';
export const YOTPO_GLOW_RINGS_ID = 'generic-glow-rings';

export const productMap = {
  [YOTPO_FARMSTAND_ID]: { id: YOTPO_FARMSTAND_ID, title: 'The Farmstand', url: `${lgUrlPrefix}${paths.FARMSTAND}` },
  [YOTPO_GLOW_RINGS_ID]: { id: YOTPO_GLOW_RINGS_ID, title: 'Glow Rings', url: `${lgUrlPrefix}${paths.shopPages.GLOW_RINGS.SIZE_4}` },
};

const initialState = {
  cumulativeReview: {
    total: 0,
    average: 0,
    byRating: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  },
  reviews: {
    [YOTPO_FARMSTAND_ID]: { all: [], total: 0, average: 5 },
    [YOTPO_GLOW_RINGS_ID]: { all: [], total: 0, average: 5 },
  },
  questions: { [YOTPO_FARMSTAND_ID]: [], [YOTPO_GLOW_RINGS_ID]: [] },
  socialPosts: [],
  loading: {
    questions: true,
  },
};

const userGeneratedSlice = createSlice({
  name: 'userGenerated',
  initialState,
  reducers: {
    setLoadingReviews(state, { payload }) {
      state.loading.reviews = payload;
    },
    setReviews(state, { payload }) {
      const averageDivisor = state.cumulativeReview.average === 0 ? 1 : 2;
      // merge cumulative review data here for farmstand and glow ring ratings b/c most reliable API, when this is the first fetch for reviews of product type
      if (!state.reviews[payload.productId].all.length) {
        Object.keys(state.cumulativeReview.byRating).forEach((key) => {
          state.cumulativeReview.byRating[key] += payload.response.bottomline.star_distribution[key];
        });
        state.cumulativeReview.total += payload.response.bottomline.total_review;
        state.cumulativeReview.average =
          (state.cumulativeReview.average + (payload.response.bottomline.average_score || 5)) / averageDivisor;
      }

      state.reviews[payload.productId].all = payload.response.reviews.map((review) => ({
        ...review,
        product_id: payload.productId,
      }));
      state.reviews[payload.productId].total = payload.response.pagination.total;
      state.reviews[payload.productId].average = payload.response.bottomline.average_score;
    },
    setQuestions(state, { payload }) {
      state.questions[payload.productId] = payload.response.questions.map((question) => ({
        ...question,
        product_id: payload.productId,
      }));
    },
    setSocialPosts(state, { payload }) {
      state.socialPosts = payload;
    },
    setLoadingQuestions(state, { payload }) {
      state.loading.questions = payload;
    },
  },
});

const dateSort = (a, b) => {
  if (a.created_at < b.created_at) {
    return -1;
  } else if (a.created_at > b.created_at) {
    return 1;
  } else {
    return 0;
  }
};

// if no id is provided, return all product questions
export const getQuestionsById = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.questions[YOTPO_FARMSTAND_ID].concat(state.userGenerated.questions[YOTPO_GLOW_RINGS_ID]).sort(dateSort);
      }
      return state.userGenerated.questions[productId];
    },
  ],
  (questions) => questions
);

// if no id is provided, return all product reviews
export const getReviewsById = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.reviews[YOTPO_FARMSTAND_ID].all
          .concat(state.userGenerated.reviews[YOTPO_GLOW_RINGS_ID].all)
          .sort(dateSort);
      }
      return state.userGenerated.reviews[productId].all;
    },
  ],
  (reviews) => reviews
);

// if no id is provided, return total number of all product reviews
export const getTotalReviews = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.reviews[YOTPO_FARMSTAND_ID].total + state.userGenerated.reviews[YOTPO_GLOW_RINGS_ID].total;
      }
      return state.userGenerated.reviews[productId].total;
    },
  ],
  (total) => total
);

export const getCumulativeReview = createSelector([(state) => state.userGenerated], (userGenerated) => userGenerated.cumulativeReview);
export const getSocialPosts = createSelector([(state) => state.userGenerated], (userGenerated) => userGenerated.socialPosts);

const { actions, reducer } = userGeneratedSlice;

export const { setQuestions, setLoadingQuestions, setReviews, setCumulativeReview, setLoadingReviews, setSocialPosts } = actions;

export const fetchReviews = (productId, pageSize = 5, pageNum = 1) => {
  const url = `${YOTPO_WIDGET}/${YOTPO_APP_KEY}/products/${productId}/reviews.json?per_page=${pageSize}&page=${pageNum}&sort=date`;
  return {
    type: AXIOS,
    payload: {
      url,
      method: 'GET',
      onSuccess: (response) => setReviews({ ...response, productId }),
      setLoading: setLoadingReviews,
    },
  };
};

export const fetchQuestions = (productId) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_PRODUCTS}/${YOTPO_APP_KEY}/${productId}/questions`,
      method: 'GET',
      onSuccess: (response) => setQuestions({ ...response, productId }),
      setLoading: setLoadingQuestions,
    },
  };
};

export const postQuestion = (data) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_QUESTIONS}/send_confirmation_mail`,
      method: 'POST',
      data,
    },
  };
};

export const postReview = (data) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_WIDGET}/reviews`,
      method: 'POST',
      data,
    },
  };
};

export default reducer;
