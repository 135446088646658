import store from 'redux/store';
import { axiosPost } from 'utils/api-utils';
import { setLettuceModal } from 'redux/lettuceModal';

/**
 * * emailSignup - Newsletter Signup Submit
 *
 * @param {Object} emailObj - params object
 * @param {string} emailObj.email - user email for signup
 * @param {string} emailObj.formType - 'footerNewsletter' || 'lettuce'
 * @param {string} [emailObj.zip] - user zip code for signup
 *
 * @return {} axios response or null if error
 * */

export const emailSignup = async ({ email, formType, zip, newsletterOptIn = true }) => {
  // Trigger GTM event - form submit attempt
  window.dataLayer.push({
    event: 'formSubmitAttempt',
    formId: 'newsletter',
  });

  const body = {
    email,
    zip,
    newsletterOptIn,
    formType,
  };

  const response = await axiosPost('/app/public/newsletterSignup', body, { withCredentials: true });

  let event;
  if (response.status === 200) {
    event = 'formSubmitted';
    store.dispatch(setLettuceModal({ label: 'hasUserSubmittedEmail', value: true }));

    if (newsletterOptIn) {
      // Send email capture event to GTM
      window.dataLayer.push({
        event: 'EmailCapture',
        emailCaptureSource: formType,
        email,
      });
    }
  } else {
    event = 'formSubmitFailure';
  }

  // Trigger GTM event based on whether or not we got a response from BE
  window.dataLayer.push({
    event,
    formId: 'newsletter',
  });

  return response;
};

export default emailSignup;
