import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Text } from 'ui-redesign/elements';
import { BackButtonStyled } from './BackButton.styled';

import { ReactComponent as Arrow } from 'ui-redesign/elements/icon/assets/chevron-thin.svg';

const BackButton = ({ label, onClick = null, modifiers }) => {
  return (
    <BackButtonStyled onClick={onClick} modifiers={modifiers}>
      <Icon>
        <Arrow />
      </Icon>
      <Text as='span' content={label} />
    </BackButtonStyled>
  );
};

BackButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  modifiers: PropTypes.any,
};

export default BackButton;
