import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { useBreakpoint } from 'utils/hooks';
import { Title, Text, Image } from 'ui-redesign/elements';

import { HeroSectionStyled, HeroInner } from './HeroSection.styled';

import scrollIntoView from 'utils/scrollIntoView';

const getBackgroundWidth = (size) => {
  switch (size) {
    case 'SM':
      return 1000;
    case 'MD':
      return 1300;
    case 'LG':
      return 1400;
    default:
      return 1920;
  }
};

const HeroSection = ({
  heroTitle,
  heroText,
  heroText2,
  imagebackground,
  altImage,
  modifiers,
  children,
  imageRouteLink,
  id,
  shouldResizeBackground,
  backgroundWidth,
  className,
}) => {
  const location = useLocation();
  const size = useBreakpoint();
  const containerRef = useCallback(
    (node) => {
      if (!!node && location) {
        scrollIntoView(location, node, -100);
      }
    },
    [location]
  );

  return (
    <HeroSectionStyled modifiers={modifiers} id={id} ref={containerRef} className={className}>
      <HeroInner>
        <Title as='h2' content={heroTitle} isHTML />
        <Text content={heroText} isHTML />
        <Text content={heroText2} isHTML />
        {children}
        {imagebackground && (
          <Image
            picture={imagebackground}
            alt={altImage}
            imageRouteLink={imageRouteLink}
            nativeWidth={shouldResizeBackground && (backgroundWidth || getBackgroundWidth(size))}
          />
        )}
      </HeroInner>
    </HeroSectionStyled>
  );
};

HeroSection.propTypes = {
  heroTitle: PropTypes.string,
  heroText: PropTypes.string,
  heroText2: PropTypes.string,
  imagebackground: PropTypes.string,
  altImage: PropTypes.string,
  modifiers: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string, PropTypes.object]),
  imageRouteLink: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  shouldResizeBackground: PropTypes.bool,
  backgroundWidth: PropTypes.number,
};

HeroSection.defaultProps = {
  id: null,
  shouldResizeBackground: true,
};

export default HeroSection;
