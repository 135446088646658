import { createSlice } from '@reduxjs/toolkit';

/**
 * * Lettuce Modal - Redux Reducer
 *
 * Lettuce Modal Content type from Contentful
 *
 * @param state
 *
 */

// reducer, action types, action creators all in 1 createSlice

const lettuceModalSlice = createSlice({
  name: 'lettuceModal',
  initialState: { hasUserSubmittedEmail: false, visitsUntilOpen: 0, isForceHidden: false },
  reducers: {
    // set fetched data into store
    setLettuceModal(state, { payload }) {
      state[payload.label] = payload.value;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = lettuceModalSlice;

// Extract and export action creators from slice by name
export const { setLettuceModal } = actions;

// Export the reducer as the default
export default reducer;
