import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import { getLoginState } from 'redux/user';
import useContent from 'utils/hooks/useContent';
import { getOpenModal, setOpenModal, setCloseModal } from 'redux/modal';
import { CLIMATE_HEATER_MODAL, CLIMATE_GOOD_GROW_MODAL, CLIMATE_LIGHTING_MODAL } from 'constants/contentful';

import { SquaredButton, Title, Text, Image, Link } from 'ui-redesign/elements';
import { Modal } from 'ui-redesign/components/modal';
import { ClimateModalStyled, ImageLink } from './ClimateModal.styled';

import ZipCodeEditor from 'ui-redesign/components/zip-code-editor/ZipCodeEditor';

const WITH_HEATER = 'WITH_HEATER';
const INDOOR = 'INDOOR';

const ClimateModal = ({ showNotice }) => {
  const isLoggedIn = useSelector(getLoginState);
  const messages = useSelector((state) => state.contentful.messages);
  const recommendation = useSelector((state) => state.catalog.packRecommendation);
  const openModal = useSelector(getOpenModal);
  const heaterContent = useContent(CLIMATE_HEATER_MODAL, openModal.id === 'climate');
  const lightingContent = useContent(CLIMATE_LIGHTING_MODAL, openModal.id === 'climate');
  const goodToGrowContent = useContent(CLIMATE_GOOD_GROW_MODAL, openModal.id === 'climate');
  const climateModal = useSelector((state) => state.modal.climate);
  const dispatch = useDispatch();
  let content;

  useEffect(() => {
    let timeout;
    if (!climateModal.hasBeenOpen && recommendation?.environment === WITH_HEATER) {
      // wait X seconds to pop
      const secondsToWait = content?.[0]?.fields?.secondsToWait;
      timeout = setTimeout(() => dispatch(setOpenModal('climate')), secondsToWait * 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [climateModal.hasBeenOpen, content, heaterContent, recommendation, dispatch]);

  const handleCloseModal = () => {
    dispatch(setCloseModal());
  };

  if (!recommendation) return null;

  let notice;
  if (recommendation.environment === WITH_HEATER) {
    content = heaterContent;
    notice = messages ? messages.LG_climateNoticeHeater : '';
  } else if (recommendation.environment === INDOOR) {
    content = lightingContent;
    notice = messages ? messages.LG_climateNoticeLighting : '';
  } else {
    content = goodToGrowContent;
  }

  return (
    <>
      {!!content && (
        <ClimateModalStyled>
          <Modal isOpen={openModal.id === 'climate'} onCloseAction={handleCloseModal}>
            <ZipCodeEditor isReadOnly={isLoggedIn} modifiers='small' />
            <Title as='h2' content={content[0].fields.title} />
            <Text modifiers='xLarge' isHTML content={content[0].fields.subHeaderText} />
            <Link
              href={_get(content[0].fields.callout, 'fields.callToAction.fields.linkUrl')}
              target={_get(content[0].fields.callout, 'fields.callToAction.fields.target')}
              rel='noopener noreferrer'
            >
              <ImageLink>
                <Image picture={_get(content[0].fields.callout, 'fields.mediaContent.fields.file.url')} />
                <Text content={_get(content[0].fields.callout, 'fields.callToAction.fields.linkText')} />
              </ImageLink>
            </Link>
            <SquaredButton
              as='a'
              onClick={handleCloseModal}
              label={_get(content[0].fields.callout, 'fields.title')}
              href={_get(content[0].fields.callout, 'fields.subTitle')}
            />
          </Modal>
        </ClimateModalStyled>
      )}
      {showNotice && notice && <Text content={notice} modifier='small' isHTML />}
    </>
  );
};

ClimateModal.defaultProps = {
  showNotice: false,
};

ClimateModal.propTypes = {
  showNotice: PropTypes.bool,
};

export default ClimateModal;
