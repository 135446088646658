import _isEmpty from 'lodash/isEmpty';

/**
 * @param {string} string - The original string value
 * @param {Object[]} replacements - List of replcaments to be made
 * @param {string} replacements[].key - The substring to be replaced
 * @param {string} replacements[].value - The string to replace
 */
export const replaceText = (string, replacements = []) => {
  const hasCorrectTypes = typeof string === 'string' && Array.isArray(replacements);
  if (_isEmpty(string) || _isEmpty(replacements) || !hasCorrectTypes) return '';

  let newString = string;
  replacements.forEach(replacement => {
    const { key, value } = replacement;
    newString = newString.replace(key, value);
  });

  return newString;
};
