import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import CustomerSlide from './slider-content/CustomerSlide';
import { CustomerReviewSliderStyled } from './CustomerReviewSlider.styled';

const SliderFeatures = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 4,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        dots: false,
      },
    },
  ],
};

const CustomerReviewSlider = ({ customerReviews = [], backgroundA, backgroundB }) => {
  const getVariant = (idx) => {
    let mod = '';
    if (idx % 4 === 1) mod = 'imageBackground';
    if (idx % 4 === 2) mod = 'darkBackground';
    if (idx % 4 === 3) mod = 'imageBackground';
    return mod;
  };

  const getReview = (review, idx) => {
    const modifier = getVariant(idx);
    return (
      <CustomerSlide
        key={review.sys.id}
        modifiers={modifier}
        backgroundImage={idx % 4 === 1 ? backgroundA : backgroundB}
        review={review.fields.body}
        customerName={review.fields.title}
        location={review.fields.subTitle}
      />
    );
  };

  return (
    <CustomerReviewSliderStyled>
      <Slider {...SliderFeatures}>{customerReviews?.map?.(getReview)}</Slider>
    </CustomerReviewSliderStyled>
  );
};

CustomerReviewSlider.propTypes = {
  customerReviews: PropTypes.array,
  backgroundA: PropTypes.string,
  backgroundB: PropTypes.string,
};

export default CustomerReviewSlider;
