import styled from 'styled-components';

import { IncrementInput } from 'ui-redesign/components';
import { SquaredButton } from 'ui-redesign/elements';

import breakpoints from 'theme-redesign/Media';

export const QtyControl = styled(IncrementInput).attrs({
  className: 'QtyControl',
})`
  margin-top: 0.8rem;

  .Input {
    width: 100%;
  }
  // loading state
  .Button {
    height: 2.1rem;
  }

  @media ${breakpoints.medium_break} {
    width: 80%;
  }
`;

export const AddToCartStyled = styled(SquaredButton).attrs({
  className: 'AddToCartStyled',
})`
  margin-top: 0.8rem;
  @media ${breakpoints.medium_break} {
    width: 80%;
  }
`;
