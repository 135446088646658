import { configureStore } from '@reduxjs/toolkit';

import { getStorage } from 'utils/storageManager';
import { environment } from 'utils/envConfig';
import storageKeys from 'constants/storageKeys';

// Reducers
import cartCheckoutReducer, { initialState as cartInitialState } from './cartCheckout/cartCheckout';
import contentfulReducer from './contentful';
import lettuceModalReducer from './lettuceModal';
import modalReducer from './modal';
import userReducer, { initialState } from './user';
import appSettingsReducer from './appSettings';
import catalogReducer from './catalog';
import reservationReducer, { initialState as reservationInitialState } from './reservation/reservation';
import userGeneratedReducer from './userGenerated/userGenerated';
import bundlesSeedlingsReducer from './bundlesSeedlings';
import globalUIReducer from './globalUI';

// Middleware
import middleware from './middleware';

// init LogRocket for staging / production
if (environment === 'staging' || environment === 'production') {
  const filterState = (state) => {
    const { lettuceModal, modal, contentful, catalog, ...restState } = state;
    const { seedlings, farmstands, supplies, ...restCatalog } = catalog;
    return {
      ...restState,
      catalog: restCatalog,
    };
  };

  const filterAction = (action) => {
    if (action.type && action.payload && action.type.includes('contentful')) {
      return {
        type: action.type,
        payload: { label: action.payload.label },
      };
    } else {
      return action;
    }
  };

  window.LogRocket && middleware.push(window.LogRocket?.reduxMiddleware?.({ stateSanitizer: filterState, actionSanitizer: filterAction }));
}

const store = configureStore({
  reducer: {
    appSettings: appSettingsReducer,
    cartCheckout: cartCheckoutReducer,
    contentful: contentfulReducer,
    lettuceModal: lettuceModalReducer,
    modal: modalReducer,
    user: userReducer,
    catalog: catalogReducer,
    reservation: reservationReducer,
    userGenerated: userGeneratedReducer,
    bundlesSeedlings: bundlesSeedlingsReducer,
    globalUI: globalUIReducer,
  },
  middleware,
  preloadedState: {
    // load initial state from local storage
    lettuceModal: getStorage('lettuceModal'),
    user: Object.assign({}, initialState, getStorage(storageKeys.USER)),
    reservation: Object.assign({}, reservationInitialState, getStorage(storageKeys.RESERVATION)),
    cartCheckout: Object.assign({}, cartInitialState, { lastAddedFarmstandId: getStorage(storageKeys.CART)?.lastAddedFarmstandId }),
  },
});

export default store;
