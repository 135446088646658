import React from 'react';
import PropTypes from 'prop-types';

import { RateStarsWrapper } from './StarRating.styled';
import { Icon } from 'ui-redesign/elements';
import { ReactComponent as Star } from 'ui-redesign/elements/icon/assets/star.svg';

const STARS = [1, 2, 3, 4, 5];

// 5 star rating UI interactive or read-only
const StarRating = ({ readOnly, rating, onClick, hasError, modifiers }) => {
  const handleClick = (count) => () => {
    onClick && onClick(count);
  };
  return (
    <RateStarsWrapper readOnly={readOnly} modifiers={modifiers}>
      {STARS.map((count) => {
        return (
          <Icon
            key={count}
            modifiers={[
              count <= rating && readOnly ? 'brandColor' : readOnly ? 'brandStroke' : count <= rating ? 'darkGrayColor' : 'darkGrayStroke',
              hasError && 'errorStroke',
            ]}
            onClick={readOnly ? null : handleClick(count)}
          >
            <Star />
          </Icon>
        );
      })}
    </RateStarsWrapper>
  );
};

StarRating.propTypes = {
  readOnly: PropTypes.bool,
  rating: PropTypes.number, // 1-5
  onClick: PropTypes.func,
};

StarRating.defaultProps = {
  readOnly: false,
  rating: 0,
  onClick: null,
};

export default StarRating;
