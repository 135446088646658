import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

import LeftArrow from 'ui-redesign/elements/icon/assets/left.svg';
import RightArrow from 'ui-redesign/elements/icon/assets/right.svg';

export const modifiers = {
  selectedBorder: ({ theme }) => ({
    styles: `
      .Image {
        border: 0.4px solid ${theme.farmProject.dark_gray};
      }
    `,
  }),
};

export const ProductHeroSliderStyled = styled.div.attrs({
  className: 'ProductHeroSliderStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .PillLabelStyled {
    top: 1.025rem;
    left: 0.8rem;
    position: absolute;
    z-index: 10;
  }

  @media ${breakPoints.large_break} {
    width: 60%;
    height: 27rem;
    max-width: 32rem;
  }

  @media ${breakPoints.xLarge_break} {
    max-width: 35rem;
  }

  @media ${breakPoints.xxLarge_break} {
    width: 100%;
    max-width: 36rem;
    height: 35rem;
  }
`;

export const HeroSliderInner = styled.div.attrs({
  className: 'HeroSliderInner',
})`
  @media ${breakPoints.xxLarge_break} {
    height: 35rem;
  }
`;

export const ProductSlideStyled = styled.div.attrs({
  className: 'ProductSlideStyled',
})`
  height: 27rem;

  .Image {
    height: 100%;

    picture {
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    height: 35rem;

    .Image {
      picture {
        img {
          height: auto;
          width: 105%;
        }
      }
    }
  }
`;

export const HeroSliderSecondary = styled.div.attrs({
  className: 'HeroSliderSecondary',
})`
  height: 5rem;
  max-width: 37rem;
  margin: auto;
  margin-top: 1rem;

  .Image {
    width: 92%;
    cursor: pointer;
  }

  .swiper-container {
    .swiper-button-prev {
      top: 47%;
      left: -3px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url(${LeftArrow});
      }
    }

    .swiper-button-next {
      top: 47%;
      right: 6px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url(${RightArrow});
      }
    }

    .swiper-wrapper {
      height: 6rem;
    }
  }
`;

export const ProductSlideThumb = styled.div.attrs({
  className: 'ProductSlideThumb',
})`
  height: 5rem;

  .Image {
    height: 100%;
    border: 0.4px solid ${({ theme }) => theme.farmProject.white};

    picture {
      img {
        height: 120%;
        width: auto;
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
