import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import marked from 'marked';
import { useHistory } from 'react-router-dom';

import { TextStyled, modifiers } from './Text.styled';

const Text = ({ id, className, content, children, modifiers, as, isHTML, size, onRef, dataNw }) => {
  const textRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (!isHTML) return;

    const handleLinkClick = (href, target) => () => {
      const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
      const isNewTab = target?.includes('blank');
      const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

      if (isNewTab) {
        window.open(href);
      } else if (isHardCodedProdLink) {
        const pathPattern = /lettucegrow.com(\/\S+)/;
        const path = href.match(pathPattern);
        path && path[1] && history.push(path[1]);
      } else if (isExternalOrActionLink) {
        window.location.href = href;
      } else {
        href && history.push(href);
      }
    };

    // parse <a> tags and handle linking with router
    const links = textRef.current ? textRef.current.querySelectorAll('a') : [];
    [...links].forEach((linkNode) => {
      linkNode.addEventListener('click', handleLinkClick(linkNode.getAttribute('href'), linkNode.getAttribute('target')));
      linkNode.removeAttribute('href');
    });
    return () => {
      [...links].forEach((linkNode) => {
        linkNode.removeEventListener('click', handleLinkClick(linkNode.getAttribute('href'), linkNode.getAttribute('target')));
      });
    };
  }, [history, isHTML]);

  if (isHTML) {
    return (
      <TextStyled
        id={id}
        className={className}
        modifiers={modifiers}
        as={as}
        dangerouslySetInnerHTML={{ __html: marked(content) }}
        size={size}
        ref={onRef || textRef}
        data-nw={dataNw}
      />
    );
  } else {
    return (
      <TextStyled id={id} className={className} modifiers={modifiers} as={as} size={size} ref={onRef || textRef} data-nw={dataNw}>
        {content}
        {children}
      </TextStyled>
    );
  }
};

Text.defaultProps = {
  id: '',
  content: '',
  modifiers: '',
  as: '',
  size: '',
  dataNw: null,
};

Text.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element, PropTypes.number]),

  modifiers: styleModifierPropTypes(modifiers),
  as: PropTypes.string,
  size: PropTypes.string,
  onRef: PropTypes.func,
  dataNw: PropTypes.string,
};

export default Text;
