import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import cx from 'classnames';

import WidthProvider from '../deprecated/hoc/WidthProvider';
import { breakpoints } from '../../constants';

class Carousel extends Component {
  constructor() {
    super();
    this.state = {
      mainSlider: null,
      thumbSlider: null,
    };
  }

  componentDidMount() {
    this.leftAlignThumbnails();
    if (this.mainSlider.innerSlider && this.mainSlider.innerSlider.list) {
      this.mainSlider.innerSlider.list.addEventListener('touchmove', this.preventPageScroll, {
        passive: false,
      });
      this.mainSlider.innerSlider.list.addEventListener('touchstart', this.handleTouchStart, {
        passive: false,
      });
    }
    this.setState({
      mainSlider: this.mainSlider,
      thumbSlider: this.thumbSlider,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.width !== this.props.width) {
      this.leftAlignThumbnails();
    }
  }

  componentWillUnmount() {
    if (this.mainSlider.innerSlider && this.mainSlider.innerSlider.list) {
      this.mainSlider.innerSlider.list.removeEventListener('touchmove', this.preventPageScroll, {
        passive: false,
      });
      this.mainSlider.innerSlider.list.removeEventListener('touchstart', this.handleTouchStart, {
        passive: false,
      });
    }
  }

  handleTouchStart(e) {
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventPageScroll(ev) {
    // prevent vertical page scroll when horizontally swiping through slides

    const minValue = 5; // threshold

    this.clientX = ev.touches[0].clientX - this.firstClientX;
    this.clientY = ev.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue) {
      ev.preventDefault();
      ev.returnValue = false;
      return false;
    }
  }

  leftAlignThumbnails() {
    // in order for part of right most thumb slide to show but not be in 'centerMode' where left most thumb slide shows, we must calculate half a slide's worth of padding to apply
    if (this.thumbSlider && this.thumbSlider.innerSlider && this.thumbSlider.innerSlider.list) {
      const slide = this.thumbSlider.innerSlider.list.querySelector('.slick-slide');
      const slideWidth = slide ? slide.offsetWidth : 0;
      if (this.props.children.length > 3) {
        this.thumbSlider.innerSlider.list.style = `padding-left: ${slideWidth / 2}px;`;
      }
    }
  }

  render() {
    const numSlides = this.props.children?.length || 0;
    return (
      <React.Fragment>
        <Slider
          className={cx('lg-carousel', `lg-carousel__${this.props.slidesToShow}-slides`, this.props.className, {
            'lg-carousel--centered': this.props.isCenterMode,
            'lg-carousel--equal-height': this.props.shouldEqualizeHeight,
          })}
          slidesToShow={Math.min(numSlides, this.props.slidesToShow)}
          slidesToScroll={Math.min(numSlides, this.props.slidesToScroll)}
          component={null}
          arrows={!this.props.shouldHideArrows}
          centerMode={this.props.isCenterMode}
          centerPadding={this.props.width < breakpoints.MOBILE ? this.props.centerPaddingMobile : this.props.centerPadding}
          autoplaySpeed={this.props.autoplaySpeed}
          autoplay={this.props.shouldAutoplay}
          swipe
          touchMove
          ref={slider => (this.mainSlider = slider)}
          asNavFor={this.props.hasThumbnails && this.props.width >= breakpoints.MOBILE ? this.state.thumbSlider : null}
          dots={this.props.hasDots}
          initialSlide={this.props.initialSlide}
        >
          {this.props.children}
        </Slider>
        {this.props.hasThumbnails && this.props.width >= breakpoints.MOBILE && this.props.children.length > 1 && (
          <div className='lg-carousel__thumbnail-wrapper'>
            <Slider
              slidesToShow={Math.min(3.5, numSlides)}
              ref={slider => (this.thumbSlider = slider)}
              asNavFor={this.state.mainSlider}
              focusOnSelect={true} // clickable
              arrows={false}
            >
              {this.props.children}
            </Slider>
          </div>
        )}
      </React.Fragment>
    );
  }
}

Carousel.defaultProps = {
  slidesToShow: 1,
  slidesToScroll: 1,
  shouldAutoplay: false,
  isCenterMode: false,
  centerPadding: '102px',
  centerPaddingMobile: '30px',
  shouldEqualizeHeight: false,
  hasThumbnails: false,
  hasDots: false,
  className: '',
  shouldHideArrows: false,
  initialSlide: 0,
  autoplaySpeed: 4000,
};

Carousel.propTypes = {
  slidesToShow: PropTypes.number,
  shouldAutoplay: PropTypes.bool,
  isCenterMode: PropTypes.bool,
  centerPadding: PropTypes.string,
  centerPaddingMobile: PropTypes.string,
  shouldEqualizeHeight: PropTypes.bool,
  hasThumbnails: PropTypes.bool,
  hasDots: PropTypes.bool,
  className: PropTypes.string,
  shouldHideArrows: PropTypes.bool,
  initialSlide: PropTypes.number,
  autoplaySpeed: PropTypes.number,
};

export default WidthProvider(Carousel);
