import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';
import { Link } from 'react-router-dom';

export const FooterStyled = styled.div.attrs({
  className: 'Footer',
})`
  width: 100%;
  height: auto;
  display: block;
  background-color: ${({ theme }) => theme.farmProject.dark_gray};

  > .Title {
    font-size: 1.5rem;
    text-align: center;
  }
`;

export const FooterContent = styled.div.attrs({
  className: 'FooterContent',
})`
  max-width: 31.625rem;
  padding: 3.125rem 2rem;
  margin: auto;

  /* a {
    cursor: pointer;
  } */

  > .LinkWrapper {
    .Icon {
      display: none;
    }
  }

  > .GroupIcon {
    margin: 3.375rem 0 4rem;
  }

  @media ${breakPoints.large_break} {
    > .GroupIcon {
      display: none;
    }

    > a {
      max-height: 6.125rem;
    }

    max-width: 62rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .LinkWrapper {
      > .Icon {
        cursor: pointer;
        width: 4rem;
        height: 6.1rem;
        display: block;
      }
    }
  }
`;

export const FooterList = styled.div.attrs({
  className: 'FooterLists',
})`
  .Title {
    font-size: 0.75rem;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 1.5rem;
  }

  .ListLinks {
    li {
      margin: 0.4rem 0;

      .Link {
        font-size: 0.75rem;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ListsLinksWrappers = styled.div.attrs({
  className: 'ListsLinksWrappers',
})`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .FooterLists {
    width: calc(50% - 1rem);
  }
`;

export const ContactInfo = styled.div.attrs({
  className: 'ContactInfo',
})`
  padding: 2.25rem 0;
  border: solid 3px ${({ theme }) => theme.farmProject.gray};
  border-left: 0;
  border-right: 0;
  margin: 2.25rem 0;

  .Title {
    font-size: 0.75rem;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 1.5rem;
  }

  .Contact {
    .Text {
      font-size: 0.75rem;
    }

    &:nth-child(4) {
      margin-bottom: 0;

      .Icon {
        height: 1.25rem;
        width: 1.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }

  .GroupIcon {
    display: none;
  }

  @media ${breakPoints.large_break} {
    .GroupIcon {
      margin-top: 3rem;
      display: flex;
      justify-content: flex-start;

      a {
        margin-right: 1.8rem;
      }
    }
  }
`;

export const Contact = styled.div.attrs({
  className: 'Contact',
})`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .LinkWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .Icon {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;

    svg {
      stroke: ${({ theme }) => theme.farmProject.light_gray};
      fill: ${({ theme }) => theme.farmProject.light_gray};
    }
  }
`;

export const GroupIcon = styled.div.attrs({
  className: 'GroupIcon',
})`
  display: flex;
  justify-content: space-around;
  align-items: center;

  a {
    cursor: pointer;

    &:nth-of-type(2) {
      .Icon {
        width: 1.875rem;
        height: 1.875rem;
        pointer-events: none;
      }
    }
  }

  .Icon {
    width: 1.7rem;
    height: 1.7rem;
    pointer-events: none;

    svg {
      width: 95%;
      height: 95%;
    }
  }
`;

export const MailingForm = styled.div.attrs({
  className: 'MailingForm',
})`
  padding-bottom: 2.25rem;
  border-bottom: solid 3px ${({ theme }) => theme.farmProject.gray};
  margin-bottom: 2.25rem;

  .Title {
    font-size: 1.125rem;
    letter-spacing: 1.6px;
    line-height: normal;
    color: ${({ theme }) => theme.farmProject.brand_primary};
    margin-bottom: 0.5rem;
  }

  form {
    > div {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  }

  @media ${breakPoints.medium_break} {
    padding-top: 2.25rem;
    border-top: solid 3px ${({ theme }) => theme.farmProject.gray};
  }

  @media ${breakPoints.large_break} {
    width: 19.5rem;
    padding: 0;
    border: none;
    margin: 0;

    .Title {
      margin-bottom: 0;
    }
  }
`;

export const ConfirmationForm = styled.div.attrs({
  className: 'ConfirmationForm',
})`
  margin-top: 1rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  .component__Checkbox {
    font-size: 0.75rem;
    padding-right: 0.25rem;

    .checkbox__IconContainer {
      flex-shrink: 0;
    }
  }

  .ButtonText {
    padding: 0;
  }
`;

export const FooterBottom = styled.div.attrs({
  className: 'FooterBottom',
})`
  margin-top: 2.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Text {
    font-size: 0.75rem;
  }

  > .LinkWrapper {
    .Icon {
      width: 2.5rem;
      height: 4.5rem;
    }
  }

  @media ${breakPoints.large_break} {
    > .LinkWrapper {
      .Icon {
        display: none;
      }
    }
    width: 100%;
  }
`;

export const BottomInfo = styled.div.attrs({
  className: 'BottomInfo',
})`
  @media ${breakPoints.large_break} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 3px ${({ theme }) => theme.farmProject.gray};
    padding-top: 2rem;
  }
`;

export const Signature = styled.div.attrs({
  className: 'Signature',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .Icon {
    cursor: pointer;
    width: 6.5rem;
    height: 1.3rem;
    margin-left: 0.25rem;
  }
`;

export const Lists = styled.div.attrs({
  className: 'Lists',
})`
  @media ${breakPoints.medium_break} {
    margin-bottom: 3rem;
    display: flex;
    align-items: flex-start;

    .ListsLinksWrappers {
      .FooterLists {
        width: 8.5rem;
      }
    }

    .ContactInfo {
      width: 12rem;
      padding: 0;
      border: none;
      margin: 0;

      .Contact {
        margin-bottom: 0.75rem;

        .Icon {
          display: none;
        }
      }
    }
  }
  @media ${breakPoints.large_break} {
    justify-content: space-between;
    width: 50%;

    .ListsLinksWrappers {
      width: 63%;

      .FooterLists {
        .ListLinks {
          li {
            margin: 0.16rem 0;
          }
        }
      }
    }

    .ContactInfo {
      width: 10rem;

      .Contact {
        padding: 0.15rem 0;
        margin: 0;
      }
    }
  }
`;

export const LinkWrapper = styled(Link).attrs({
  className: 'LinkWrapper',
})`
  text-decoration: none;

  p {
    :hover {
      color: ${({ theme }) => theme.farmProject.brand_primary};
      transition: all 0.2s ease;
    }
  }

  svg {
    :hover {
      stroke: ${({ theme }) => theme.farmProject.brand_primary};
      fill: ${({ theme }) => theme.farmProject.brand_primary};
      transition: all 0.2s ease;
    }
  }
`;

LinkWrapper.defaultProps = {
  to: '',
};
