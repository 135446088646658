import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { ReactComponent as IconCheck } from './assets/check.svg';
import { CheckStyled, Check, IconContainer, modifiers } from './Checkbox.styled';

const Checkbox = ({ label, field, onChange }) => {
  const handleChange = (ev) => {
    field.onChange(ev);
    onChange && onChange(ev);
  };
  return (
    <CheckStyled>
      <Check type='checkbox' name={field.name} value={field.value || ''} onChange={handleChange} />
      <IconContainer>
        <IconCheck />
      </IconContainer>
      {label}
    </CheckStyled>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  field: PropTypes.object,
  onChange: PropTypes.func,
  modifiers: styleModifierPropTypes(modifiers),
};

export default Checkbox;
