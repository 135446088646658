import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme-redesign/Media';

export const modifiers = {
  darkGray: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};
      border: 1px solid transparent;
      
      span {
        color: ${theme.farmProject.gray_3};
      }

      .Icon {
        g {
          g {
            path {
              fill: ${theme.farmProject.gray_3};
            }
          }
        }
      }
      
      &:hover {
        border: solid 1px ${theme.farmProject.dark_gray};
        background-color: transparent;

        span {
          color: ${theme.farmProject.dark_gray};
        }

        .Icon {
          g {
            g {
              path {
                fill: ${theme.farmProject.dark_gray} !important;
              }
            }
          }
        }
      }
    `,
  }),
};

export const BackButtonStyled = styled.button.attrs({
  className: 'BackButtonStyled',
})`
  height: 2.725rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0.5rem 0.125rem 0.5rem 0.5rem;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  cursor: pointer;
  max-width: 9rem;

  .Icon {
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  span {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.625rem;
    letter-spacing: normal;
    color: ${({ theme }) => theme.farmProject.dark_gray};
    text-decoration: none;
    max-width: 5.5rem;
    line-height: normal;
    text-align: left;
    flex-shrink: 0;
    display: none;

    @media ${breakPoints.small_break} {
      display: block;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.farmProject.dark_gray};
    border: 1px solid ${({ theme }) => theme.farmProject.dark_gray};
    span {
      color: ${({ theme }) => theme.farmProject.gray_3};
    }

    .Icon {
      svg {
        fill: ${({ theme }) => theme.farmProject.gray_3};

        g {
          g {
            path {
              fill: ${({ theme }) => theme.farmProject.gray_3};
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.small_break} {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }

  @media ${breakPoints.large_break} {
    max-width: 13rem;
    
    span {
      font-size: 0.75rem;
      max-width: 7.5rem;
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
