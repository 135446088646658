import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getOpenModal, setCloseModal } from 'redux/modal';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '../index';
import { Text, Title } from 'ui-redesign/elements';

const ErrorModal = ({ openOverride, title, content }) => {
  const dispatch = useDispatch();
  const { id, label } = useSelector(getOpenModal);
  const [openState, setOpenState] = useState(openOverride || id === 'error');

  useEffect(() => {
    setOpenState(openOverride || id === 'error');
    //eslint-disable-next-line
  }, [id]);

  const closeModal = () => {
    dispatch(setCloseModal());
    setOpenState(false);
  };

  return (
    <Modal isOpen={openState} onCloseAction={closeModal}>
      <Title modifiers='small' content={title} />
      <Text content={label || content} isHTML />
    </Modal>
  );
};

ErrorModal.defaultProps = {
  openOverride: false,
  title: 'OOPS!',
  content: "Something isn't loading properly. Please try refreshing your browser or call us at <a href='tel:5122344001'>(512) 234-4001</a>",
};

ErrorModal.propTypes = {
  openOverride: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default ErrorModal;
