import React from 'react';
import PropTypes from 'prop-types';
import { Title, Text, Image, SquaredButton, Icon } from '../../elements';
import paths from 'constants/paths';
import { CardRecipeStyled, CardInner, RecipeInfo, RecipeTime } from './CardRecipe.styled';

import { ReactComponent as Clock } from 'ui-redesign/elements/icon/assets/clock.svg';

const CardRecipe = ({ modifiers, author, title, image, time, recipeContent, clickable, slug }) => {
  return (
    <CardRecipeStyled modifiers={modifiers}>
      <CardInner as={clickable ? 'a' : ''} href={`/recipes/${slug}`}>
        <Image picture={image} />
        <Title as='h4' modifiers={['tiny', 'brandColor', 'semiBold']} content={title} />
        <RecipeInfo>
          <Text as='span' content={author} />
          <RecipeTime>
            <Icon>
              <Clock />
            </Icon>
            <Text as='span' content={`Under ${time} min`} />
          </RecipeTime>
        </RecipeInfo>
        <Text modifiers='noLineHeight' content={recipeContent} />
        <SquaredButton modifiers='tertiary' label='SEE THE RECIPE' href={`${paths.RECIPES}/${slug}`} />
      </CardInner>
    </CardRecipeStyled>
  );
};

CardRecipe.propTypes = {
  modifiers: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
  time: PropTypes.number,
  recipeContent: PropTypes.string,
  clickable: PropTypes.bool,
};

export default CardRecipe;
