import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { Title, Text, Image, Link } from 'ui-redesign/elements';
import { StarRating } from 'ui-redesign/components';
import { PressReviewSliderWrapper, PressSliderWrapper, PressSlider } from './PressReviewSlider.styled';

import { useBreakpoint } from 'utils/hooks';
import paths from 'constants/paths';

const PressReviewSlider = ({ title, body, brands, showCallout, modifiers }) => {
  const viewportWidth = useBreakpoint();

  const sliderFeatures = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: viewportWidth === 'XL' ? 5 : 1,
    className: viewportWidth === 'XL' ? 'center' : false,
    centerPadding: viewportWidth === 'XL' ? '20px' : null,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  if (!brands?.length) return null;
  const getBlocks = () => {
    const blocks = brands.map((block) => {
      return (
        <PressSlider key={block.sys.id}>
          <Image picture={block.fields?.mediaContent?.fields?.file?.url} nativeWidth={240} />
          <Text modifiers={['noLineHeight', (viewportWidth === 'LG' || viewportWidth === 'XL') && 'small']} content={block.fields?.body} />
          <Link
            modifiers={['underline', 'darkGrayColor']}
            href={block.fields?.callToAction?.fields?.linkUrl}
            target='_blank'
            content='READ FULL ARTICLE'
          />
        </PressSlider>
      );
    });
    if (showCallout) {
      const callout = (
        <PressSlider key='callout'>
          <StarRating readOnly rating={5} modifiers='yellowColor' />
          <Text isHTML modifiers={['bold', 'light', viewportWidth !== 'XL' ? 'xxLarge' : 'xLarge']} content={body} />
          <Link modifiers={['underline', 'darkGrayColor']} href={paths.CUSTOMER_REVIEWS} content='READ THE REVIEWS' />
        </PressSlider>
      );
      blocks.splice(viewportWidth !== 'XL' ? 0 : 2, 0, callout);
    }
    return blocks;
  };

  return (
    <PressReviewSliderWrapper modifiers={modifiers}>
      {!!title && <Title content={title} />}
      <PressSliderWrapper>
        <Slider {...sliderFeatures}>{getBlocks()}</Slider>
      </PressSliderWrapper>
    </PressReviewSliderWrapper>
  );
};

PressReviewSlider.defaultProps = {
  showCallout: false,
};

PressReviewSlider.propTypes = {
  showCallout: PropTypes.bool,
};
export default PressReviewSlider;
