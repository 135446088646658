import React from 'react';
import PropTypes from 'prop-types';

import { MainContentStyled } from './MainPageContent.styled';

const MainContent = ({ children }) => <MainContentStyled>{children}</MainContentStyled>;

MainContent.propTypes = {
  children: PropTypes.any,
};

export default MainContent;
