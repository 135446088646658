import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const ResetPasswordStyled = styled.div.attrs({
  className: 'ResetPassword',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        .ModalInner {
          max-width: 23.5rem;
          form {
            width: 100%;
          }
          .Text {
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          .ModalInner {
            max-width: 23.5rem;

            .Text {
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
`;
