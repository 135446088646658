import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  yellowColor: ({ theme }) => ({
    styles: `
      .Icon {
        svg {
          path {
            fill: ${theme.farmProject.yellow};
          }
        }
      }
    `,
  }),
};

export const RateStarsWrapper = styled.div.attrs({
  className: 'RateStarsWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: center;

  .Icon {
    width: 1.125rem;
    height: 1.125rem;
    margin: 0 0.125rem;

    svg {
      path {
        stroke-width: 20;
      }
    }
  }

  ${applyStyleModifiers(modifiers)}
`;
