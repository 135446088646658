export const size = {
  xxLarge_break: '1440px',
  xLarge_break: '1200px',
  large_break: '1024px',
  middle_break: '720px',
  medium_break: '640px',
  small_break: '375px',
  tiny_break: '320px',
};

const breakPoints = {
  xxLarge_break: `(min-width: ${size.xxLarge_break})`,
  xLarge_break: `(min-width: ${size.xLarge_break})`,
  large_break: `(min-width: ${size.large_break})`,
  middle_break: `(min-width: ${size.middle_break})`,
  medium_break: `(min-width: ${size.medium_break})`,
  small_break: `(min-width: ${size.small_break})`,
  tiny_break: `(min-width: ${size.tiny_break})`,
};

export default breakPoints;
