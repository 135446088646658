import Client from 'shopify-buy';
import _unionBy from 'lodash/unionBy';
import strictUriEncode from 'strict-uri-encode';

import {
  initializeClient,
  addItems,
  removeItems,
  updateItems,
  setCheckout,
  openCart,
  addDiscount,
  removeDiscount,
  notifyCartUpdate,
  addMetadata,
  replaceSeedlings,
  getSeedlingsInCart,
  getItemInCart,
  getCartHasFarmstand,
  clearCart,
  setLoading,
  setCheckoutError,
  goToCheckout,
} from 'redux/cartCheckout/cartCheckout';
import { getItemById, getItemByVariantId } from 'redux/catalog';
import { getLoginState } from 'redux/user';

import { addDays } from 'utils/date-utils';
import itemAvailabilities from 'constants/itemAvailabilities';

import logError from 'utils/errorHandler';
import { getStorage } from 'utils/storageManager';
import storageKeys from 'constants/storageKeys';
import { cartAddItems, startedCheckout } from 'utils/klaviyo-utils';
import { trackUrl } from 'utils/googleTagManager';
import { gcsUrlPrefix, authDomain, shopifyDomain, shopifyToken } from 'utils/envConfig';
import firebaseApp from 'utils/firebaseConfig';
import { gtmAddToCart, gtmRemoveFromCart } from 'utils/googleTagManager';

const { OUT_OF_SEASON } = itemAvailabilities;
/**
 * * Redux Middleware for Cart & Checkout actions
 *
 */

const CART_ACTIONS = [
  removeItems.toString(),
  updateItems.toString(),
  replaceSeedlings.toString(),
  addItems.toString(),
  addDiscount.toString(),
  removeDiscount.toString(),
  addMetadata.toString(),
  openCart.toString(),
  goToCheckout.toString(),
];

let shopifyClient;

try {
  shopifyClient = Client.buildClient({
    storefrontAccessToken: shopifyToken,
    domain: shopifyDomain,
  });
} catch (error) {
  logError(error);
}

const cartCheckoutMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  if (
    action.type === initializeClient.toString() ||
    action.type === removeItems.toString() ||
    action.type === addItems.toString() ||
    action.type === updateItems.toString() ||
    action.type === addDiscount.toString()
  ) {
    dispatch(setLoading(true));
  }

  // if cart action is not initialize client, and no checkout exists yet in state, then dispatch init client, with original action as callback
  if (CART_ACTIONS.includes(action.type) && action.type !== initializeClient.toString() && !getState().cartCheckout.checkout.id) {
    dispatch(initializeClient(action));
    return;
  }

  next(action);

  const checkout = getState().cartCheckout.checkout;
  const user = getState().user;
  const isLoggedIn = getLoginState(getState());

  if (action.type === initializeClient.toString()) {
    const oldCartStorage = getStorage(storageKeys.OLD_CART);
    const cartStorage = getStorage(storageKeys.CART);
    let checkout;
    const initializeCheckout = async () => {
      try {
        if (cartStorage?.checkoutId) {
          checkout = await shopifyClient.checkout.fetch(cartStorage.checkoutId);
          // clear cart state for already completed checkout
          if (checkout.completedAt) {
            dispatch(clearCart());
            checkout = await shopifyClient.checkout.create();
          }
        } else {
          // backwards compatibility to hydrate cart items from old cart storage
          const mappedLineItems = [];
          (oldCartStorage?.items || []).forEach((storedItem) => {
            const catalogItem = getItemById(getState(), storedItem.sku);
            catalogItem && mappedLineItems.push({ variantId: catalogItem.shopifyVariantId, quantity: storedItem.qty });
          });
          checkout = await shopifyClient.checkout.create();
          if (oldCartStorage?.items?.length) {
            checkout = await shopifyClient.checkout.addLineItems(checkout.id, mappedLineItems);
          }
        }
        dispatch(setCheckout(checkout));
        CART_ACTIONS.includes(action.payload?.type) && dispatch(action.payload);
      } catch (error) {
        logError(error);
        dispatch(setLoading(false));
      }
    };
    initializeCheckout();
  }

  if (action.type === addItems.toString()) {
    const itemsToAdd = [];
    const itemsToUpdate = [];
    action.payload.items.forEach(({ variantId, quantity }) => {
      const foundItem = checkout.lineItems.find((cartItem) => cartItem.variant?.id === variantId);
      if (foundItem) {
        // Item already exists in cart. update quantity.
        itemsToUpdate.push({ id: foundItem.id, quantity: foundItem.quantity + quantity });
      } else {
        // Push new item into cart array
        itemsToAdd.push({ variantId, quantity });
      }
    });

    const updateCheckout = async () => {
      try {
        let updatedCheckout;
        if (itemsToAdd.length) {
          updatedCheckout = await shopifyClient.checkout.addLineItems(checkout.id, itemsToAdd);
        }
        if (itemsToUpdate.length) {
          updatedCheckout = await shopifyClient.checkout.updateLineItems(checkout.id, itemsToUpdate);
        }
        dispatch(setCheckout(updatedCheckout));

        cartAddItems(action.payload.items, updatedCheckout, getState());

        action.payload.items.forEach((lineItem) => {
          const catalogItem = getItemByVariantId(getState(), lineItem.variantId);
          const cartItem = updatedCheckout.lineItems.find((item) => item.variant?.id === lineItem.variantId);
          gtmAddToCart(cartItem, catalogItem, updatedCheckout);
        });
      } catch (error) {
        logError(error);
        dispatch(setLoading(false));
      }
    };

    updateCheckout();
  }

  if (action.type === removeItems.toString()) {
    const updateCheckout = async () => {
      try {
        const updatedCheckout = await shopifyClient.checkout.removeLineItems(checkout.id, action.payload.items);
        dispatch(setCheckout(updatedCheckout));
        action.payload.items.forEach((itemId) => {
          gtmRemoveFromCart(itemId);
        });
      } catch (error) {
        logError(error);
        dispatch(setLoading(false));
      }
    };
    updateCheckout();
  }

  if (action.type === updateItems.toString()) {
    const updateCheckout = async () => {
      try {
        const updatedCheckout = await shopifyClient.checkout.updateLineItems(checkout.id, action.payload.items);

        action.payload.items.forEach(({ id, quantity }) => {
          const cartItem = checkout.lineItems.find((item) => item.id === id);
          const catalogItem = getItemByVariantId(getState(), cartItem.variant.id);
          if (quantity > cartItem.quantity) {
            gtmAddToCart(cartItem, catalogItem, updatedCheckout);
          } else if (quantity < cartItem.quantity) {
            gtmRemoveFromCart(catalogItem.sku);
          }
        });

        // heads up - setCheckout after gtm call so that we can compare item quantities
        dispatch(setCheckout(updatedCheckout));
      } catch (error) {
        logError(error);
        dispatch(setLoading(false));
      }
    };
    updateCheckout();
  }

  if (action.type === replaceSeedlings.toString()) {
    const updateCheckout = async () => {
      try {
        const oldSeedlingItems = getSeedlingsInCart(getState());
        const seedlingCheckoutIds = oldSeedlingItems.map((seedling) => getItemInCart(getState(), seedling.shopifyVariantId)?.id);
        await shopifyClient.checkout.removeLineItems(checkout.id, seedlingCheckoutIds);
        action.payload.callback();
      } catch (error) {
        logError(error);
      }
    };
    updateCheckout();
  }

  if (action.type === addDiscount.toString()) {
    const updateCheckout = async () => {
      try {
        const updatedCheckout = await shopifyClient.checkout.addDiscount(checkout.id, action.payload.code);
        dispatch(setCheckout(updatedCheckout));
        dispatch(setLoading(false));
      } catch (error) {
        logError(error);
        dispatch(setLoading(false));
      }
    };
    updateCheckout();
  }

  if (action.type === removeDiscount.toString()) {
    const updateCheckout = async () => {
      try {
        const updatedCheckout = await shopifyClient.checkout.removeDiscount(checkout.id);
        dispatch(setCheckout(updatedCheckout));
      } catch (error) {
        logError(error);
      }
    };
    updateCheckout();
  }

  if (action.type === addMetadata.toString()) {
    const updateCheckout = async () => {
      try {
        const newAttrs = Object.keys(action.payload.data).map((attr) => {
          return {
            key: attr,
            value: action.payload.data[attr] === true ? 'true' : action.payload.data[attr] === false ? 'false' : action.payload.data[attr],
          };
        });
        const currentAttrs = checkout.customAttributes.map((attr) => ({ key: attr.key, value: attr.value }));
        const metadata = { customAttributes: _unionBy(newAttrs, currentAttrs, 'key') };
        const updatedCheckout = await shopifyClient.checkout.updateAttributes(checkout.id, metadata);
        dispatch(setCheckout(updatedCheckout));
      } catch (error) {
        logError(error);
      }
    };
    updateCheckout();
  }

  if (action.type === setCheckout.toString()) {
    const appSettings = getState().appSettings;
    const reservation = getState().reservation;
    const catalog = getState().catalog;
    const isFarmstandInCart = getCartHasFarmstand(getState());
    const buyableDate = isFarmstandInCart ? addDays(Date.now(), parseInt(appSettings.outSeedsBuyableIn)) : Date.now();

    /* HEADS UP - to determine if a seedling is out of stock,
     ** we take into account whether cart has farmstand (FYF), and if so, allow a more generous buyable window
     ** we also account for a stale catalog response from a long running session and check that the inStockDate is in the future, before removing
     */

    checkout.lineItems.forEach((item) => {
      const catalogItem = getItemByVariantId(getState(), item.variant.id);
      const catalogSeed = catalog.seedlings[catalogItem?.sku];
      const isSeedlingReserved = reservation.items.find((resItem) => resItem.sku === catalogItem.sku) && reservation.expiry > Date.now();
      const isSeedlingOutOfStock = !!catalogSeed?.inStockDate && catalogSeed?.inStockDate > buyableDate;
      const isOutOfSeason = catalogSeed?.availability === OUT_OF_SEASON && !catalogSeed?.inStockDate && !catalogSeed?.inSeasonDate;
      const isSeedFutureShipBuyable = !!catalogSeed?.shipsOnDate;

      if (
        catalog.isCatalogFetched &&
        (!catalogItem || isSeedlingOutOfStock || isOutOfSeason) &&
        !isSeedlingReserved &&
        !isSeedFutureShipBuyable
      ) {
        dispatch(removeItems({ items: [item.id] }));
        dispatch(notifyCartUpdate());
      }
    });
  }

  if (action.type === goToCheckout.toString()) {
    if (!checkout.id || !checkout.lineItems.length) return;
    const lastPathText = window.location.pathname.split('/').pop().replace(/-/gi, ' ').toUpperCase();
    const updateCheckout = async () => {
      try {
        const metadata = {
          customAttributes: _unionBy(
            [
              { key: 'userEnvironment', value: user.userSetEnvironment },
              { key: 'isGuest', value: user.isGuest ? 'true' : 'false' },
              { key: 'mobileWeb', value: user.isFromMobile ? 'true' : 'false' },
              { key: 'lastPathUrl', value: window.location.href },
              { key: 'lastPathTitle', value: lastPathText },
            ],
            checkout.customAttributes.map((attr) => ({ key: attr.key, value: attr.value })),
            'key'
          ),
        };
        let updatedCheckout = await shopifyClient.checkout.updateAttributes(checkout.id, metadata);
        if (isLoggedIn) {
          const userAddress = {
            address1: user.address,
            address2: user.postStop,
            city: user.city,
            province: user.state,
            zip: user.zip,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
          };
          updatedCheckout = await shopifyClient.checkout.updateShippingAddress(checkout.id, userAddress);
        }
        dispatch(setCheckout(updatedCheckout));
        // let GCS and shopify take it from here!
        startedCheckout(updatedCheckout, getState());
        const checkoutUrl = trackUrl(updatedCheckout.webUrl);
        if (isLoggedIn) {
          const firebaseUser = firebaseApp.auth().currentUser;
          let refreshedAuthToken = user.authToken;
          if (firebaseUser) {
            refreshedAuthToken = await firebaseUser.getIdToken();
          }
          window.location.href = `${gcsUrlPrefix}/app/lgcom/multipass/?authToken=${refreshedAuthToken}&authDomain=${authDomain}&returnTo=${strictUriEncode(
            checkoutUrl
          )}`;
        } else {
          window.location.href = checkoutUrl;
        }
      } catch (error) {
        logError(error);
        error.message && dispatch(setCheckoutError(error.message));
      }
    };
    updateCheckout();
  }
};

export default cartCheckoutMiddleware;
