import React from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';

import { SelectStyled } from './Select.styled';
import Icon from '../icon/Icon';
import { ReactComponent as ArrowScrollDown } from '../icon/assets/chevron.svg';

/**
 * * Custom Select field - Material UI select does not allow autocomplete, and autocomplete does not allow native mobile options dropdown
 */

const Select = ({ id, name, value, required, onChange, label, error, options, form, field, modifiers, helperText, autoComplete }) => {
  const inputName = name || field.name;
  const inputValue = value || field.value;
  const inputChange = onChange || field.onChange;
  const inputBlur = field.onBlur;
  const inputTouched = form.touched[inputName];
  const errorMessage = (inputTouched && (error || form.errors[inputName])) || null;
  const hasError = inputTouched && !!errorMessage;
  return (
    <SelectStyled hasValue={!!inputValue} hasError={hasError} modifiers={modifiers}>
      <Icon>
        <ArrowScrollDown />
      </Icon>

      <select
        id={id}
        name={inputName}
        value={inputValue}
        onChange={inputChange}
        onBlur={inputBlur}
        required={required}
        autoComplete={autoComplete}
      >
        {<option key='default' value='' hidden disabled selected></option>}
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label>{required ? label + '*' : label}</label>
      {(helperText || hasError) && (
        <FormHelperText error={hasError} required={required}>
          {errorMessage || helperText}
        </FormHelperText>
      )}
    </SelectStyled>
  );
};

Select.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array,
  autoComplete: PropTypes.string,
  helperText: PropTypes.string,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Select;
