import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Shop from './Shop';
import AccountMenu from './AccountMenu';
import { List, ListItem } from './Navigation.styled';
import Search from './Search';

import paths from 'constants/paths';
import { useBreakpoint, useHideGorgiasChat } from 'utils/hooks';
import { setLettuceModal } from 'redux/lettuceModal';

const Menu = ({ isOpen }) => {
  const location = useLocation();
  const size = useBreakpoint();
  const dispatch = useDispatch();

  useHideGorgiasChat(isOpen);

  useEffect(() => {
    if (isOpen) {
      dispatch(setLettuceModal({ label: 'isForceHidden', value: true }));
    }
    return () => {
      dispatch(setLettuceModal({ label: 'isForceHidden', value: false }));
    };
  }, [isOpen, dispatch]);

  const LINKS = [
    {
      label: 'How It Works',
      path: paths.EXPERIENCE,
    },
    {
      label: 'The Farmstand',
      path: paths.FARMSTAND,
    },
    {
      label: 'Seedlings',
      path: paths.SEEDLINGS,
    },
    {
      label: 'Our Mission',
      path: paths.OUR_MISSION,
    },
    {
      label: 'Resources',
      path: paths.BLOG,
    },
  ];

  return (
    <List isOpen={isOpen}>
      {(size === 'SM' || size === 'MD') && <Search isExpanded={true} />}
      {LINKS.map((link) => {
        const isActive = location.pathname.includes(link.path);
        return (
          <Link key={link.label} to={link.path}>
            <ListItem isActive={isActive}>{link.label}</ListItem>
          </Link>
        );
      })}

      <Shop />
      <hr />
      {(size === 'SM' || size === 'MD') && <AccountMenu isOpen={true} />}
    </List>
  );
};

Menu.defaultProps = {
  isOpen: false,
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
};

export default Menu;
