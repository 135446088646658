import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TooltipStyled, TipIconWrapper } from './Tooltip.styled';

import { Icon, Text } from 'ui-redesign/elements';
import { ReactComponent as InformationIcon } from 'ui-redesign/elements/icon/assets/information-icon.svg';
import { useBreakpoint } from 'utils/hooks';

const Tooltip = ({ text, iconModifiers }) => {
  const [isTipOpen, setIsTipOpen] = useState(false);
  const screenSize = useBreakpoint();
  const isNotDesktop = screenSize === 'SM' || screenSize === 'MD';
  const showTooltip = isNotDesktop && isTipOpen;
  const modifiers = ['backgroundGray2', showTooltip && 'showTip'];

  const onClickTooltip = () => {
    setIsTipOpen(!isTipOpen);
  };

  return (
    <TipIconWrapper onClick={onClickTooltip} modifiers={modifiers}>
      <Icon modifiers={iconModifiers}>
        <InformationIcon />
      </Icon>
      <TooltipStyled>
        <Text modifiers={['tiny', 'brandFont', 'darkGrayColor']}>{text}</Text>
      </TooltipStyled>
    </TipIconWrapper>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  iconModifiers: PropTypes.any,
};

export default Tooltip;
