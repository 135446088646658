import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconClose } from 'ui-redesign/elements/icon/assets/close.svg';

import { ModalStyled, ModalBackdrop, ModalWrapper, ModalContent, ModalCloseButton, ModalInner } from './Modal.styled';
import { useToggleBodyScrolling } from 'utils/hooks';

const Modal = ({ children, isOpen, onCloseAction, size, position, modalType, shouldHideCloseButton, dataNw }) => {
  useToggleBodyScrolling(isOpen);

  return (
    <ModalStyled modifiers={isOpen ? 'show' : 'hide'}>
      <ModalWrapper modifiers={position}>
        <ModalBackdrop onClick={onCloseAction} />
        <ModalContent modifiers={[size, modalType]} data-nw={dataNw}>
          {shouldHideCloseButton ? (
            ''
          ) : (
            <ModalCloseButton onClick={onCloseAction}>
              <IconClose />
            </ModalCloseButton>
          )}
          <ModalInner>{children}</ModalInner>
        </ModalContent>
      </ModalWrapper>
    </ModalStyled>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  shouldHideCloseButton: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large', 'fitContent']),
  modalType: PropTypes.oneOf(['normal', 'scroll', 'bundleModal']),
  position: PropTypes.any,
  onCloseAction: PropTypes.func.isRequired,
  children: PropTypes.node,
  dataNw: PropTypes.string,
};

Modal.defaultProps = {
  isOpen: false,
  shouldHideCloseButton: false,
  modalType: 'normal',
};

export default Modal;
