import React from 'react';
import PropTypes from 'prop-types';

import { Text, SquaredButton, Icon, Image } from 'ui-redesign/elements';
import { StarRating } from 'ui-redesign/components';
import { CustomerReviewStyled, QuoteIcon } from './SliderCustomerContent.styled';
import { ReactComponent as Quote } from 'ui-redesign/elements/icon/assets/quote.svg';

import paths from 'constants/paths';
import { useBreakpoint } from 'utils/hooks';

const CustomerReview = ({ modifiers, review, customerName, location, backgroundImage }) => {
  const size = useBreakpoint();
  return (
    <CustomerReviewStyled modifiers={modifiers}>
      <Image picture={backgroundImage} nativeWidth={size === 'XL' ? 700 : 900} />
      <QuoteIcon>
        <Icon>
          <Quote />
        </Icon>
      </QuoteIcon>
      <StarRating readOnly rating={5} modifiers='yellowColor' />
      <Text modifiers={['noLineHeight']} content={review} />
      <Text modifiers={['xLarge', 'brandFont']} content={customerName} />
      <Text modifiers={['small', 'brandFont']} content={location} />
      <SquaredButton label='READ THE REVIEWS' href={paths.CUSTOMER_REVIEWS} />
    </CustomerReviewStyled>
  );
};

CustomerReview.propTypes = {
  review: PropTypes.string.isRequired,
  customerName: PropTypes.string,
  location: PropTypes.string,
  backgroundImage: PropTypes.string,
  totalReviews: PropTypes.number,
  modifiers: PropTypes.any,
};

CustomerReview.defaultProps = {
  customerName: '',
  location: '',
  backgroundImage: '',
  totalReviews: 0,
};
export default CustomerReview;
