/**
 * Some date functions to replace moment
 */

const MONTHS = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];

export default function formatDate(dateToFormat, isUTC = false) {
  const date = new Date(dateToFormat);
  const day = isUTC ? date.getUTCDate() : date.getDate();
  const month = isUTC ? date.getUTCMonth() : date.getMonth();
  const year = isUTC ? date.getUTCFullYear() : date.getFullYear();
  return `${MONTHS[month]} ${day}, ${year}`;
}

export function formatShortDate(dateToFormat) {
  //returns MM/DD/YY

  const date = new Date(dateToFormat);

  // Format day/month/year to two digits
  var formattedDate = ('0' + date.getDate()).slice(-2);
  var formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
  var formattedYear = date.getFullYear().toString().substr(2, 2);

  return `${formattedMonth}/${formattedDate}/${formattedYear}`;
}

export function formatShortUTCDate(dateToFormat) {
  //Return MM/DD/YY in UTC timezone
  const date = new Date(dateToFormat);

  var formattedDate = ('0' + date.getUTCDate()).slice(-2);
  var formattedMonth = ('0' + (date.getUTCMonth() + 1)).slice(-2);
  var formattedYear = date.getUTCFullYear().toString().substr(2, 2);

  return `${formattedMonth}/${formattedDate}/${formattedYear}`;
}

export function getShortMonth(dateToFormat) {
  const date = new Date(dateToFormat);

  return MONTHS[date.getMonth()].substring(0, 3);
}

export function getUTCShortMonth(dateToFormat) {
  const date = new Date(dateToFormat);

  return MONTHS[date.getUTCMonth()].substring(0, 3);
}

export function getDay(dateToFormat) {
  const date = new Date(dateToFormat);

  return date.getDate();
}
export function getUTCDay(dateToFormat) {
  const date = new Date(dateToFormat);

  return date.getUTCDate();
}

export function getDayMonthFormat(timestamp, isUTC = false) {
  const date = new Date(timestamp);
  const day = isUTC ? date.getUTCDate() : date.getDate();
  const month = isUTC ? date.getUTCMonth() : date.getMonth();
  return `${month + 1}/${day}`;
}

export function getSeedlingAvailableDate(seedling) {
  if (!seedling || (!seedling.inSeasonDate && !seedling.inStockDate)) return;
  const laterDate = new Date(Math.max(seedling.inSeasonDate, seedling.inStockDate));
  return `${laterDate.getMonth() + 1}/${laterDate.getDate()}`;
}

export function getSeedlingAvailableDateObject(seedling) {
  if (!seedling || (!seedling.inSeasonDate && !seedling.inStockDate)) return;
  const laterDate = new Date(Math.max(seedling.inSeasonDate, seedling.inStockDate));
  return laterDate;
}

export function getDaysBetween(firstDate, secondDate) {
  if (!firstDate || !secondDate) return null;
  const dateA = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const dateB = new Date(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const millisBetween = Math.abs(dateB.getTime() - dateA.getTime());
  const days = millisBetween / millisecondsPerDay;

  return Math.floor(days);
}

export function addDays(date, dayCount) {
  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  return date + dayCount * millisecondsPerDay;
}
