import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const LoginModalStyled = styled.div.attrs({
  className: 'LoginModal',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        .ModalInner {
          max-width: 23.5rem;

          .Text {
            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .Link {
            margin-top: 0.5rem;
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          .ModalInner {
            max-width: 29.5rem;

            .Title {
              margin: 0.5rem 0;
            }

            .Text {
              &:last-of-type {
                margin-bottom: 0;
              }
            }

            .Link {
              margin-top: 1rem;
              margin-bottom: 3.5rem;
            }
          }
        }
      }
    }
  }
`;

export const InputGroup = styled.div.attrs({
  className: 'InputGroup',
})`
  width: 100%;
  margin-top: 1rem;

  .InputStyled {
    margin: 0;
    margin-bottom: 1rem;
  }

  @media ${breakPoints.medium_break} {
    margin-top: 1.625rem;
  }
`;
