import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SquaredButton } from 'ui-redesign/elements/index';

import { ToggleStyled, ToggleInner, ButtonGroup } from './Toggle.styled';

const Toggle = ({ startOn = true, labelOn, labelOff, onChange }) => {
  const [isOn, setIsOn] = useState(startOn);
  const [isDirty, setIsDirty] = useState(false);

  const onClickHandle = toggleState => {
    setIsDirty(true);
    setIsOn(toggleState);
  };

  useEffect(() => {
    if (startOn !== isOn) setIsOn(startOn);
    //eslint-disable-next-line
  }, [startOn]);

  useEffect(() => {
    isDirty && onChange && onChange(isOn);
    //eslint-disable-next-line
  }, [isOn, isDirty]);

  return (
    <ToggleStyled>
      <ToggleInner>
        <ButtonGroup modifiers={isOn ? 'on' : 'off'}>
          <SquaredButton onClick={() => onClickHandle(true)} modifiers={isOn && 'tertiary'} label={labelOn} />
          <SquaredButton onClick={() => onClickHandle(false)} modifiers={isOn && 'tertiary'} label={labelOff} />
        </ButtonGroup>
      </ToggleInner>
    </ToggleStyled>
  );
};

Toggle.propTypes = {
  startOn: PropTypes.bool,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  onChange: PropTypes.func,
};

export default Toggle;
