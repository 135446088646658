import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {};

export const Check = styled.input.attrs({
  className: 'checkbox__input',
})`
  & + .checkbox__IconContainer {
    svg {
      opacity: ${({ value }) => +value};
    }
  }
`;

export const CheckStyled = styled.label.attrs({
  className: 'component__Checkbox',
})`
  font-family: ${({ theme }) => theme.farmProject.font_family};
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.023125rem;
  text-transform: unset;
  color: ${({ theme }) => theme.farmProject.light_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox__IconContainer {
    font-size: 0.5rem;
    width: 1rem;
    height: 1rem;
    border: 1.5px solid ${({ theme }) => theme.farmProject.light_gray};
    border-radius: 0;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 0.7rem;
      height: 0.7rem;
      fill: ${({ theme }) => theme.farmProject.light_gray};
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const CheckErrorStyled = styled.label.attrs({
  className: 'component__Checkbox',
})`
  font-family: ${({ theme }) => theme.farmProject.font_family};
  font-size: 1rem;
  line-height: normal;
  letter-spacing: 0.023125rem;
  text-transform: unset;
  color: ${({ theme }) => theme.farmProject.light_gray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.span.attrs({
  className: 'checkbox__IconContainer',
})``;
