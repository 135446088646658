import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const ProductHeroSliderStyled = styled.div.attrs({
  className: 'ProductHeroSliderStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .PillLabelStyled {
    top: 0.65rem;
    left: 0.65rem;
    position: absolute;
    z-index: 10;
  }

  @media ${breakPoints.xxLarge_break} {
    width: 100%;
    max-width: 37rem;
    height: 35rem;
  }
`;

export const HeroSliderInner = styled.div.attrs({
  className: 'HeroSliderInner',
})`
  .slick-slider {
    padding-bottom: 0;
    height: 28rem;

    .slick-dots {
      bottom: 1.5rem;
      z-index: 10;
      padding: 0;
    }

    .slick-slide {
      height: 28rem;
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-thumb {
      display: block;

      height: 6.5rem;

      li {
        width: 6.5rem;
        height: 6.5rem;

        a {
          display: block;
          cursor: pointer;

          .ProductSlideStyled {
            height: 6.5rem;
          }
        }
      }
    }

    @media ${breakPoints.xxLarge_break} {
      height: 35rem;
    }

    .slick-list {
      .slick-track {
        .slick-slide {
          @media ${breakPoints.xxLarge_break} {
            height: 35rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    height: 35rem;
  }
`;

export const ProductSlideStyled = styled.div.attrs({
  className: 'ProductSlideStyled',
})`
  height: 28rem;

  .Image {
    height: 100%;

    picture {
      img {
        height: 100%;
        width: auto;
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    height: 35rem;
  }
`;
