import React from 'react';
import Pagination from '@material-ui/core/Pagination';
import PaginationItem from '@material-ui/core/PaginationItem';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PaginationWrapper } from './Paging.styled';

const Paging = ({ pageSize, total, currentPage, baseRoute }) => {
  return (
    <PaginationWrapper>
      <Pagination
        count={Math.ceil(total / pageSize)}
        defaultPage={currentPage}
        page={currentPage}
        siblingCount={0}
        boundaryCount={1}
        renderItem={(item) => <PaginationItem component={Link} to={`${baseRoute}${item.page}`} {...item} />}
      />
    </PaginationWrapper>
  );
};

Paging.propTypes = {
  pageSize: PropTypes.number,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  baseRoute: PropTypes.string,
};

export default Paging;
