import React from 'react';
import PropTypes from 'prop-types';

import PromoPrice from 'ui-redesign/components/promo-price/PromoPrice';
import { Text, Image, Title } from 'ui-redesign/elements';

import { ProductGiftStyled, ProductGiftWrapper, ProductDescription, ImageProductWrapper } from './ProductGift.styled';

const ProductGift = ({ modifiers, productName, productDescription, productImage, newPrice, oldPrice }) => {
  const imageUrl = productImage?.fields?.file?.url;

  if (!productName || !productDescription) return null;

  return (
    <ProductGiftStyled>
      <ProductGiftWrapper modifiers={modifiers}>
        <ImageProductWrapper>{!!imageUrl && <Image picture={imageUrl} />}</ImageProductWrapper>
        <ProductDescription>
          <Title as='h4' modifiers='tertiaryXSmall' content={productName?.toUpperCase?.()} />
          <PromoPrice newPrice={newPrice} oldPrice={oldPrice} />
          <Text modifiers={['brandFont', 'noLineHeight']} content={productDescription} isHTML />
        </ProductDescription>
      </ProductGiftWrapper>
    </ProductGiftStyled>
  );
};

ProductGift.propTypes = {
  productName: PropTypes.string,
  productDescription: PropTypes.string,
  productImage: PropTypes.object,
  oldPrice: PropTypes.string,
  newPrice: PropTypes.string,
  sku: PropTypes.string,
  modifiers: PropTypes.any,
};

export default ProductGift;
