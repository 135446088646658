import React from 'react';

import { useBreakpoint, useContent, useStopLoading } from 'utils/hooks';
import Head from 'components/Head';

import { MainContent, ContactSection } from 'ui-redesign/components';

import _get from 'lodash/get';
import _map from 'lodash/map';

import { HomeStyled } from './Home.styled';

import SectionsRenderer from 'pages/newHome/SectionsRenderer';

import { HOME_2020 } from 'constants/contentful';

const Home = () => {
  const size = useBreakpoint();
  const isDesktop = ['XL', 'LG'].includes(size);

  const content = useContent(HOME_2020);
  useStopLoading(!!content);

  if (!content) return null;

  const fields = content[0].fields;
  const sectionsData = _map(fields.sections, (e) => ({ ...e.fields, size, isDesktop, id: _get(e, 'sys.id') }));
  const renderSection = (sectionData) => {
    const section = SectionsRenderer[sectionData.id];
    return section && section(sectionData);
  };

  return (
    <MainContent>
      <Head
        title={content[0].fields.metaTitle}
        description={content[0].fields.metaDescription}
        ogImage={_get(content[0].fields, 'metaFogImage.fields.file.url')}
        twitterImage={_get(content[0].fields, 'metaTwitterImage.fields.file.url')}
      />
      <HomeStyled>
        <MainContent>
          {sectionsData.map(renderSection)}
          <ContactSection />
        </MainContent>
      </HomeStyled>
    </MainContent>
  );
};

export default Home;
