import { useState, useEffect } from 'react';
import { axiosPost } from 'utils/api-utils';
import useScrollListener from './useScrollListener';

/**
 * * useGorgias - Custom hook to defer loading gorgia script
 *  credit to react-driftjs https://github.com/chardmd/react-drift/blob/master/src/Drift.jsx
 */

export default function useGorgias() {
  const [isLoaded, setIsLoaded] = useState(false);
  const isScrolled = useScrollListener();

  useEffect(() => {
    const insertScript = (scriptText) => {
      const script = document.createElement('script');
      script.innerText = scriptText;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    const addListeners = () => {
      const sendUpdatedChatInfo = (data) => {
        axiosPost('/app/public/update', data);
      };

      if (window?.GorgiasChat) {
        window.GorgiasChat.on('ready', sendUpdatedChatInfo);
        window.GorgiasChat.on('destroy', sendUpdatedChatInfo);
        window.GorgiasChat.on('message:sent', sendUpdatedChatInfo);
        window.GorgiasChat.on('message:received', sendUpdatedChatInfo);
        window.GorgiasChat.on('widget:opened', sendUpdatedChatInfo);
        window.GorgiasChat.on('widget:closed', sendUpdatedChatInfo);
        window.GorgiasChat.on('unreadCount', sendUpdatedChatInfo);
      }
    };

    const addMainScript = () => {
      const scriptText = `
        !function(_){_.GORGIAS_CHAT_APP_ID="401",_.GORGIAS_CHAT_BASE_URL="us-east1-898b.production.gorgias.chat",_.GORGIAS_API_BASE_URL="config.gorgias.chat";var e=new XMLHttpRequest;e.open("GET","https://config.gorgias.chat/applications/401",!0),e.onload=function(t){if(4===e.readyState)if(200===e.status){var n=JSON.parse(e.responseText);if(!n.application||!n.bundleVersion)throw new Error("Missing fields in the response body - https://config.gorgias.chat/applications/401");if(_.GORGIAS_CHAT_APP=n.application,_.GORGIAS_CHAT_BUNDLE_VERSION=n.bundleVersion,n&&n.texts&&(_.GORGIAS_CHAT_TEXTS=n.texts),!document.getElementById("gorgias-chat-container")){var o=document.createElement("div");o.id="gorgias-chat-container",document.body.appendChild(o);const _=document.createElement("script");_.setAttribute("defer",!0),_.src="https://storage.googleapis.com/gorgias-chat-production-client-builds/{bundleVersion}/static/js/main.js".replace("{bundleVersion}",n.bundleVersion),document.body.appendChild(_)}}else console.error("Failed request GET - https://config.gorgias.chat/applications/401")},e.onerror=function(_){console.error(_)},e.send()}(window||{});
      `;
      insertScript(scriptText);
      setIsLoaded(true);
    };

    // dynamically add gorgia script on page scroll
    if (!isLoaded && isScrolled) {
      addMainScript();
      setTimeout(() => addListeners(), 1000);
    }
  }, [isLoaded, isScrolled]);
}
