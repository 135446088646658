import React from 'react';
import PropTypes from 'prop-types';

import { Text, Image } from 'ui-redesign/elements';
import AccordionSection from 'components/elements/AccordionSection';
import Price from './price';

import {
  FarmStandCardStyled,
  FarmstandCardInner,
  FarmstandInformation,
  PlantsNumber,
  AccordionWrapper,
  FarmstandModel,
} from './FarmstandCard.styled';

const FarmstandCard = ({
  id,
  number,
  price,
  farmStandModel,
  height,
  modifiersCardInner,
  modifiersModel,
  bullet1,
  bullet2,
  bullet3,
  isDesktop,
  centerOneFarmstand,
}) => (
  <FarmStandCardStyled modifiers={centerOneFarmstand && 'centerOneFarmstand'}>
    <FarmstandCardInner modifiers={modifiersCardInner}>
      <FarmstandInformation>
        <PlantsNumber>
          <Text as='span' content={number} />
          <Text as='span' content='PLANTS' />
          <AccordionWrapper>
            <AccordionSection title=' '>
              <Text content={bullet1} />
              <Text content={bullet2} />
              <Text content={bullet3} />
            </AccordionSection>
          </AccordionWrapper>
        </PlantsNumber>
        <Price id={id} price={price} farmstandSize={number} />
      </FarmstandInformation>
      <FarmstandModel modifiers={modifiersModel}>
        <Text as='span' content={height} />
        <Image picture={farmStandModel} nativeWidth={isDesktop ? 346 : 286} />
      </FarmstandModel>
    </FarmstandCardInner>
  </FarmStandCardStyled>
);

FarmstandCard.propTypes = {
  id: PropTypes.string,
  number: PropTypes.string,
  price: PropTypes.number,
  farmStandModel: PropTypes.string,
  height: PropTypes.string,
  modifiersCardInner: PropTypes.string,
  modifiersModel: PropTypes.string,
  bullet1: PropTypes.string,
  bullet2: PropTypes.string,
  bullet3: PropTypes.string,
  isDesktop: PropTypes.bool,
  centerOneFarmstand: PropTypes.bool,
};

export default FarmstandCard;
