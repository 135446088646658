import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';
import { Link } from 'react-router-dom';

import icons from 'constants/icons';
import colors from 'constants/styledColors';

import CircleSpinner from 'components/CircleSpinner';

// Pulsing dot animation
const pulsing = keyframes`
  from {
    transform: scale(1);
    opacity: 1;  
  }

  to {
    transform: scale(0.8);
    opacity: 0;  
  }
`;

const pulseAnimation = props => css`
  width: 36px !important; // force width
  height: 36px;
  padding: 0 !important; // force full circular shape
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  background-color: ${props.loadingColor ? props.loadingColor : props.backgroundColor};
  border-radius: 50%;
  animation: ${pulsing} 1s;
  animation-iteration-count: infinite;
  animation-delay: -0.5s;
`;

// Button styles
const Button = styled.button`
  padding: ${props => props.paddingSize};
  font-family: 'Rubik', sans-serif;
  letter-spacing: 0.7px;
  transition: all 0.3s, color 0s;
  cursor: 'pointer';
  pointer-events: ${({ inAction, disabled }) => (inAction || disabled ? 'none' : 'auto')};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  font-size: ${props => props.size};
  border: 1px solid ${props => (props.borderColor ? props.borderColor : props.backgroundColor)};
  width: ${props => props.width};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  span {
    margin-left: 5px;
    margin-right: ${props => (props.postIconText ? '5px' : 0)};
  }
  overflow: hidden;
  position: relative;

  .action-icon {
    font-size: 1rem;
    line-height: 0;
    vertical-align: sub;
    margin-right: 0;
  }

  &:after {
    content: '';
    background: #45cf32;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.7s;
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  /* Loading animation state */
  ${props => props.isLoading && props.loadingStyle === 'pulse' && pulseAnimation}

    /* static hover state */
    ${props =>
      props.isStatic &&
      css`
        &:hover {
          border-radius: 0;
        }
      `}
`;

const Wrapper = styled.div`
  padding: 0;
  padding-right: 5px;
  transition: all 0.3s;
  ${props =>
    !props.isStatic &&
    css`
      &:hover {
        padding-right: 0px;
        button span {
          margin-left: 10px;
        }
      }
      button {
        span {
          transition: margin-left 0.3s;
        }
      }
    `}
`;

/**
 * * The NEW button on the scene at Lettuce Grow - fancy town!
 * @param {string} text - The text we want the button to display in it's default state
 * @param {string} size - The font-size of the text in the button (ex: '12px' or '1rem')
 * @param {string} paddingSize - The padding to be applied to the button
 * @param {string} icon - The icon (from constants/icons) the button will use
 * @param {string} color - The color for the button text
 * @param {string} width - The width of the button
 * @param {string} borderColor - <- see name <-
 * @param {string} backgroundColor - <- see name <-
 * @param {string} postIconText - the text we want to display after the icon - typically used for pricing (ex: '$2')
 * @param {bool} isLoading - The loading status of the button during async actions
 * @param {string} loadingStyle - the loading style to be used during async actions
 * @param {string} loadingText - Text to display when the button is loading - will only display in 'spinner' style
 * @param {string} loadingColor - the background color when the button is in the loading state - will only display in 'pulse' style
 * @param {bool} isStatic - The static status of the button - this will prevent the icon from pushing to the right when a user hovers
 * @param {bool} isDisabled - <- see name <-
 *
 */

const ButtonNew = ({
  children,
  text,
  postIconText,
  icon,
  isLoading,
  isStatic,
  loadingText,
  loadingStyle,
  isDisabled,
  to,
  loadingColor,
  width,
  className,
  dataNw,
  ...rest
}) => {
  const buttonProps = {
    icon,
    disabled: isDisabled,
    isLoading,
    postIconText,
    loadingStyle,
    loadingColor,
    isStatic,
  };

  const theButton = (
    <Button key='theButton' {...rest} {...buttonProps} width={width} data-nw={dataNw}>
      {isLoading && loadingStyle === 'spinner'
        ? [loadingText, <CircleSpinner key='circle-spinner' size='6px' primaryColor={loadingColor} isInline={true} />]
        : [text, children, icon && <span key='plain-icon' className={`icon-${icon}`} />, postIconText]}
    </Button>
  );

  return (
    <Wrapper isStatic={isStatic} className={className}>
      {/* Wrap the button in a link if the 'to' prop exists */}
      {to ? <Link to={to}>{[theButton]}</Link> : [theButton]}
    </Wrapper>
  );
};

export default ButtonNew;

ButtonNew.defaultProps = {
  text: '',
  size: '0.75rem',
  paddingSize: '0.75em 2em',
  icon: null,
  color: colors.WHITE,
  borderColor: '',
  backgroundColor: colors.MAIN_GREEN,
  postIconText: '',
  isLoading: false,
  loadingStyle: 'pulse',
  loadingColor: colors.MAIN_GREEN,
  loadingText: '',
  isStatic: true,
  isDisabled: false,
  width: 'initial',
  dataNw: null,
};

ButtonNew.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  paddingSize: PropTypes.string,
  icon: PropTypes.oneOf(icons.ALL),
  color: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  postIconText: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingStyle: PropTypes.oneOf(['pulse', 'spinner']),
  loadingText: PropTypes.string,
  loadingColor: PropTypes.string,
  isStatic: PropTypes.bool, // don't animate
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  dataNw: PropTypes.string,
};
