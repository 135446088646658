import { createSlice } from '@reduxjs/toolkit';

/**
 * * Global UI - Redux Reducer - persists UI related states for each page
 *
 * @param state
 *
 */

const initialState = {
  seedlingsLandingPage: {
    search: '',
    filters: {
      type: [],
      availability: [],
    },
  },
};

const globalUISlice = createSlice({
  name: 'globalUI',
  initialState,
  reducers: {
    updateSeedlingsLPField(state, { payload }) {
      state.seedlingsLandingPage[payload.key] = payload.value;
    },
    clearSeedlingsLPField(state, { payload }) {
      state.seedlingsLandingPage[payload] = initialState.seedlingsLandingPage[payload];
    },
  },
});

const { actions, reducer } = globalUISlice;

export const { updateSeedlingsLPField, clearSeedlingsLPField } = actions;

export default reducer;
