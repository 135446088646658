const green = '#4c9462';
const yellow = '#ffc656';
const pink = '#f1b9a9';
const magenta = '#9e4777';
const red = '#e84b37';
const white = '#fff';

export const farmTheme = {
  farmProject: {
    //Font-family
    font_family: 'Rubik, sans-serif',
    font_family_text: 'Source Serif Pro, serif',

    //Color Pallete
    gray: '#41423e',
    gray_1: '#e8e8e3',
    gray_2: '#efeeeb',
    gray_3: '#f6f6f4',
    mid_gray: '#c5c5c1',
    light_gray: '#82827f',
    dark_gray: '#383a36',
    red,
    green,
    light_green: '#a6dab6',
    light_green_2: '#dbe8dc',
    yellow,
    pink,
    magenta,
    white,
    dark_green: '#2F7444',

    brand_primary: green,
    brand_secondary: yellow,
    accent_primary: pink,
    accent_secondary: magenta,
    error: red,
  },
};
