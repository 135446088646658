import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const modifiers = {
  small: () => ({
    styles: `
      width: 1.25rem;
      height: 1.25rem;
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      svg, g, path {
        fill: ${theme.farmProject.light_gray};
        stroke: ${theme.farmProject.light_gray};
      }
    `,
  }),
  invertedStroke: ({ theme }) => ({
    styles: `
      * {
        stroke: ${theme.farmProject.light_gray};
      }
    `,
  }),
  invertedFill: ({ theme }) => ({
    styles: `
      * {
        fill: ${theme.farmProject.light_gray};
        polygon {
          fill: ${theme.farmProject.light_gray};
        }
      }
    `,
  }),
  darkGrayColor: ({ theme }) => ({
    styles: `
      svg, g {
        fill: ${theme.farmProject.dark_gray};
        stroke: ${theme.farmProject.dark_gray};
      }
    `,
  }),
  darkGrayStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.dark_gray};
        fill: transparent;
      }
    `,
  }),
  brandColor: ({ theme }) => ({
    styles: `
      svg, g, path {
        fill: ${theme.farmProject.green};
      }
    `,
  }),
  brandStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.brand_primary};
        fill: transparent;
      }
    `,
  }),
  errorStroke: ({ theme }) => ({
    styles: `
      svg, g, path {
        stroke: ${theme.farmProject.error};
        fill: transparent;
      }
    `,
  }),
  transparent: () => ({
    styles: `
      svg {
        path {
          fill: transparent;
        }
      }
    `,
  }),
  rotate90: () => ({
    styles: `
    transform: rotate(90deg);
    `,
  }),
  rotate180: () => ({
    styles: `
    transform: rotate(180deg);
    `,
  }),
  rotate270: () => ({
    styles: `
    transform: rotate(270deg);
    `,
  }),
};

export const IconStyled = styled.div.attrs({
  className: 'Icon',
})`
  width: 3.25rem;
  height: 3.25rem;
  line-height: 1;
  background-color: transparent;
  display: block;
  overflow: hidden;
  position: relative;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'initial')};
  ${applyStyleModifiers(modifiers)}

  svg {
    width: 100%;
    height: auto;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
