import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'ui-redesign/components';
import { Text, Title, Image, SquaredButton } from 'ui-redesign/elements';

import { SavingSectionStyled, ContentWrapper } from './FarmSavingSection.styled';

const SavingSection = ({ title, text, mediaContent, callToAction, modifiers }) => {
  const hasCta = !!callToAction?.fields.linkUrl;
  return (
    <SavingSectionStyled modifiers={modifiers}>
      <ContentSection>
        <ContentWrapper>
          <Image picture={mediaContent?.fields?.file?.url} />
        </ContentWrapper>
        <ContentWrapper>
          <Title as='h3' modifiers='secondarySmall' content={title} />
          <Text content={text} />
          {hasCta && <SquaredButton label={callToAction?.fields?.linkText} href={callToAction?.fields?.linkUrl} />}
        </ContentWrapper>
      </ContentSection>
    </SavingSectionStyled>
  );
};

SavingSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  mediaContent: PropTypes.object,
  callToAction: PropTypes.object,
  modifiers: PropTypes.any,
};

export default SavingSection;
