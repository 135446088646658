import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'ui-redesign/elements';
import { ReactComponent as SandwichIcon } from 'ui-redesign/elements/icon/assets/menu.svg';
import { ReactComponent as CloseIcon } from 'ui-redesign/elements/icon/assets/close.svg';
import { SandwichWrapper } from './Navigation.styled.js';

const Sandwich = ({ isMenuOpen, onClick }) => {
  return (
    <SandwichWrapper>
      <Icon onClick={onClick} modifiers={['small', 'darkGrayColor']}>
        {isMenuOpen ? <CloseIcon /> : <SandwichIcon />}
      </Icon>
    </SandwichWrapper>
  );
};

Sandwich.defaultProps = {
  isMenuOpen: false,
  onClick: null,
};
Sandwich.propTypes = { isMenuOpen: PropTypes.bool, onClick: PropTypes.func };

export default Sandwich;
