import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { findIndex as _find } from 'lodash';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import { Image } from 'ui-redesign/elements';
import { PillLabel } from 'ui-redesign/components';

import {
  ProductHeroSliderStyled,
  ProductSlideStyled,
  HeroSliderInner,
  HeroSliderSecondary,
  ProductSlideThumb,
} from './FarmCarouselDesktop.styled';

SwiperCore.use([Navigation]);

const FarmCarouselDesktop = forwardRef(({ data = [], promo }, ref) => {
  const [imgSelectIndex, setImgSelectIndex] = useState(0);
  const shouldLoop = (data?.length || 0) > 6;
  const slidesPerView = shouldLoop ? 6 : data?.length;

  useImperativeHandle(ref, () => ({
    onSelectFarmstand: (sku) => {
      const farmImgIndex = _find(data, { description: sku });
      if (farmImgIndex >= 0) setImgSelectIndex(farmImgIndex);
    },
  }));

  const renderProductSlide = (slideItem, index) => {
    const isSelected = imgSelectIndex === index;
    const image = slideItem?.file?.url || '';

    const onSelectImage = () => {
      setImgSelectIndex(index);
    };

    if (!image) return null;

    return (
      <SwiperSlide key={index} onClick={onSelectImage}>
        <ProductSlideThumb modifiers={isSelected && 'selectedBorder'}>
          <Image picture={image} nativeWidth={1000} />
        </ProductSlideThumb>
      </SwiperSlide>
    );
  };

  return (
    <ProductHeroSliderStyled>
      {!!promo && !!data?.[imgSelectIndex]?.description && <PillLabel content={promo} />}
      <HeroSliderInner>
        <ProductSlideStyled>
          <Image picture={data?.[imgSelectIndex]?.file?.url || null} nativeWidth={1000} />
        </ProductSlideStyled>
      </HeroSliderInner>
      <HeroSliderSecondary>
        <Swiper slidesPerView={slidesPerView} watchSlidesVisibility watchSlidesProgress loop={shouldLoop} navigation={shouldLoop}>
          {data?.map(renderProductSlide)}
        </Swiper>
      </HeroSliderSecondary>
    </ProductHeroSliderStyled>
  );
});

FarmCarouselDesktop.propTypes = {
  data: PropTypes.array,
  promo: PropTypes.string,
};

FarmCarouselDesktop.displayName = 'FarmCarouselDesktop';

export default FarmCarouselDesktop;
