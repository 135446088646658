import React from 'react';
import PropTypes from 'prop-types';
import { useContent } from 'utils/hooks';

import { SquaredButton, Image, Text, Title } from 'ui-redesign/elements';
import { ContentSection, CustomerReviewSlider } from 'ui-redesign/components';

import { PreSproutedSection, ContentWrapper } from './Home.styled';
import { CUSTOMER_REVIEWS } from 'constants/contentful';

const PreSprouted = ({ title, body, mediaContent, callToAction, desktopImgSize, mobileImgSize, isDesktop }) => {
  const customerReviewContent = useContent(CUSTOMER_REVIEWS);

  return (
    <React.Fragment>
      <PreSproutedSection>
        <ContentSection modifiers={['leftText']} animation='crossFade'>
          <Image
            picture={mediaContent?.fields?.file.url}
            imageRouteLink={callToAction?.fields?.linkUrl}
            nativeWidth={isDesktop ? desktopImgSize : mobileImgSize}
          />
          <ContentWrapper>
            <Title content={title} modifiers='secondarySmall' as='h3' />
            <Text content={body} />
            <SquaredButton
              as='a'
              label={callToAction?.fields?.linkText || callToAction?.fields?.text}
              href={callToAction?.fields?.linkUrl || callToAction?.fields?.url}
            />
          </ContentWrapper>
        </ContentSection>
      </PreSproutedSection>
      <CustomerReviewSlider
        customerReviews={customerReviewContent?.[0]?.fields?.percentages}
        backgroundA={customerReviewContent?.[0]?.fields?.leftBackgroundAccent?.fields?.file?.url}
        backgroundB={customerReviewContent?.[0]?.fields?.rightBackgroundAccent?.fields?.file?.url}
      />
    </React.Fragment>
  );
};

PreSprouted.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  mediaContent: PropTypes.object,
  callToAction: PropTypes.object,
  desktopImgSize: PropTypes.number,
  mobileImgSize: PropTypes.number,
  isDesktop: PropTypes.bool,
};

export default PreSprouted;
