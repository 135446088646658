import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResponsiveIframe extends Component {
    constructor() {
        super();
        this.iframe = null;
    }

    componentDidMount() {
        const iframe = this.container.querySelector('iframe');
        if (!!iframe) {
            iframe.classList.add('lg-responsive-iframe');
            iframe.width = null;
            iframe.height = null;
        }
        this.iframe = iframe;
    }

    render() {
        return (
            <div
                className='lg-media lg-responsive-iframe-wrapper'
                ref={node => (this.container = node)}
                dangerouslySetInnerHTML={{ __html: this.iframe ? this.iframe.outerHTML : this.props.embedCode }}
            />
        );
    }
}

ResponsiveIframe.defaultProps = {};

ResponsiveIframe.propTypes = {
    embedCode: PropTypes.string.isRequired,
};

export default ResponsiveIframe;
