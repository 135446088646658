import styled from 'styled-components';

export const PriceInformation = styled.div.attrs({
  className: 'PriceInformation',
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  max-width: 7rem;
  height: 4.7rem;

  > span {
    position: absolute;
    top: 33%;
    left: auto;
    right: 0;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 0.8rem;
    z-index: 50;
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 1.25rem;
    color: ${({ theme }) => theme.farmProject.green};
    display: block;
    line-height: normal;
    margin-bottom: 0;

    p {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      color: ${({ theme }) => theme.farmProject.green};
      display: block;
      line-height: normal;
      margin-bottom: 0;
      font-size: 0.7rem;
      width: 7.5rem;
      line-height: 1rem;

      strong {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .Text + .Text {
    font-size: 0.6rem;
    width: 4rem;
    margin-left: 0.25rem;
  }

  .Button {
    width: 7rem;
    min-width: 5.5rem;
    padding: 0.25rem;
    margin-top: 0.5rem;
  }

  .IncreaseInput,
  .Button {
    z-index: 30;
  }
`;
