import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { SubMenu } from './Navigation.styled';
import { ButtonText, Text, Icon } from 'ui-redesign/elements';
import { ReactComponent as UserIcon } from 'ui-redesign/elements/icon/assets/user.svg';

import paths from 'constants/paths';
import { getLoginState, getCustomer } from 'redux/user';
import { setOpenModal } from 'redux/modal';
import { useAuth, useBreakpoint } from 'utils/hooks';

const AccountMenu = ({ isOpen }) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginState);
  const customer = useSelector(getCustomer);
  const size = useBreakpoint();
  const auth = useAuth();

  const handleLogout = () => {
    auth.signOut();
  };

  const handleLoginModal = () => {
    dispatch(setOpenModal('login'));
  };

  return (
    <SubMenu isOpen={isOpen}>
      {isOpen && (
        <ul>
          <li>
            {isLoggedIn && <Text modifiers={['bold', 'brandFont']} content={`HI, ${user.firstName.toUpperCase()}`}></Text>}
            {isLoggedIn && (size === 'SM' || size === 'MD') ? (
              ''
            ) : (
              <ButtonText
                dataNw='login-button'
                modifiers={['secondary', 'underline']}
                label={isLoggedIn ? 'Log out' : 'Login'}
                onClick={isLoggedIn ? handleLogout : handleLoginModal}
              />
            )}
            <Icon modifiers={['small', isLoggedIn && 'brandStroke']}>
              <UserIcon />
            </Icon>
          </li>
          {isLoggedIn && (
            <>
              <li>
                <Link to={paths.MY_FARM}>My Farm</Link>
              </li>
              <li>
                <Link to={`${paths.MY_FARM}#my-deliveries`}>My Deliveries</Link>
              </li>
              <li>
                <Link to={`${paths.MY_FARM}#my-account`}>My Account</Link>
              </li>
              {customer?.referralCode && (
                <li>
                  <Link to={`${paths.MY_FARM}#my-referral`}>Refer & Earn</Link>
                </li>
              )}
            </>
          )}
          {isLoggedIn && (size === 'SM' || size === 'MD') && (
            <ButtonText
              dataNw='login-button'
              modifiers={['secondary', 'underline']}
              label={isLoggedIn ? 'Log out' : 'Login'}
              onClick={isLoggedIn ? handleLogout : handleLoginModal}
            />
          )}
        </ul>
      )}
    </SubMenu>
  );
};

AccountMenu.defaultProps = { isExpandable: true };
AccountMenu.propTypes = { isExpandable: PropTypes.bool };

export default AccountMenu;
