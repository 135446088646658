import { createClient } from 'contentful';
import queryString from 'query-string';
import _isEqual from 'lodash/isEqual';

import { environment, contentCacheUrl } from 'utils/envConfig.js';
import { axiosGet } from './api-utils.js';

/* 
* HEADS UP 
  client function checks cached content map for cached entry key
  - if cached map has key that matches params, it uses the map value as url path in axios request to FB hosted content
  - else (fallback) to hit contentful proxy
  - navigating to /disable-content-cache will set flag in redux to always hit proxy directly
*/

const usePublishedContent = environment === 'production';
const SPACE_ID = 'tjhwhh07rwer';

const PREVIEW_ACCESS_TOKEN = '08e701b9fb8574b4e7ee8ec46f149d38d22edcf353a5b73213c8653b8b1fd02f';
const PUBLISHED_ACCESS_TOKEN = '5031d36352da33ece15d90ca25af1513edde8ab9a2d8f59bdbe8ff4de4a2db12';
const ACCESS_TOKEN = usePublishedContent ? PUBLISHED_ACCESS_TOKEN : PREVIEW_ACCESS_TOKEN;

// TEMP - always use proxy as cache backup
const contentfulHost =
  environment === 'local' || environment === 'development'
    ? 'dev.lettucegrow.com'
    : document?.domain?.includes('release')
    ? 'staging.lettucegrow.com'
    : (document && document.domain) || 'lettucegrow.com';
// const contentfulHost = usePublishedContent ? 'cdn.contentful.com' : 'dev.lettucegrow.com';

const _client = createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: contentfulHost,
  basePath: 'app/contentfulproxy',
});

const getCachedContentEntry = (cachedContentMap, contentParams) => {
  // ensure param values are stringified before comparing with JSON
  const sanitizedParams = Object.keys(contentParams).reduce((acc, key) => {
    if (contentParams[key] !== undefined) {
      acc[key] = contentParams[key].toString();
    }
    return acc;
  }, {});

  const cachedKeys = Object.keys(cachedContentMap);
  const cachedKey = contentParams ? cachedKeys.find((key) => _isEqual(queryString.parse(key), sanitizedParams)) : '';
  return cachedContentMap[cachedKey];
};

const client = (params, reduxStore = {}) => {
  const disableContentCache = ['local', 'development'].includes(environment) || reduxStore.appSettings.disableContentCache;
  let cachedContentMap = reduxStore.contentful.cacheMap;

  // early return for force disabled
  if (!cachedContentMap || disableContentCache) return _client;

  const cachedEntry = getCachedContentEntry(cachedContentMap, params);
  const contentHost = cachedEntry ? contentCacheUrl : contentfulHost;

  // entry is found in cache map! Hit FB hosted cache with entry key
  if (cachedEntry) {
    return {
      getEntries: () => {
        return axiosGet(`${contentHost}/${cachedEntry}`, {}).then((response) => _client.parseEntries(response.data));
      },
      getEntry: () => {
        console.error('getEntry NOT currently supported for content cache, please add support in "contentful-client"');
      },
    };
  }

  // no cached entry found, fallback to contentful api
  console.warn('NO CONTENT CACHE ENTRY FOUND FOR:', params);
  return _client;
};

export { client };
