import styled from 'styled-components';
import breakPoints from '../../theme-redesign/Media';

export const HomeStyled = styled.div.attrs({
  className: 'Homepage',
})`
  margin: auto;
  .HomeHeroSlider {
    padding-bottom: 0;
    width: 100%;
    .slick-slide {
      height: unset;
    }
    .slick-dots {
      padding: 0;
      bottom: 1rem;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      button {
        border: 1px solid ${({ theme }) => theme.farmProject.green};
      }
      .slick-active {
        button {
          background: ${({ theme }) => theme.farmProject.green};
        }
      }
    }
  }
  .HeroSection {
    max-height: 46rem;
    padding: 2rem;

    .Image {
      img {
        width: 125%;
        height: auto;
      }
    }
    .HeroInner {
      .Title {
        font-size: 2.25rem;
        max-width: 16rem;
        padding-right: 15%;
        margin-bottom: 1.5rem;
      }

      .Text {
        padding-right: 15%;
      }

      .Button {
        width: 12rem;
      }
    }
  }

  .HeroSection.GlowRings {
    background: #f4f4f1; // hack to match image background, should not be in theme
    .Image img {
      width: auto;
      height: 100%;
      max-height: 700px;
      left: 98%;
    }
  }

  .HeroSection.WinterSeedlings {
    background: ${({ theme }) => theme.farmProject.white}; // hack to match image background
    .Image img {
      height: 108%;
      max-height: 700px;
      width: auto;
      left: 115%;
      transform: translate(-50%, -50%) rotate(-32deg);
      top: 57%;
}
    }
  }

  .PressSliderWrapper {
    margin-top: 0;

    .slick-prev {
      left: -0.5rem;
    }
    .slick-next {
      right: 0;
    }
  }

  @media ${breakPoints.medium_break} {
    .HeroSection {
      height: 37rem;
      padding: 3rem;

      .Image {
        height: 100%;

        img {
          width: 110%;
        }
      }
      .HeroInner {
        max-width: 33.5rem;

        .Title {
          font-size: 3rem;
          max-width: 23rem;
          padding-right: 0;
          margin-bottom: 1rem;
        }

        .Text {
          padding-right: 40%;
        }
      }
    }
    .SectionInner {
      max-width: 33.5rem;
      margin: auto;
    }

    .HeroSection.GlowRings {
      .Image img {
        width: auto;
        max-width: 1920px;
        height: 100%;
        max-height: unset;
        top: 50%;
        left: 70%;
      }
    }
    .HeroSection.WinterSeedlings {
      .Image img {
        left: 80%;
        top: 50%;
        width: 100%;
        max-width: 1920px;
        height: auto;
        max-height: unset;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media ${breakPoints.large_break} {
    .HeroSection {
      .Image {
        img {
          width: 115%;
          height: auto;
        }
      }
      .HeroInner {
        max-width: 62rem;

        .Title {
          max-width: 32rem;
        }

        .Text {
          padding-right: 55%;
        }
      }
    }

    .HeroSection.GlowRings {
      .Image img {
        width: 155%;
        max-width: 1920px;
        height: auto;
        top: 60%;
        left: 38%;
      }
    }

    .HeroSection.WinterSeedlings {
      .Image img {
        left: 65%;
        top: 57%;
      }
    }

    .SectionInner {
      max-width: 62rem;
    }
  }

  @media ${breakPoints.xxLarge_break} {
    .HeroSection.GlowRings {
      .Image img {
        left: 50%;
      }
    }
  }
`;

export const HydroponicSection = styled.div.attrs({
  className: 'HydroponicSection',
})`
  .ContentSection {
    padding-top: 23rem;

    .SectionInner {
      > a {
        display: block;
        width: 100%;
        height: 20rem;
        overflow: hidden;
        position: absolute;
        top: 10rem;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .Image {
        width: 100%;
        height: 100%;
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        picture {
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 125%;
            width: auto;
          }
        }
      }

      .ContentWrapper {
        .Title {
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;
      padding-top: 32rem;

      .SectionInner {
        > a {
          height: 28rem;
          top: 14rem;
        }

        .Image {
          picture {
            img {
              position: relative;
              top: auto;
              left: auto;
              transform: none;
            }
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            margin-bottom: 3rem;
          }

          .Text {
            max-width: 25rem;
            margin: auto;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding-bottom: 1rem;
      padding-top: 5rem;

      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > a {
          width: calc(50% - 2rem);
          height: 50rem;
          position: relative;
          top: auto;
          left: auto;
          transform: none;
        }

        .Image {
          margin: auto;
          margin-bottom: 0;
          width: 100%;
          max-width: 23rem;

          picture {
            img {
              height: 100%;
              position: relative;
              top: auto;
              left: auto;
              transform: none;
            }
          }
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const PreSproutedSection = styled.div.attrs({
  className: 'PreSproutedSection',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .ContentSection {
    .SectionInner {
      .Image {
        height: auto;
        margin-bottom: 2rem;

        picture {
          img {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
          }
        }
      }
      .ContentWrapper {
        .Title {
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .Image {
          margin-bottom: 4rem;
        }
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 20rem;
            margin-bottom: 3rem;
          }

          .Text {
            max-width: 28rem;
            margin-left: 0;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;

        > a {
          width: calc(50% - 2rem);
        }

        .Image {
          margin-left: 0;
          margin-bottom: 0;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const GrowSection = styled.div.attrs({
  className: 'GrowSection',
})`
  position: relative;

  .ContentSection {
    .SectionInner {
      .Image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        picture {
          img {
            height: 100vh;
            width: auto;
          }
        }
      }
      .ContentWrapper {
        .Title {
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .SectionInner {
        .Image {
          picture {
            img {
              width: 140%;
              height: auto;
            }
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3.75rem;
            max-width: 20rem;
            margin: auto;
            margin-bottom: 3rem;
          }

          .Text {
            letter-spacing: 1.5px;
            max-width: 28rem;
            margin: auto;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      height: 48.75rem;

      .SectionInner {
        .Image {
          picture {
            img {
              width: 125%;
              height: auto;
            }
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 5rem;
            letter-spacing: 0.5rem;
            max-width: 45rem;
          }

          .Text {
            max-width: 36rem;
          }
        }
      }
    }
  }
`;

export const FarmStandTableSection = styled.div.attrs({
  className: 'FarmStandTableSection',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .ContentSection {
    .SectionInner {
      .Image {
        height: auto;
        margin-bottom: 2rem;

        picture {
          img {
            position: relative;
            top: auto;
            left: auto;
            transform: none;
          }
        }
      }

      .ContentWrapper {
        .Title {
          font-size: 3rem;
          max-width: 15rem;
          margin-bottom: 1.5rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .Image {
          margin-bottom: 4rem;
        }

        .ContentWrapper {
          .Title {
            font-size: 3.75rem;
            max-width: 20rem;
            margin-bottom: 3rem;
          }

          .Text {
            max-width: 30rem;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > a {
          width: calc(50% - 2rem);
        }

        .Image {
          margin-left: 0;
          margin-bottom: 0;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const SeasonNumbersSection = styled.div.attrs({
  className: 'SeasonNumbersSection',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_1};

    .SectionInner {
      .ContentWrapper {
        .Title {
          font-size: 1.875rem;
          margin-bottom: 1.5rem;
        }

        .Text {
          font-size: 1rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            max-width: 30rem;
            margin: auto;
            margin-bottom: 3rem;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .NumbersList {
          margin-top: 0;
          width: calc(50% - 2rem);

          .NumberListItem {
            .Text {
              font-size: 2rem;
              text-align: center;
            }

            .Text + .Text {
              font-size: 0.75rem;
              text-align: left;
            }
          }
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            font-size: 3rem;
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;

export const NumbersList = styled.ul.attrs({
  className: 'NumbersList',
})`
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  list-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  @media ${breakPoints.medium_break} {
    margin-top: 4rem;
  }
`;

export const NumberListItem = styled.li.attrs({
  className: 'NumberListItem',
})`
  width: 100%;
  max-width: 17rem;
  padding-top: 1.5rem;
  padding-bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .StatsTicker__Section-ampsag-0 {
    width: 100%;
    margin: 0;
  }

  .StatsTicker__Ticker-ampsag-1 {
    width: 100%;
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.green};
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    p {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      font-size: 0.875rem;
      font-weight: 300;
      color: ${({ theme }) => theme.farmProject.green};
    }
  }

  .odometer {
    margin-bottom: 0;
    width: 60%;
    text-align: left;
    color: ${({ theme }) => theme.farmProject.green};
    font-weight: bold;

    .odometer-inside {
      display: flex;
      justify-content: center;

      .odometer-digit {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 1.75rem;
        font-weight: 300;
        color: ${({ theme }) => theme.farmProject.green};
      }

      .odometer-formatting-mark {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 1.75rem;
        font-weight: 300;
        color: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  &:nth-child(even) {
    border-top: solid 1px ${({ theme }) => theme.farmProject.green};
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.green};
  }

  @media ${breakPoints.medium_break} {
    max-width: none;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 33.5rem;

    .StatsTicker__Section-ampsag-0 {
      flex-direction: column;
    }

    .odometer {
      width: 60%;
      text-align: left;
      color: #4c9462;
      font-weight: bold;

      .odometer-inside {
        justify-content: flex-start;

        .odometer-digit {
          font-size: 2rem;
        }

        .odometer-formatting-mark {
          font-size: 2rem;
        }
      }
    }

    .StatsTicker__Ticker-ampsag-1 {
      justify-content: flex-start;

      p {
        font-size: 1rem;
        text-align: left;
        width: 10rem;
      }
    }

    .odometer + .Text {
      max-width: 8rem;
    }

    .StatsTicker__Ticker-ampsag-1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  @media ${breakPoints.medium_break} {
    .odometer {
      .odometer-inside {
        justify-content: center;

        .odometer-digit {
          font-size: 2.5rem;
        }

        .odometer-formatting-mark {
          font-size: 2.5rem;
        }
      }
    }

    .StatsTicker__Ticker-ampsag-1 {
      justify-content: flex-start;

      p {
        font-size: 1rem;
        text-align: left;
        width: 10rem;
      }
    }

    .odometer + .Text {
      max-width: 8rem;
    }

    .StatsTicker__Ticker-ampsag-1 {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;
