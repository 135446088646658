import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUserSetEnvironment } from 'redux/user';
import { Text } from 'ui-redesign/elements/index';
import Toggle from 'ui-redesign/components/toggle/Toggle';

import { GrowEnvironmentToggleStyled } from './GrowEnvironmentToggle.styled';

const GrowEnvironmentToggle = () => {
  const recommendation = useSelector((state) => state.catalog.packRecommendation);
  const dispatch = useDispatch();
  const onChangeHandle = (toggleState) => {
    dispatch(setUserSetEnvironment(toggleState ? 'OUTDOOR' : 'INDOOR'));
  };

  return (
    <GrowEnvironmentToggleStyled>
      <Text modifiers='brandFont' content='I’m primarily growing:' />
      {recommendation?.environment && (
        <Toggle startOn={recommendation.environment === 'OUTDOOR'} labelOn='OUTDOORS' labelOff='INDOORS' onChange={onChangeHandle} />
      )}
    </GrowEnvironmentToggleStyled>
  );
};

export default GrowEnvironmentToggle;
