import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';

import { Text, SquaredButton } from 'ui-redesign/elements';

import { ModalWarningStyled, ModalInner, ButtonGroup } from './WarningModal.styled';

const WarningModal = forwardRef(({ title, content, cancelCTA = {}, confirmCTA = {}, onConfirm }, ref) => {
  const [isShowing, setIsShowing] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal: () => setIsShowing(true),
    closeModal: onCancel,
  }));

  const onCancel = () => setIsShowing(false);

  return (
    <ModalWarningStyled modifiers={isShowing && 'show'}>
      <ModalInner>
        <Text as='span' modifiers={['brandFont', 'noLineHeight', 'semibold', 'errorColor']} content={title} />
        <Text modifiers={['brandFont', 'noLineHeight', 'light']} content={content} isHTML />
        <ButtonGroup>
          <SquaredButton modifiers={['whiteActive', 'lightGrayColor']} label={cancelCTA?.linkText} onClick={onCancel} />
          <SquaredButton modifiers='tertiary' label={confirmCTA?.linkText} onClick={onConfirm} />
        </ButtonGroup>
      </ModalInner>
    </ModalWarningStyled>
  );
});

WarningModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  cancelCTA: PropTypes.object,
  confirmCTA: PropTypes.object,
  onConfirm: PropTypes.func,
};

WarningModal.displayName = 'WarningModal';

export default WarningModal;
