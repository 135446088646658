import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { SquaredButtonStyled } from './SquaredButton.styled';
import { Icon } from 'ui-redesign/elements';
import { ReactComponent as LoadingIcon } from 'assets/images/loading.svg';

const SquaredButton = ({ id, type, disabled, onClick, label, modifiers, as, href, target, className, dataNw }) => {
  const history = useHistory();

  const handleClick = () => {
    onClick && onClick();

    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isNewTab = target?.includes('blank');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));
    if (isNewTab) {
      href && window.open(href);
    } else if (isHardCodedProdLink) {
      const pathPattern = /lettucegrow.com(\/\S+)/;
      const path = href.match(pathPattern);
      path && path[1] && history.push(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && history.push(href);
    }
  };
  return (
    <SquaredButtonStyled
      id={id}
      type={type}
      disabled={disabled}
      onClick={handleClick}
      modifiers={modifiers}
      as={as}
      target={target}
      className={className}
      data-nw={dataNw}
    >
      {modifiers?.includes?.('loading') ? (
        <Icon>
          <LoadingIcon />
        </Icon>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      )}
    </SquaredButtonStyled>
  );
};

SquaredButton.defaultProps = {
  id: null,
  type: '',
  disabled: false,
  onClick: null,
  label: '',
  modifiers: null,
  as: '',
  href: '',
  className: '',
};

SquaredButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  modifiers: PropTypes.any,
  as: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  dataNw: PropTypes.string,
};

export default SquaredButton;
