import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import _get from 'lodash/get';

import { MainContent } from 'ui-redesign/components';
import { NotFoundSection, ContentWrapper } from './styled';

import { ContentSection, Loading } from 'ui-redesign/components';
import { SquaredButton, Image, Text, Title } from 'ui-redesign/elements';

import { useContent } from 'utils/hooks';
import { setDisableContentCache } from 'redux/appSettings';

import { NOT_FOUND } from 'constants/contentful';
import paths from 'constants/paths';

const NotFound = () => {
  const content = useContent(NOT_FOUND);
  const location = useLocation();
  const dispatch = useDispatch();

  // redux actions based on path
  useEffect(() => {
    if (location.pathname === paths.DISABLE_CACHE) {
      dispatch(setDisableContentCache());
    }
  }, [dispatch, location]);

  if (!content) return <Loading isFullHeight />;

  const { title, body, mediaContent, callToAction } = content[0].fields;

  return (
    <MainContent>
      <NotFoundSection>
        <ContentSection>
          <Image picture={_get(mediaContent, 'fields.file.url')} />
          <ContentWrapper>
            <Title content={title} as='h3' modifiers='secondarySmall' isHTML />
            <Text content={body} isHTML />
            <SquaredButton
              as='a'
              label={_get(callToAction, 'fields.linkText') || _get(callToAction, 'fields.text')}
              href={_get(callToAction, 'fields.linkUrl') || _get(callToAction, 'fields.url')}
            />
          </ContentWrapper>
        </ContentSection>
      </NotFoundSection>
    </MainContent>
  );
};

export default NotFound;
