import { useMediaQuery } from '@material-ui/core';
import breakpoints from 'theme-redesign/Media';

export default () => {
  const isMD = useMediaQuery(`${breakpoints.medium_break}`, { noSsr: true });
  const isLG = useMediaQuery(`${breakpoints.large_break}`, { noSsr: true });
  const isXL = useMediaQuery(`${breakpoints.xLarge_break}`, { noSsr: true });

  if (isXL) return 'XL';
  if (isLG) return 'LG';
  if (isMD) return 'MD';
  else return 'SM';
};
