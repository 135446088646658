import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { VoteButton } from './ReviewFooter.styled';
import { Text, Icon } from 'ui-redesign/elements';
import { ReactComponent as ThumbsUp } from 'ui-redesign/elements/icon/assets/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from 'ui-redesign/elements/icon/assets/thumbs-down.svg';

import { axiosPost } from 'utils/api-utils';
import { setOpenModal } from 'redux/modal';
import { YOTPO_BASE } from 'constants/thirdPartyUrls';

/* Vote buttons
-  handles reviews or answers voting with Yotpo API integration
- handles only one up or down vote allowed per entity (review or answer)
- vote buttons act as toggle to only allow incrementing vote count by one, or undoing previous vote
- tracks votes "optimistically" in component state so user feedback is immediate and not tied to API request timing
*/

const VoteButtons = ({ initialUpVotes, initialDownVotes, entityId, entityType }) => {
  const [optimisticUpVotes, setOptimisticUpVotes] = useState(initialUpVotes);
  const [optimisticDownVotes, setOptimisticDownVotes] = useState(initialDownVotes);
  const dispatch = useDispatch();

  const hasUpVote = initialUpVotes + 1 === optimisticUpVotes;
  const hasDownVote = initialDownVotes + 1 === optimisticDownVotes;

  const submitVote = async (voteType, isUndo) => {
    try {
      let url = `${YOTPO_BASE}/${entityType}/${entityId}/vote/${voteType}`;
      if (isUndo) url += '/true';
      axiosPost(url);
    } catch (error) {
      // reset vote count state that was optimistically updated - b/c api request failed for some reason;
      if (voteType === 'down') {
        setOptimisticDownVotes(isUndo ? initialDownVotes + 1 : initialDownVotes);
      } else {
        setOptimisticUpVotes(isUndo ? initialUpVotes + 1 : initialUpVotes);
      }
      dispatch(setOpenModal('error'));
    }
  };

  const handleUpVote = () => {
    // check if vote should really increase count, or instead act as a 'toggle' to undo a previous up vote
    if (!hasUpVote) {
      setOptimisticUpVotes(initialUpVotes + 1);
      submitVote('up');

      if (hasDownVote) {
        setOptimisticDownVotes(initialDownVotes); // ensure to reset down votes so vote cannot be both up and down for same entity
        submitVote('down', true);
      }
    } else {
      // vote button acts as toggle to "undo" up vote for given review
      setOptimisticUpVotes(initialUpVotes);
      submitVote('up', true);
    }
  };
  const handleDownVote = () => {
    // check if vote should really decrease count, or instead act as a 'toggle' to undo a previous down vote
    if (!hasDownVote) {
      setOptimisticDownVotes(initialDownVotes + 1);
      submitVote('down');

      if (hasUpVote) {
        setOptimisticUpVotes(initialUpVotes); // ensure to reset up votes so vote cannot be both up and down for same entity
        submitVote('up', true); // undo up vote
      }
    } else {
      // vote button acts as toggle to "undo" down vote for given review
      setOptimisticDownVotes(initialDownVotes);
      submitVote('down', true);
    }
  };

  return (
    <>
      <VoteButton onClick={handleUpVote} key='up' isInverted={hasUpVote}>
        <Icon modifiers={hasUpVote ? 'darkGrayStroke' : 'darkGrayColor'}>
          <ThumbsUp />
        </Icon>
        <Text modifiers={['brandFont']} content={optimisticUpVotes} />
      </VoteButton>
      <VoteButton onClick={handleDownVote} key='down' isInverted={hasDownVote}>
        <Icon modifiers={hasDownVote ? 'darkGrayStroke' : 'darkGrayColor'}>
          <ThumbsDown />
        </Icon>
        <Text modifiers={['brandFont']} content={optimisticDownVotes} />
      </VoteButton>
    </>
  );
};

VoteButtons.defaultProps = {
  initialUpVotes: 0,
  initialDownVotes: 0,
};

VoteButtons.propTypes = {
  initialUpVotes: PropTypes.number,
  initialDownVotes: PropTypes.number,
  entityId: PropTypes.number.isRequired, // review or answer id
  entityType: PropTypes.oneOf(['reviews', 'answers']).isRequired,
};

export default VoteButtons;
