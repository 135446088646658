import styled from 'styled-components';

import breakPoints from '../../../theme-redesign/Media';

import { Image } from '../../elements';

export const HeroProductDetailStyled = styled.div.attrs({
  className: 'HeroProductDetailStyled',
})`
  max-width: 100%;
  margin: 0;
  position: relative;
`;

export const BackgroundMobile = styled(Image).attrs({
  className: 'BackgroundMobile',
})`
  picture {
    img {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
  }
`;

export const BackgroundDesktop = styled(Image).attrs({
  className: 'BackgroundDesktop',
})`
  picture {
    img {
      width: 105%;
    }
  }
  display: block;
  padding-top: 40%;
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})``;

export const HeroInfoWrapper = styled.div.attrs({
  className: 'HeroInfoWrapper',
})`
  padding: 2rem;
  max-width: 30rem;
  margin: auto;
  text-align: center;

  .Button,
  .QtyControl {
    margin: auto;
    width: 100%;
    max-width: 21rem;
    display: block;
  }
  @media ${breakPoints.xLarge_break} {
    padding: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -50%);
    max-width: 35rem;
  }
  .TextTooltipWrapperStyled {
    justify-content: center;
    .Text {
      font-size: 0.825rem;
    }
    .TipIconWrapper {
      .TooltipStyled {
        width: 56vw;
        .Text {
          font-size: 0.725rem;
        }

        @media ${breakPoints.medium_break} {
          width: 31vw;
        }
      }
    }
  }
`;

export const ProductInfo = styled.div.attrs({
  className: 'ProductInfo',
})`
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin-bottom: 1.5rem;

  .Title {
    text-align: center;
    font-size: 2.5rem;
    line-height: normal;
  }

  .Text {
    text-align: center;
  }

  span {
    display: block;
    margin: 0.125rem 0 1rem;
    font-style: italic;
    text-align: center;
  }

  @media ${breakPoints.xLarge_break} {
    padding-bottom: 0;
    border-bottom: none;

    .Title {
      font-size: 3rem;
    }
  }
`;
