import React from 'react';
import PropTypes from 'prop-types';

import { SlimBannerStyled } from './SlimBanner.styled';

const SlimBanner = ({ modifiers, children, onClick }) => (
  <SlimBannerStyled modifiers={modifiers} onClick={onClick}>
    {children}
  </SlimBannerStyled>
);

SlimBanner.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
  onClick: PropTypes.func,
};

export default SlimBanner;
