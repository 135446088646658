import styled from 'styled-components';

export const IconFeatureStyled = styled.div.attrs({
  className: 'IconFeatureStyled',
})`
  padding: 1rem 0;
  border-top: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin: 1rem 0;
`;

export const FeatureList = styled.ul.attrs({
  className: 'SizeButtonGroup',
})`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li.attrs({
  className: 'ListItem',
})`
  width: 33%;
  max-width: 12rem;
  margin: 0 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Icon {
    width: 2.5rem;
    height: 2.5rem;

    svg {
      width: 95%;
    }
  }

  .Text {
    margin-top: 0.5rem;
    font-size: 0.785rem;
    text-align: center;
  }
`;
