import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

const modifiers = {
  show: () => ({ styles: `display: block` }),
};

export const ModalWarningStyled = styled.div.attrs({
  className: 'ModalWarningStyled',
})`
  display: none;
  width: fit-content;
  border-radius: 0.125rem;
  width: 100%;
  max-width: 24rem;
  left: 50%;
  top: 15rem;
  transform: translate(-50%);
  position: fixed;
  border-radius: 0.125rem;
  background-color: white;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;

  &::before {
    content: '';
    display: block;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -15rem;
    left: 50%;
    transform: translate(-50%);
    background-color: black;
    opacity: 0.35;
    z-index: 9;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const ModalInner = styled.div.attrs({
  className: 'ModalInner',
})`
  padding: 1rem;
  position: relative;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 100;

  .Text {
    letter-spacing: normal;

    &:first-child {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
      display: block;
    }

    * {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }
`;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  display: flex;
  justify-content: flex-start;
  margin-top: 1.5rem;

  .Button {
    width: 8rem;
    margin-right: 1rem;
    border-radius: 0.125rem;
  }
`;
