import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Pagination, Navigation]);

const CustomSwiper = ({ items = [], onSwiperClick, renderSlideCard, swiperProps }) => {
  const renderSwiperSlide = (item, index) => {
    const onSlideClick = () => onSwiperClick && onSwiperClick(item, index);

    return (
      <SwiperSlide key={index} onClick={onSlideClick}>
        {renderSlideCard(item, index)}
      </SwiperSlide>
    );
  };

  return <Swiper {...swiperProps}>{items.map(renderSwiperSlide)}</Swiper>;
};

CustomSwiper.propTypes = {
  items: PropTypes.array,
  swiperProps: PropTypes.shape({
    loop: PropTypes.bool,
    pagination: PropTypes.oneOf([PropTypes.bool, PropTypes.object]),
    navigation: PropTypes.oneOf([PropTypes.bool, PropTypes.object]),
    centeredSlides: PropTypes.bool,
    centeredSlidesBounds: PropTypes.bool,
    centerInsufficientSlides: PropTypes.bool,
    preventInteractionOnTransition: PropTypes.bool,
    spaceBetween: PropTypes.number,
    slidesPerView: PropTypes.number,
    slidesPerColumn: PropTypes.number,
    slidesPerColumnFill: PropTypes.oneOf(['row', 'column']),
  }),
  onSwiperClick: PropTypes.func,
  renderSlideCard: PropTypes.func,
};

export default CustomSwiper;
