import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { MuiFormHelperText } from 'theme-redesign/Mixins';

export const modifiers = {
  standard: ({ theme }) => ({
    styles: `
      margin: unset;

      div {
        max-width: unset;

        .MuiInputBase-input {
          height: unset;
          color: ${theme.farmProject.white};
          background-color: transparent;
          label {
            z-index: 1;
            pointer-events: none;
          }
        }
      }
    `,
  }),
  brandForStandard: ({ theme }) => ({
    styles: `
      .MuiInput-underline:before {
        border-bottom-color: ${theme.farmProject.light_gray};
      }

      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom-width: 1px;
        border-bottom-color: ${theme.farmProject.brand_primary};
      }

      .MuiInput-underline:after {
        border-bottom-color: ${theme.farmProject.brand_primary};
      }

      div {
        label {
          transform: translate(0, 24px) scale(1);
          color: ${theme.farmProject.brand_primary};
          &.Mui-focused {
            color: ${theme.farmProject.brand_primary};
          }
        }
      }
    `,
  }),
  clear: ({ theme }) => ({
    styles: `
    div {
      .MuiInputBase-input {
        background: transparent;
        height: 2.5rem;

      }
    }
    `,
  }),
};

export const InputStyled = styled.div.attrs({
  className: 'InputStyled',
})`
  width: 100%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    width: 100%;
    max-width: 18rem;
    display: block;

    ${MuiFormHelperText};

    .MuiInputBase-input {
      font-size: 0.875rem;
      font-family: ${props => props.theme.farmProject.font_family};
      line-height: 1rem;
      box-sizing: border-box;
      height: 3.5rem;
      color: ${({ theme }) => theme.farmProject.dark_gray};
      background-color: ${({ theme }) => theme.farmProject.gray_2};
    }

    ::placeholder {
      color: ${({ theme }) => theme.farmProject.brand_primary};
    }

    .MuiFilledInput-root:hover {
      background-color: ${({ theme }) => theme.farmProject.gray_3};
    }

    label {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      font-size: 0.75rem;
      text-transform: unset;
      color: ${({ theme }) => theme.farmProject.dark_gray};
      transform: translate(0.75rem, 1.45rem) scale(1);
      pointer-events: none;
      z-index: 1;

      &.Mui-focused {
        color: ${({ theme }) => theme.farmProject.brand_primary};
      }
      &.MuiFormLabel-root.Mui-error {
        color: ${({ theme }) => theme.farmProject.red};
      }
    }
  }

  .MuiFilledInput-underline:before {
    border-bottom-color: ${({ theme }) => theme.farmProject.dark_gray};
  }

  .MuiFilledInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  .MuiFilledInput-underline:after {
    border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  .MuiFilledInput-underline.Mui-error:after {
    border-bottom-color: ${({ theme }) => theme.farmProject.red};
  }

  .MuiInput-underline:before {
    border-bottom-color: ${({ theme }) => theme.farmProject.dark_gray};
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  .MuiInput-underline:after {
    border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: ${({ theme }) => theme.farmProject.red};
  }

  .MuiInputLabel-shrink {
    transform: translate(0, 6px) scale(0.75);
  }

  @media ${breakPoints.medium_break} {
    margin-bottom: 3.5rem;
  }

  ${applyStyleModifiers(modifiers)};
`;
