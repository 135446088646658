import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  xxLarge: () => ({
    styles: `
      font-size: 1.5rem;
      line-height: 1.75rem;
    `,
  }),
  xLarge: () => ({
    styles: `
      font-size: 1rem;
      line-height: 1.75rem;
    `,
  }),
  small: () => ({
    styles: `
      font-size: 0.7rem;
      line-height: normal;
    `,
  }),
  tiny: () => ({
    styles: `
      font-size: 0.65rem;
      line-height: normal;
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.gray_3};
    `,
  }),
  brandFont: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      * {
        font-family: ${theme.farmProject.font_family};
      }
    `,
  }),
  brandLarge: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      font-size: 2.3125rem;
      color: ${theme.farmProject.brand_primary};
      line-height: 2.75rem;
    `,
  }),
  brand: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      font-size: 1.875rem;
      color: ${theme.farmProject.brand_primary};
      line-height: 2.75rem;
    `,
  }),
  brandSmall: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      font-size: 1.5625rem;
      color: ${theme.farmProject.brand_primary};
      line-height: 1.875rem;
    `,
  }),
  brandTiny: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      font-size: 0.65rem;
      color: ${theme.farmProject.brand_primary};
      line-height: 1;
    `,
  }),
  assignature: ({ theme }) => ({
    styles: `
      font-family: ${theme.farmProject.font_family};
      font-size: 1rem;
      letter-spacing: 0;
      color: ${theme.farmProject.brand_primary};
      line-height: 1.125rem;
    `,
  }),
  error: ({ theme }) => ({
    styles: `
      letter-spacing: 0;
      font-family: ${theme.farmProject.font_family};
      color: ${theme.farmProject.error};
      * {
        color: ${theme.farmProject.error};
      }
    `,
  }),
  errorColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.error};
    `,
  }),
  lightGrayColor: ({ theme }) => ({
    styles: `
    color: ${theme.farmProject.light_gray};
    `,
  }),
  midGrayColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.mid_gray};
    `,
  }),
  darkGrayColor: ({ theme }) => ({
    styles: `
    color: ${theme.farmProject.dark_gray};
    `,
  }),
  brandColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.brand_primary};
    `,
  }),
  bold: () => ({
    styles: `
      font-weight: bold;
      * {
        font-weight: bold;
      }
    `,
  }),
  semibold: () => ({
    styles: `
      font-weight: 500;
      * {
        font-weight: 500;
      }
    `,
  }),
  light: () => ({
    styles: `
      font-weight: 300;
      * {
        font-weight: 300;
      }
    `,
  }),
  italic: () => ({
    styles: `
      font-style: italic;
      `,
  }),
  lineHeightSmall: () => ({
    styles: `
      line-height: 1.5rem;
      * {
        line-height: 1.5rem;
      }
    `,
  }),
  noLineHeight: () => ({
    styles: `
      line-height: normal;
    `,
  }),
  noLetterSpace: () => ({
    styles: `
        letter-spacing: 0;
      `,
  }),
  preWrap: () => ({
    styles: `
      white-space: pre-wrap;
    `,
  }),
  lineThrough: () => ({
    styles: `
      text-decoration: line-through;
    `,
  }),
  left: () => ({
    styles: `text-align: left;`,
  }),
  center: () => ({
    styles: `text-align: center;`,
  }),
  right: () => ({
    styles: `text-align: right;`,
  }),
};

export const TextStyled = styled.p.attrs({
  className: 'Text',
})`
  font-family: ${({ theme }) => theme.farmProject.font_family_text};
  font-size: 0.875rem;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.farmProject.dark_gray};
  padding: 0;
  margin: 0;
  * {
    font-size: inherit;
  }
  a {
    text-decoration: underline;
  }

  ${applyStyleModifiers(modifiers)};
`;
