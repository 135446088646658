import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { setUserZip } from 'redux/user';
import { Text, ButtonText } from 'ui-redesign/elements';
import { Input } from 'ui-redesign/elements';
import { ZipCode } from './ZipCodeEditor.styled';

const validateZip = (zipcode) => /(^\d{5}$)/.test(zipcode);

const ZipCodeEditor = ({ isReadOnly, shouldHideLabel, modifiers }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [zipcode, setZipcode] = useState(user.zip);
  const [isValidZip, setIsValidZip] = useState(validateZip(zipcode));
  const [isOpenZip, setIsOpenZip] = useState(false);
  const node = useRef();

  useEffect(() => {
    const handleDocumentClick = (e) => {
      // click outside editor
      if (get(node, 'current.contains') && !node.current.contains(e.target)) {
        reset();
      }
    };

    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const reset = () => {
    setZipcode('');
    setIsOpenZip(false);
  };

  const onChangeZip = (event) => {
    const value = event.target.value;
    if (value.length > 5) return;

    setZipcode(value);

    if (!isValidZip && validateZip(value)) {
      setIsValidZip(value);
    }
  };

  const onZipKeyPress = ({ keyCode }) => {
    const enterKeyCode = 13;
    const escKeyCode = 27;

    switch (keyCode) {
      case enterKeyCode:
        updateZipCode();
        break;
      case escKeyCode:
        reset();
        break;
      default:
        break;
    }
  };

  const updateZipCode = () => {
    const isValid = validateZip(zipcode);
    setIsValidZip(isValid);
    if (isValid) {
      dispatch(setUserZip(zipcode.toString()));
    }
  };

  const isMediumZip = modifiers && (typeof modifiers === 'string' ? modifiers === 'medium' : modifiers.includes('medium'));

  return (
    <ZipCode ref={node} modifiers={[isOpenZip && 'openInput'].concat(modifiers)}>
      {!shouldHideLabel && (
        <Text modifiers={isMediumZip && 'small'} as='span' content={isReadOnly ? 'BASED ON ZIP CODE:' : 'ENTER YOUR ZIP CODE:'} />
      )}
      <ButtonText label={user.zip} onClick={() => !isReadOnly && setIsOpenZip(!isOpenZip)} dataNw='zip-button' />
      {isOpenZip && (
        <Input
          autoFocus
          variant='standard'
          inputMode='numeric'
          type='number'
          modifiers={['standard', 'brandForStandard']}
          placeholder={user.zip.toString()}
          value={zipcode.toString()}
          onChange={onChangeZip}
          onKeyDown={onZipKeyPress}
          dataNw='zip-input'
          error={zipcode.toString().length && !isValidZip ? 'Zip code must be five digits' : ''}
        />
      )}
      {isOpenZip && (
        <ButtonText label='SAVE' onClick={updateZipCode} modifiers={['brand', 'underlined']} disabled={!isValidZip} dataNw='zip-save' />
      )}
    </ZipCode>
  );
};

ZipCodeEditor.propTypes = {
  isReadOnly: PropTypes.bool,
  shouldHideLabel: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

export default ZipCodeEditor;
