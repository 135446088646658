import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme-redesign/Media';

export const modifiers = {
  leftText: () => ({
    styles: `
      .SectionInner {
        .Title {
          text-align: left;
        }
        .Text {
          text-align: left;
        }
      }
    `,
  }),
  rigthText: () => ({
    styles: `
      .SectionInner {
        .Title {
          text-align: right;
        }
        .Text {
          text-align: right;
        }
      }
    `,
  }),
  inverted: ({ theme }) => ({
    styles: `
      .Title {
        color: ${theme.farmProject.gray_3};
      }
      .Text {
        color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  wBackgroundImage: () => ({
    styles: `
      overflow: hidden;

      .SectionInner {
        .Image {
          height: 100vh;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;

          picture {
            width: 100%;
            height: 100%;

            img {
              width: auto;
              height: 100%;
            }
          }
        }
      }
    `,
  }),
  fadeInUp: ({ animationState }) => ({
    styles: `
      @media ${breakPoints.medium_break} {
        animation-name: fadeInUp;
      }

      animation-duration: 2s;
      animation-play-state: ${animationState};
      will-change: animation-play-state;

      @keyframes fadeInUp {
        from {
          opacity: 0;
          transform: translate3d(0, 5rem, 0);
        }

        to {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    `,
  }),

  fadeMixDownUp: ({ animationState }) => ({
    styles: `
      @media ${breakPoints.medium_break} {
        > div {
          animation-name: fadeInDown;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          @keyframes fadeInDown {
            from {
              opacity: 0;
              transform: translate3d(0, -2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }

        > div + div {
          animation-name: fadeInUp;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translate3d(0, 2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    `,
  }),

  fadeMixUpDown: ({ animationState }) => ({
    styles: `
      @media ${breakPoints.medium_break} {                
        > div {
          animation-name: fadeInDown;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          @keyframes fadeInDown {
            from {
              opacity: 0;
              transform: translate3d(0, -2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }

        > div + div {
          animation-name: fadeInUp;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translate3d(0, 2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    `,
  }),

  crossFade: ({ animationState }) => ({
    styles: `
      @media ${breakPoints.medium_break} {                
        > div {
          animation-name: fadeInDown;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          @keyframes fadeInDown {
            from {
              opacity: 0;
              transform: translate3d(0, 2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }

        > a {
          animation-name: slideDown;
          animation-duration: 2s;
          animation-play-state: ${animationState};
          will-change: animation-play-state;

          boder: solid 2px black;

          @keyframes slideDown {
            from {
              opacity: 0;
              transform: translate3d(0, -2rem, 0);
            }
    
            to {
              opacity: 1;
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    `,
  }),
};

export const ContentSectionStyled = styled.div.attrs({
  className: 'ContentSection',
})`
  width: 100%;
  height: auto;
  padding: 5rem 2rem;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  position: relative;
  box-sizing: border-box;

  ${applyStyleModifiers(modifiers)};
`;

export const SectionInner = styled.div.attrs({
  className: 'SectionInner',
})`
  width: 100%;
  height: auto;

  .Title {
    /* font-size: 2.25rem;
    line-height: 1.17; 
    margin-bottom: 3rem; */
    text-align: center;
    position: relative;
    z-index: 10;
  }

  .Text {
    text-align: center;
    position: relative;
    z-index: 10;
  }

  .Button {
    position: relative;
    z-index: 10;
  }

  ${applyStyleModifiers(modifiers)};
`;
