import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import store from 'redux/store';
import styled from 'styled-components/macro';

import { Link, Image } from 'ui-redesign/elements';
import Text from 'components/elements/Text';
import Button from 'components/elements/Button';
import ResponsiveIframe from 'components/deprecated/ResponsiveIframe';

import sizes from 'constants/sizes';

const getMessageByCode = (code) => {
  const messages = store.getState().contentful.messages;
  if (!messages) return;
  const message = messages[code];
  return message ? message : '';
};

const ExtendedButton = styled(Button)`
  margin: 3em 0;
  text-align: center;
`;

const ExtendedImage = styled(Image)`
  max-width: 100%;
`;

const parseRichText = (richText, history) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_6]: (node, children) => {
        return <h6 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return <h5 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return <h4 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 dangerouslySetInnerHTML={{ __html: children[0] }} />;
      },
      [BLOCKS.QUOTE]: (node, children) => {
        return <blockquote dangerouslySetInnerHTML={{ __html: get(node, 'content[0].content[0].value') }} />;
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return children.map((child, idx) => {
          if (child.props && child.props.href) {
            return (
              <Link key={idx} modifiers={['darkGrayColor', 'fontFamilyText', 'underline']} href={child.props.href}>
                {child.props.children}
              </Link>
            );
          }
          return (
            <Text key={idx} size={sizes.SMALLISH} asHTML={typeof child === 'string'} isInline>
              {child}
            </Text>
          );
        });
      },
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <Image picture={node.data.target.fields?.file?.url} alt={node.data.target.fields?.title} />;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const entry = node.data.target;
        const entryType = entry ? get(entry, 'sys.contentType.sys.id') : null;
        if (entryType === 'callToAction') {
          if (!_isEmpty(entry.fields.accent)) {
            return (
              <Link href={entry.fields.linkUrl}>
                <ExtendedImage picture={entry.fields.accent.fields.file.url} alt='' className='BlogCta' />;
              </Link>
            );
          } else {
            return <ExtendedButton text={entry.fields.linkText} to={entry.fields.linkUrl} />;
          }
        } else {
          return <ResponsiveIframe embedCode={node.data.target.fields.embedCode} />;
        }
      },
    },
  };

  return documentToReactComponents(richText, options);
};

export const getField = (item = {}, fieldName = '', content = []) => {
  const fields = item?.[fieldName] ? item : item?.fields ? item.fields : content?.find?.((e) => e.sys.id === item?.sku)?.fields;
  return fields?.[fieldName] || null;
};

export { getMessageByCode, parseRichText };
