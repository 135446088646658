import React from 'react';
import PropTypes from 'prop-types';

import Wrapper, { Input, Label } from './TextArea.styled';
import { Text } from 'ui-redesign/elements';

/**
 * * Text Area - TextArea input field
 */

const TextArea = ({ label, field, form, required, width, setFieldValue, customBlur, helperText, ...rest }) => {
  const errorMsg = form.errors[field.name];
  const hasError = !!errorMsg && form.touched[field.name];
  const blurHandler = (ev) => {
    // Call custom onBlur
    if (customBlur) customBlur(form.values);

    // Call formik onBlur
    field.onBlur(ev);
  };
  return (
    <>
      <Wrapper width={width}>
        <Input placeholder=' ' {...rest} {...field} onBlur={blurHandler} hasError={hasError} />
        <Label required={required} hasError={hasError}>
          {label}
        </Label>
      </Wrapper>

      <Text content={hasError ? errorMsg : helperText} modifiers={['small', 'brandFont', hasError && 'error']} />
    </>
  );
};

TextArea.defaultProps = {
  type: 'text',
  width: '100%',
};

TextArea.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
};

export default TextArea;
