import { client } from 'utils/contentful-client';
import { fetchAppSettings, fetchAppSettingsStart, fetchAppSettingsEnd, fetchAppSettingsError, setAppSettings } from 'redux/appSettings';
import logError from 'utils/errorHandler';
/**
 * * Redux Middleware for App Settings fetch action - FETCH_APP_SETTINGS
 *
 * @param {Parameters in action.payload}
 * @param content_type - contentful content type
 * @param include - default contentful - 3
 * @param label - contentful name ie. home, phoneNumber
 *
 */

const appSettingsMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  const store = getState();

  // Middleware only for getting app setting content types
  if (action.type !== fetchAppSettings.toString()) return;

  // set loading state
  dispatch(fetchAppSettingsStart());
  client({ content_type: 'appSettings' }, store)
    .getEntries({ content_type: 'appSettings' })
    .then(data => {
      dispatch(setAppSettings({ data }));
      dispatch(fetchAppSettingsEnd());
    })
    .catch(error => {
      // set error in appSettings store
      dispatch(fetchAppSettingsError({ error: error.message }));
      // dispatch error for logging
      logError(error);
      dispatch(fetchAppSettingsEnd());
    });
};

export default appSettingsMiddleware;
