import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setLettuceModal } from 'redux/lettuceModal';

/**
 *
 * * useHideNewsletter - Custom hook to remove newsletter
 * Handles cases:
 * Hiding newsletter popup for the entire lifecycle of a component (hideCondition is always true)
 * Hiding newsletter popup when the passed 'hideCondition' is true, showing when false
 *
 * */

export default function useHideNewsletter(hideCondition = true) {
  const dispatch = useDispatch();
  const lettuceModal = useSelector((state) => state.lettuceModal);
  useEffect(() => {
    if (hideCondition) {
      dispatch(setLettuceModal({ label: 'isForceHidden', value: true }));
    } else if (lettuceModal.isForceHidden) {
      dispatch(setLettuceModal({ label: 'isForceHidden', value: false }));
    }
    return () => {
      dispatch(setLettuceModal({ label: 'isForceHidden', value: false }));
    };
    /* eslint-disable-next-line */
  }, [hideCondition, dispatch]);
}
