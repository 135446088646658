import React from 'react';

import { IconStyled } from './Icon.styled';

const Icon = ({ modifiers, children, onClick, className }) => (
  <IconStyled modifiers={modifiers} onClick={onClick} tabIndex={onClick ? 0 : -1} className={className}>
    {children}
  </IconStyled>
);

export default Icon;
