import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from '../../../theme-redesign/Media';

export const modifiers = {
  inverted: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};

      .Text {
        a {
          color: ${theme.farmProject.accent_primary};
        }
      }
    `,
  }),
};

export const CookieComponentStyled = styled.div.attrs({
  className: 'CookieComponentStyled',
})`
  width: 100%;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  position: fixed;
  bottom: 0;
  z-index: 1000;

  ${applyStyleModifiers(modifiers)};
`;

export const ContentInfo = styled.div.attrs({
  className: 'ContentInfo',
})`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .Text {
    text-align: center;
    margin-bottom: 1rem;
  }

  .Icon {
    margin: 0 0 0rem 0.5rem;
    height: 1.25rem;
  }

  @media ${breakPoints.medium_break} {
  align-items: center;
    padding: 2.5rem;
    .Text {
      text-align: left;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }
`;
