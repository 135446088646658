import styled from 'styled-components';
import breakPoints from '../../theme-redesign/Media';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const NotFoundSection = styled.div.attrs({
  className: 'NotFoundSection',
})`
  .ContentSection {
    .SectionInner {
      .Image {
        width: 100%;
        max-width: 13rem;
        height: auto;
        margin: auto;
        margin-bottom: 2rem;

        picture {
          img {
            position: relative;
            top: auto;
            left: 10px;
            transform: none;
          }
        }
      }

      .ContentWrapper {
        .Title {
          font-size: 4rem;
          margin-bottom: 1rem;

          span {
            font-style: italic;
          }
        }

        .Button {
          width: 12rem;
          margin: auto;
          margin-top: 1.5rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .Image {
          width: 27rem;
          height: auto;
        }

        .ContentWrapper {
          .Title {
            font-size: 8rem;

            span {
              font-style: italic;
            }
          }

          .Text {
            max-width: 25rem;
            margin: auto;
          }

          .Button {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding-bottom: 5rem;

      .SectionInner {
        max-width: 60rem;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > a {
          width: calc(50% - 2rem);
        }

        .Image {
          margin: auto;
          margin-bottom: 0;
          width: 100%;
          max-width: 23rem;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            text-align: left;
            position: relative;
            left: -10px;

            span {
              font-style: italic;
            }
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
