import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { SlimBanner } from 'ui-redesign/components';
import { Text, Link, Icon } from 'ui-redesign/elements';
import Carousel from 'components/elements/Carousel';
import MessageBannerStyled from './MessageBanner.styled';
import { ReactComponent as InfoIcon } from 'ui-redesign/elements/icon/assets/information-icon.svg';

import { MESSAGE_BANNER } from 'constants/contentful';
import paths from 'constants/paths';
import { useBreakpoint, useContent, useQueryParams } from 'utils/hooks';
import { getLoginState, getCustomer } from 'redux/user';

const MessageBanner = ({ location }) => {
  const content = useContent(MESSAGE_BANNER);
  const { source } = useQueryParams('pc');
  const hasPromo99 = source === '99pi';
  const isLoggedIn = useSelector(getLoginState);
  const viewportSize = useBreakpoint();
  const isMobile = viewportSize === 'SM';
  const customer = useSelector(getCustomer);
  const farmstandLink =
    location.pathname === paths.FARMSTAND || location.pathname === paths.SHOP_PRODUCTS
      ? `${location.pathname}#shop-farmstands`
      : paths.FARMSTAND;

  if (!content) return null;

  const fields = content[0].fields;

  const autoplaySpeed = (fields.type && Number(fields.type)) || 4000;
  return (
    <MessageBannerStyled>
      {isLoggedIn && customer?.referralCode ? (
        <SlimBanner key='refer-banner' modifiers='inverted'>
          <Text
            modifiers={['brandFont', 'small']}
            as='span'
            isHTML
            content={`<p><b>Give $50, Get $25</b>${isMobile ? '<br>' : ' - '}SHARE YOUR CODE: <b>${customer.referralCode}</b></p>`}
          ></Text>
        </SlimBanner>
      ) : (
        <Carousel shouldHideArrows shouldAutoplay autoplaySpeed={autoplaySpeed}>
          {hasPromo99 && (
            <SlimBanner key='promo-banner' modifiers='inverted'>
              <Text modifiers={['brandFont', 'small']} as='span'>
                <p>
                  99% Invisible Fans - Use Code <b>99percentpod</b> for an Exclusive Discount!{' '}
                  <Link href={paths.SHOP_PRODUCTS} content='SHOP NOW'></Link>
                </p>
              </Text>
            </SlimBanner>
          )}
          {fields.percentages.map((block, idx) => {
            const linkText = block.fields.primaryButton?.fields?.linkText;
            const linkUrl = block.fields.primaryButton?.fields?.linkUrl;
            const link = linkUrl === paths.FARMSTAND ? farmstandLink : linkUrl;
            return (
              <SlimBanner key='farmstand-banner' modifiers='inverted'>
                <Text isHTML modifiers={['brandFont', 'small']} content={`${block.fields.body}`} as='span'></Text>
                {idx === 1 && (
                  <span className='affirm-product-modal' data-page-type='product' data-amount={46900}>
                    <Icon modifiers={['small', 'inverted']}>
                      <InfoIcon />
                    </Icon>
                  </span>
                )}
                {!!linkText && !!linkUrl && <Link modifiers={['underline', 'accentColor', 'small']} content={linkText} href={link} />}
              </SlimBanner>
            );
          })}
        </Carousel>
      )}
    </MessageBannerStyled>
  );
};

MessageBanner.defaultProps = {};

MessageBanner.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default MessageBanner;
