import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import PropTypes from 'prop-types';
import { minBy as _minBy, maxBy as _maxBy, isEmpty as _isEmpty, find as _find } from 'lodash';

import { ButtonText, Title, Text, SquaredButton } from 'ui-redesign/elements';
import { ProductGift, ProductGiftList, StarRating, PromoToggle, PromoPrice } from 'ui-redesign/components';

import {
  BundleItems,
  FarmSelectSize,
  FarmGlowRings,
  FarmIconFeatures,
  FarmAccordion,
  FarmCarouselMobile,
  FarmCarouselDesktop,
} from './components';

import { HeroWrapper, HeroDetailStyled, HeroDetailInner, ProductInformation, ProductDescription } from './FarmProductDetail.styled';

import useCarouselPosition from './utils/useCarouselPosition';
import { replaceText } from 'utils/string-utils';
import { viewedProduct } from 'utils/klaviyo-utils';
import { formatDynamicDecimalPrice as formatPrice } from 'utils/cart-utils';
import scrollIntoView from 'utils/scrollIntoView';
import paths from 'constants/paths';
import { glowRingsMapSizeSku } from 'constants/sku';
import breakpoints from 'theme-redesign/Media';
import { useCatalog } from 'utils/hooks';

import { getFarmstands, getGlowRings, getSupplies } from 'redux/catalog';
import { fetchReviews, YOTPO_GLOW_RINGS_ID, YOTPO_FARMSTAND_ID } from 'redux/userGenerated/userGenerated';

const HeroDetail = ({
  history,
  location = {},
  title,
  lowerTitle,
  buttonSizesTitle,
  body,
  glowRingsSection = {},
  promoSection = {},
  giftsSection = {},
  infoSection = {},
  accordionSection = [],
  bundlesSection = {},
  bundlesPromoSection = {},
  bundlePrices = [],
  mediaBackground = [],
  leftBackgroundAccent = {},
  farmstandsData: farmstandsContent = [],
  isGlowRing = false,
  hasScrollBehavior = false,
  onAddToCart,
}) => {
  const isBundlePage = !!bundlesSection;
  const isGlowOrBundle = isGlowRing || isBundlePage;
  const isLargeDesktop = useMediaQuery(`${breakpoints.large_break}`, { noSsr: true });
  const dispatch = useDispatch();
  const refImageSlider = useRef();
  const farmstandsCatalog = useSelector(getFarmstands);
  const glowRingsCatalog = useSelector(getGlowRings);
  const suppliesCatalog = useSelector(getSupplies);
  useCatalog();
  const reviews = useSelector((state) => state.userGenerated.reviews[isGlowRing ? YOTPO_GLOW_RINGS_ID : YOTPO_FARMSTAND_ID]);
  const [farmIndex, setFarmIndex] = useState(-1);
  const [isGRAdded, setIsGRAdded] = useState(false);
  const [isPromoSelected, setIsPromoSelected] = useState(false);
  const { shouldBeFixed, shouldBeAbsolute, navHeight } = useCarouselPosition(isGlowRing, isBundlePage);

  useEffect(() => {
    // unique logic here to handle this component on shop page for farmstand only
    // shop page does not have <ReviewsList> component which normally is responsible for fetching reviews and hydrating redux slice
    if (!isGlowRing && !reviews.all.length) {
      dispatch(fetchReviews(YOTPO_FARMSTAND_ID));
    }
  }, [isGlowRing, reviews.all, dispatch]);

  const canUseStickEffect = hasScrollBehavior && isLargeDesktop;
  const selectedFarmstand = farmstandsContent[farmIndex];
  const giftPlaceholder = giftsSection?.percentages?.[0] || null;
  const carouselImages = mediaBackground?.map((e) => ({ id: e?.sys?.id || '', ...(e?.fields || {}) }));
  const glowRingsSlugs = (isGlowRing && glowRingsCatalog?.map((e) => e?.slug)) || [];
  const glowRingImage = leftBackgroundAccent?.fields?.file?.url;

  const minPrice = formatPrice(
    _minBy(isBundlePage ? bundlePrices : isGlowRing ? glowRingsCatalog : farmstandsCatalog, (e) => e?.priceCents)?.priceCents || 0,
    false
  );
  const maxPrice = formatPrice(
    _maxBy(isBundlePage ? bundlePrices : isGlowRing ? glowRingsCatalog : farmstandsCatalog, (e) => e?.priceCents)?.priceCents || 0,
    false
  );
  const showingGlowRing =
    (!!selectedFarmstand && glowRingsCatalog?.find((e) => e?.sku === glowRingsMapSizeSku[selectedFarmstand?.size])) || null;
  const productPriceOrRange = !selectedFarmstand
    ? `$${minPrice} - $${maxPrice}`
    : isGlowOrBundle
    ? formatPrice(
        showingGlowRing?.priceCents +
          (isBundlePage ? (selectedFarmstand?.price + selectedFarmstand?.size * 2) * 100 - (bundlePrices?.[farmIndex]?.discount || 0) : 0)
      )
    : formatPrice(selectedFarmstand?.price * 100);
  const hasFarmstand = !!selectedFarmstand && !_isEmpty(selectedFarmstand);

  const glowRingAddPrice = (isGRAdded && showingGlowRing?.priceCents) || 0;
  const buttonPrice = isGlowOrBundle ? productPriceOrRange : formatPrice(selectedFarmstand?.price * 100 + glowRingAddPrice);
  const buttonText = hasFarmstand ? `ADD TO CART - ${buttonPrice}` : 'SELECT A SIZE';

  const farmSectionRef = useCallback(
    (node) => {
      if (node && farmstandsContent.length && location?.pathname?.includes?.(paths.SHOP_PRODUCTS)) scrollIntoView(location, node);
    },
    [location, farmstandsContent.length]
  );

  const onSelectFarmstand = (index) => {
    if (isGlowRing && glowRingsCatalog[index]) {
      const { priceCents, name, slug, category, sku, imageUrl } = glowRingsCatalog[index];
      viewedProduct({
        priceCents,
        name,
        slug,
        category,
        sku,
        imageUrl,
      });
    } else if (farmstandsContent[index]) {
      const { price, name, slug, id, mediaMain } = farmstandsContent[index];
      viewedProduct({
        priceCents: price * 100,
        name,
        slug,
        category: 'Farmstand',
        sku: id,
        imageUrl: mediaMain?.fields?.file?.url,
      });
    }

    return setFarmIndex(index);
  };

  const onSelectGlowRing = (isGlowRingAdded) => setIsGRAdded(isGlowRingAdded);

  const onAddFarmstand = () => {
    const { relatedGifts = [] } = selectedFarmstand;
    const farmstandFromCatalog = _find(farmstandsCatalog, { sku: selectedFarmstand.id }) || null;
    const glowRingFromCatalog = isGRAdded || isGlowOrBundle ? showingGlowRing : null;

    if (onAddToCart) {
      onAddToCart({
        farmstandCatalog: farmstandFromCatalog,
        glowRingCatalog: glowRingFromCatalog,
        relatedGifts,
        isPromoSelected,
      });
    }
  };

  const onChangePromoCheck = ({ target }) => setIsPromoSelected(target.value !== 'true');

  const renderGiftLine = (giftData, index) => {
    const { shortDescription, detailImage } = giftData?.fields || {};
    const { priceCents = 0, name = '' } = suppliesCatalog[giftData?.sys?.id] || {};

    if (!name) return null;
    return (
      <ProductGift
        key={index}
        productName={name}
        productDescription={shortDescription}
        productImage={detailImage}
        newPrice='FREE GIFT'
        oldPrice={formatPrice(priceCents)}
      />
    );
  };

  const renderGiftPlaceholder = () => {
    const { name, shortDescription, detailImage } = giftPlaceholder?.fields || {};
    const { priceCents = 0 } = suppliesCatalog[giftPlaceholder?.sys?.id || ''] || {};

    if (!name) return null;
    return (
      <ProductGift
        modifiers='disabled'
        productName={name}
        productDescription={shortDescription}
        productImage={detailImage}
        newPrice='FREE GIFT'
        oldPrice={formatPrice(priceCents)}
      />
    );
  };

  return (
    <HeroWrapper id='shop-farmstands' ref={farmSectionRef}>
      <HeroDetailStyled
        navHeight={navHeight}
        modifiers={canUseStickEffect && ((shouldBeAbsolute && 'absolute') || (shouldBeFixed && 'fixed'))}
      >
        <HeroDetailInner>
          {isLargeDesktop ? (
            <FarmCarouselDesktop ref={refImageSlider} data={carouselImages} promo={bundlesPromoSection?.body} />
          ) : (
            <FarmCarouselMobile ref={refImageSlider} data={carouselImages} promo={bundlesPromoSection?.body} />
          )}
          <ProductInformation isGlowRing={isGlowRing}>
            <Title as='h2' modifiers='secondarySmall' content={title} isHTML />
            {isBundlePage && bundlePrices?.[farmIndex]?.discount ? (
              <PromoPrice
                newPrice={formatPrice(bundlePrices[farmIndex].priceCents - bundlePrices[farmIndex].discount)}
                oldPrice={formatPrice(bundlePrices[farmIndex].priceCents)}
              />
            ) : (
              <Text as='span' modifiers={['brandFont']}>
                {productPriceOrRange}
              </Text>
            )}
            <Text modifiers={['italic']} content={replaceText(lowerTitle, [{ key: '__PRICE__', value: Math.ceil(minPrice / 12) }])} />
            <div>
              <StarRating readOnly rating={Math.round(reviews.average)} />
              <ButtonText
                label={`(${reviews.total} ${reviews?.total === 1 ? 'REVIEW' : 'REVIEWS'})`}
                href={`${isGlowRing ? history.location.pathname : paths.FARMSTAND}#reviews-and-questions`}
                modifiers={['underlineContent', 'compact', 'secondary']}
              ></ButtonText>
            </div>
            <ProductDescription>
              <Text content={body} isHTML />
            </ProductDescription>
            <FarmSelectSize
              title={buttonSizesTitle}
              data={farmstandsContent}
              onSelectFarmstand={onSelectFarmstand}
              imgSliderRef={refImageSlider}
              glowRingSlugs={glowRingsSlugs}
              glowRingsNumberLabel={`${hasFarmstand ? farmIndex + 2 : 0} GLOW RINGS️`}
              isBundlePage={isBundlePage}
              hasFarmstand={hasFarmstand}
              history={history}
            />
            {(!isGlowOrBundle || !!glowRingsSection) && (
              <FarmGlowRings
                image={glowRingImage}
                glowRing={showingGlowRing}
                hasFarmstand={hasFarmstand}
                onSelectGlowRing={onSelectGlowRing}
                {...glowRingsSection}
              />
            )}
            {!_isEmpty(promoSection) && (
              <PromoToggle content={promoSection?.body} onChange={onChangePromoCheck} modifiers={isPromoSelected && 'checked'} />
            )}
            <SquaredButton
              modifiers={['tertiary', !hasFarmstand && 'newDisabled']}
              label={buttonText}
              onClick={onAddFarmstand}
              dataNw='add-farmstand-to-cart'
            />
            {isBundlePage && (
              <BundleItems title={bundlesSection?.title} glowRing={showingGlowRing} bundleItems={bundlesSection?.percentages || []} />
            )}
            {!isGlowOrBundle && (!_isEmpty(selectedFarmstand?.relatedGifts) || !!giftPlaceholder) && (
              <ProductGiftList listTitle={!_isEmpty(selectedFarmstand?.relatedGifts) ? giftsSection?.title : giftsSection?.lowerTitle}>
                {!_isEmpty(selectedFarmstand?.relatedGifts)
                  ? selectedFarmstand?.relatedGifts?.map(renderGiftLine)
                  : !!giftPlaceholder && renderGiftPlaceholder()}
              </ProductGiftList>
            )}
            <FarmIconFeatures data={infoSection} />
            {!!accordionSection && <FarmAccordion data={accordionSection} />}
          </ProductInformation>
        </HeroDetailInner>
      </HeroDetailStyled>
    </HeroWrapper>
  );
};

HeroDetail.defaultProps = {
  hasScrollBehavior: false,
  buttonSizesTitle: null,
  glowRingsSection: null,
  infoSection: null,
  accordionSection: null,
  promoSection: null,
  giftsSection: null,
  bundlesSection: null,
  bundlesPromoSection: null,
  farmstandsData: null,
};

HeroDetail.propTypes = {
  isGlowRing: PropTypes.bool,
  title: PropTypes.string,
  lowerTitle: PropTypes.string,
  buttonSizesTitle: PropTypes.string,
  body: PropTypes.string,
  glowRingsSection: PropTypes.object,
  infoSection: PropTypes.array,
  accordionSection: PropTypes.array,
  promoSection: PropTypes.object,
  giftsSection: PropTypes.object,
  bundlesSection: PropTypes.object,
  bundlesPromoSection: PropTypes.object,
  bundlePrices: PropTypes.array,
  farmstandsData: PropTypes.array,
  mediaBackground: PropTypes.array,
  leftBackgroundAccent: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  hasScrollBehavior: PropTypes.bool,
  onAddToCart: PropTypes.func,
};

export default HeroDetail;
