import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { setIsLoading } from 'redux/appSettings';

import paths from 'constants/paths';
import LGTagManager from 'utils/googleTagManager';

const globalLoadingPaths = [
  paths.HOME,
  paths.LOGIN,
  paths.EXPERIENCE,
  paths.FARMSTAND,
  paths.FILL_YOUR_FARMSTAND,
  paths.FILL_YOUR_FARMSTAND_BUNDLES,
  paths.OUR_MISSION,
  paths.BLOG,
  paths.RECIPES,
  paths.SHOP_PRODUCTS,
  paths.SEEDLINGS,
  paths.SEEDLING_DETAIL,
  paths.FAQ,
  paths.SURVEY_RESULTS,
  paths.CONTACT_US,
  paths.VIRTUAL_SHOPPING,
  paths.PROMOTION_PAGE,
  paths.PROMOTION_PAGE_2,
];

// actions and events based on location change

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const dispatchLoading = () => {
      const { pathname } = location || {};
      const isSeedSubpath = pathname.startsWith(`${paths.SEEDLINGS}/`);
      const isShopSubpath = pathname.startsWith(`${paths.SHOP_PRODUCTS}/`);
      const isFAQSubpath = pathname.startsWith(`${paths.FAQ}/`);
      const isRecipeSubpath = pathname.startsWith(`${paths.RECIPES}/`);
      const isBlogSubpath = pathname.startsWith(`${paths.BLOG}/`);
      const isFarmSubpath = pathname.startsWith(`${paths.FARMSTAND}/`);
      const isSubpath = isSeedSubpath || isShopSubpath || isFAQSubpath || isRecipeSubpath || isBlogSubpath || isFarmSubpath;
      const isLoadingPath = !!globalLoadingPaths.find((path) => pathname === path) || isSubpath;

      if (isLoadingPath) {
        dispatch(setIsLoading(true));
      }
    };

    dispatchLoading();

    const params = location ? queryString.parse(location.search) : '';
    LGTagManager.pushPath(location.pathname, params);
    // eslint-disable-next-line
  }, [location]);
};
