import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme-redesign/Media';
import oldBreakpoints from 'constants/breakpoints';

export const modifiers = {
  backgroundGray2: ({ theme }) => ({
    styles: `
      .TooltipStyled {
        background-color: ${theme.farmProject.gray_2};
      }
    `,
  }),
  showTip: () => ({
    styles: `      
      .TooltipStyled {
        display: block;
      }
      .Icon:after {
        display: block;
      }
    `,
  }),
};

export const TooltipStyled = styled.div.attrs({
  className: 'TooltipStyled',
})`
  max-width: 30rem;
  background-color: ${({ theme }) => theme.farmProject.gray_3};
  padding: 0.75rem 1.125rem;
  border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  border-radius: 0.5rem;
  margin: auto;
  display: none;
  z-index: 1;
  width: 56vw;
  position: relative;
  transform: translate(-50%, -10%);

  .Text {
    font-weight: normal;
    display: block;
  }

  @media ${breakPoints.medium_break} {
    width: 53vw;
    position: relative;
    transform: translate(-30%, -5%);
  }

  @media (min-width: ${oldBreakpoints.MOBILE}px) {
    width: 31vw;
    transform: translate(-61%, -4%);
  }

  @media ${breakPoints.large_break} {
    width: 20vw;
    transform: translate(-64%, -3%);
    left: 1rem;
  }
`;

export const TipIconWrapper = styled.div.attrs({
  className: 'TipIconWrapper',
})`
  display: block;
  width: 1rem;
  height: 1rem;
  top: 3rem;
  right: 2.5rem;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  .Icon {
    width: 10px;
    height: 34px;
    top: 2px;
    left: 4px;

    &:after {
      content: '';
      width: 1px;
      height: 33px;
      background-color: ${({ theme }) => theme.farmProject.dark_gray};
      display: none;
      position: relative;
      margin-top: 0.75rem;
      margin-left: 0.3rem;
    }

    svg {
      top: 18%;
      g {
        fill: transparent;

        circle:first-of-type {
          r: 7.5;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    &:hover {
      .TooltipStyled {
        display: block;
      }
      .Icon:after {
        display: block;
      }
    }
  }

  ${applyStyleModifiers(modifiers)}
`;
