import styled, { css } from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { MuiFormHelperText } from 'theme-redesign/Mixins';

export const modifiers = {
  compact: ({ hasValue }) => ({
    styles: `
    height: 2.5rem;

    .Icon {
      top: 1.3rem;
    }
    select {
      height: unset;
      padding: 0.62rem 1rem 0.62rem 0;
      line-height: normal;

      &:active,
      &:focus {
        ~ label {
          transform: translate(-0.3rem, -3.55rem) scale(0.75);
        }
      }

      ${
        hasValue
          ? css`
              ~ label {
                transform: translate(-0.3rem, -3.55rem) scale(0.75);
              }
            `
          : css`
              ~ label {
                transform: translate(0.75rem, -2.15rem) scale(1);
              }
            `
      }
    }

    .MuiFormHelperText-root {
      margin: -1rem 0rem 0.6rem;
    }
    `,
  }),
};

export const SelectStyled = styled.div.attrs({
  className: 'SelectStyled',
})`
  width: 100%;
  height: 3.5rem;
  margin: ${({ hasError }) => (hasError ? '2rem auto 1rem' : '2rem auto 0.5rem')};
  text-align: left;
  position: relative;

  .Icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 2rem;
    left: auto;
    right: 1rem;
    transform: translate(-50%, -50%);
    transition: all 0.25s ease-in-out;
    pointer-events: none;
  }

  &:active,
  &:focus,
  &:focus-within {
    .Icon {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  select {
    border: none;
    border-bottom: ${({ hasError }) => (hasError ? '2px solid' : '1px solid')};
    border-radius: 0;
    background: ${({ theme }) => theme.farmProject.gray_2};
    border-color: ${({ theme, hasError }) => (hasError ? theme.farmProject.error : theme.farmProject.dark_gray)};
    padding: 1.7rem 0.7rem 0.6rem;
    width: 100%;
    height: 3.5rem;

    svg {
      width: 3rem;
      height: 3rem;
    }

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
      border-width: 2px;
      border-color: ${({ theme, hasError }) => (hasError ? theme.farmProject.error : theme.farmProject.brand_primary)};
    }

    &:active,
    &:focus {
      ~ label {
        transform: translate(0.3rem, -3.2rem) scale(0.75);
      }
    }

    ${({ hasValue }) =>
      hasValue
        ? css`
            ~ label {
              transform: translate(0.3rem, -3.2rem) scale(0.75);
            }
          `
        : css`
            ~ label {
              transform: translate(0.75rem, -2.35rem) scale(1);
            }
          `}
  }

  label {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: unset;
    color: ${({ theme, hasError }) => (hasError ? theme.farmProject.error : theme.farmProject.dark_gray)};
    display: inline-block;
    transition: transform 300ms;
    pointer-events: none;
    text-align: left;
    letter-spacing: normal;
  }

  // error text
  .Text {
    font-size: 0.75rem;
    margin-top: -0.8rem;
  }

  ${MuiFormHelperText};
  .MuiFormHelperText-root {
    margin: -1rem 0.8rem 0.6rem;
    position: absolute;
    top: auto;
    left: auto;
    bottom: -2.125rem;
  }

  ${applyStyleModifiers(modifiers)};
`;
