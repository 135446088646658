import { createSlice, createSelector } from '@reduxjs/toolkit';

/**
 * * Modal - Redux Reducer - controls a single modal opened at a time, records opened history of modal types
 *
 * @param state
 *
 */

export const MODALS = {
  CLIMATE: 'climate',
  VERIFY_ADDRESS: 'verifyAddress',
  PASSWORD_RESET: 'passwordReset',
  LOGIN: 'login',
  ERROR: 'error',
  REFERRAL: 'referral',
};

const initialState = {
  open: '',
  climate: { id: MODALS.CLIMATE, hasBeenOpen: false },
  verifyAddress: { id: MODALS.VERIFY_ADDRESS, hasBeenOpen: false },
  passwordReset: { id: MODALS.PASSWORD_RESET, hasBeenOpen: false },
  login: { id: MODALS.LOGIN, hasBeenOpen: false },
  error: { id: MODALS.ERROR, label: 'Unexpected Error', hasBeenOpen: false },
  referral: { id: MODALS.REFERRAL, hasBeenOpen: false },
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setLabel(state, { payload }) {
      state[payload.id].label = payload.label;
    },
    setOpenModal(state, { payload }) {
      state[payload] = Object.assign({}, state[payload], { hasBeenOpen: true });
      state.open = payload;
    },
    setCloseModal(state) {
      state.open = '';
    },
  },
});

export const getOpenModal = createSelector([(state) => state.modal], (modal) => modal[modal.open] || {});

const { actions, reducer } = modalSlice;

export const { setLabel, setOpenModal, setCloseModal } = actions;

export default reducer;
