/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import _get from 'lodash/get';

import { Text, Image, Title } from 'ui-redesign/elements';
import { ContentWrapper, SliderSectionStyled, SliderItem, ItemInner, SliderWrapper } from './styled';

import { ContentSection } from 'ui-redesign/components';

const TestimonialsListSection = ({ contentBlock, initialSlide, isDesktop }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide,
  };

  const testemonials = _get(contentBlock, 'fields.percentages');
  return (
    <Slider {...settings}>
      {testemonials.map((testimonialList) => {
        const mediaBackground = _get(testimonialList, 'fields.mediaBackground[0].fields.file.url');
        return (
          <SliderItem key={testimonialList.sys.id}>
            <ItemInner>
              <Text content={testimonialList.fields.body} isHTML />
              <Image picture={mediaBackground} nativeWidth={isDesktop ? 618 : 565} />
              <Text content={testimonialList.fields.body2} />
            </ItemInner>
          </SliderItem>
        );
      })}
    </Slider>
  );
};

TestimonialsListSection.propTypes = {
  contentBlock: PropTypes.object,
  initialSlide: PropTypes.number,
  isDesktop: PropTypes.bool,
};

TestimonialsListSection.defaultProps = {
  initialSlide: 0,
};

const SliderSection = ({ contentBlock, title, body, initialSlide, isDesktop }) => {
  return (
    <SliderSectionStyled>
      <ContentSection>
        <ContentWrapper>
          <Text content={body} />
          <Title content={title} modifiers='secondarySmall' as='h3' />
        </ContentWrapper>
        <SliderWrapper>
          <TestimonialsListSection contentBlock={contentBlock} initialSlide={initialSlide} isDesktop={isDesktop} />
        </SliderWrapper>
      </ContentSection>
    </SliderSectionStyled>
  );
};

SliderSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  contentBlock: PropTypes.object,
  initialSlide: PropTypes.number,
  isDesktop: PropTypes.bool,
};

export default SliderSection;
