import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import paths from 'constants/paths';

/**
 *
 * * useHideGorgiasChat - Custom hook to remove Gorgias Chat
 * Handles cases:
 * Hiding gorgias for the entire lifecycle of a component (hideCondition is always true)
 * Hiding gorgias when the passed 'hideCondition' is true, showing when false
 * Uses MutationObserver to detect async gorgias additions to the DOM
 * ADD to SHOULD_HIDE_PATHS for any path that should never have gorgias shown on it.
 *
 * */

const SHOULD_HIDE_PATHS = [paths.FILL_YOUR_FARMSTAND, paths.FILL_YOUR_FARMSTAND_BUNDLES, paths.OPEN_SURVEY];

export default function useHideGorgiasChat(hideCondition = true) {
  const [wasGorgiasAdded, setWasGorgiasAdded] = useState(false);
  const location = useLocation();

  // set observer
  useEffect(() => {
    // early return for older browsers - assume gorgias was added
    if (!window.MutationObserver) setWasGorgiasAdded(true);

    const observer = new MutationObserver((records) => {
      const wasGorgiasAdded = !!records.find((e) => !![...e.addedNodes].find((n) => n.id === 'gorgias-chat-container'));
      setWasGorgiasAdded(wasGorgiasAdded);
    });
    observer.observe(document.body, { childList: true });
    return () => {
      if (observer) observer.disconnect();
      showGorgias();
    };
  }, []);

  // handle closing gorgias if gorgias is found in DOM or if added to DOM,
  // only close gorgias if hideCondition is true (default)
  useEffect(() => {
    const isGorgiasOpen = document.querySelector('#gorgias-chat-container');
    if ((isGorgiasOpen || wasGorgiasAdded) && !!hideCondition) closeGorgias();
    else if (!hideCondition && !SHOULD_HIDE_PATHS.includes(location.pathname)) showGorgias();
  }, [hideCondition, wasGorgiasAdded, location.pathname]);

  const closeGorgias = () => {
    const gorgias = document.querySelector('#gorgias-chat-container');
    if (gorgias) gorgias.style.display = 'none';
  };

  const showGorgias = () => {
    const gorgias = document.querySelector('#gorgias-chat-container');
    if (gorgias) gorgias.style.display = 'block';
  };

  return;
}
