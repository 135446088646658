import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme-redesign/Media';

const modifiers = {
  white: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.white};
      }
    `,
  }),
};

export const FeaturedSpecsSection = styled.div.attrs({
  className: 'FeaturedSpecs',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      max-width: 33.5rem;
      margin: auto;

      .ContentWrapper {
        .Title {
          font-size: 2.25rem;
          text-align: left;
          margin-bottom: 2rem;
        }

        > .Text {
          text-align: left;
          margin-bottom: 2rem;
          text-align: left;
          margin-bottom: 2rem;
        }
        > .Text {
          text-align: left;
          margin-bottom: 2rem;
        }
      }

      .AccordionWrapper {
        .Button {
          margin-top: 3rem;
          width: 100%;
          max-width: 24rem;
        }
      }
    }
  }
  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 29rem;
            margin-bottom: 2rem;
          }

          .Text {
            margin-bottom: 3rem;
          }
        }

        .AccordionWrapper {
          .Button {
            max-width: 17rem;
          }
        }
      }
    }
  }
  @media ${breakPoints.large_break} {
    .ContentSection {
      padding-top: 0;

      .SectionInner {
        padding-top: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 62rem;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 3.5rem;
            max-width: 30rem;
          }

          .Text {
            max-width: 22rem;
          }
        }

        .AccordionWrapper {
          width: calc(50% - 1rem);
        }
      }
    }
  }

  ${applyStyleModifiers(modifiers)}
`;

export const AccordionWrapper = styled.div.attrs({
  className: 'AccordionWrapper',
})`
  .AccordionSection {
    padding-bottom: 1rem;
    margin-top: 1.5rem;
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};

    .AccordionTitleSection {
      display: flex;
      align-items: center;
      position: relative;

      span {
        position: absolute;
        top: 0.7rem;
        left: 0.5rem;
        transform: translate(-50%, -50%);
      }

      .AccordionTitle {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-weight: 300;
        margin-left: 2rem;
      }
    }

    .Accordion {
      margin-top: 0.5em;
      transition: all 0.5s;
    }

    .Text {
      text-align: left;
      padding-left: 2rem;
      padding-bottom: 0.5rem;
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;
