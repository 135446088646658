import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getOpenModal, setCloseModal } from 'redux/modal';
import { Modal } from '../index';
import LoginForm from 'ui-redesign/components/loginForm/LoginForm';
import ResetPasswordModal from 'ui-redesign/components/modal/reset-password/ResetPassword';

import { LoginModalStyled } from './LoginModal.styled';

const LoginModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openModal = useSelector(getOpenModal);

  const closeModal = () => {
    dispatch(setCloseModal());
    history?.location?.state?.passwordResetRetry && history.push(history.location.pathname); //clear location state
  };

  return (
    <LoginModalStyled>
      <Modal isOpen={openModal.id === 'login'} onCloseAction={closeModal} dataNw='login-modal'>
        <LoginForm onSubmit={closeModal} isOnModal />
      </Modal>
      <ResetPasswordModal isOpen={openModal.id === 'passwordReset'} onCloseAction={closeModal} />
    </LoginModalStyled>
  );
};

export default LoginModal;
