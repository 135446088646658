import styled from 'styled-components/macro';
import breakPoints from 'theme-redesign/Media';

export const FormWrapper = styled.div.attrs({
  className: 'FormWrapper',
})`
  margin-top: 3rem;

  .Title {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .Button {
    width: 100%;
    max-width: 18rem;
    height: 2.25rem;
    margin: auto;
    margin-top: 2.5rem;
  }

  @media ${breakPoints.large_break} {
    margin-top: 6.25rem;
    margin-bottom: 3rem;
    .Button {
      max-width: unset;
    }
  }
`;

export const FormGroup = styled.div.attrs({
  className: 'FormGroup',
})`
  .InputStyled {
    margin: 1.5rem auto;

    div {
      max-width: 25rem;
    }

    &:nth-child(2) {
      margin-bottom: 2.75rem;

      @media ${breakPoints.medium_break} {
        margin-bottom: 2.5rem;
      }
    }

    @media ${breakPoints.medium_break} {
      margin-bottom: 1.5rem;
    }
  }

  .SelectStyled,
  .TextArea {
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }

  .SelectStyled {
    margin-bottom: 3.25rem;
  }

  .TextArea + .Text {
    text-align: left;
    max-width: 25rem;
    margin: 0 auto 3.25rem;
    padding-left: 14px; // match input
  }
`;

export const AddPhotoWrapper = styled.div.attrs({
  className: 'AddPhotoWrapper',
})`
  text-align: center;
  .Text {
    margin-bottom: 0.5rem;
  }

  .Text + .Text {
    font-size: 0.75rem;
  }
  @media ${breakPoints.large_break} {
    text-align: left;
  }
`;
