import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { SquaredButton } from 'ui-redesign/elements';

export const modifiers = {
  actived: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};

      span {
        color: white;
      }
    `,
  }),
};

export const FarmstandSizeStyled = styled.div.attrs({
  className: 'FarmstandSizeStyled',
})``;

export const SizeButtonGroup = styled.div.attrs({
  className: 'SizeButtonGroup',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0 0.5rem;
`;

export const SelectSizeButton = styled(SquaredButton).attrs({
  className: 'SelectSizeButton',
})`
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.25rem;
  margin-right: 0.5rem;

  span {
    font-size: 0.875rem;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const LabelWrapper = styled.div.attrs({
  className: 'LabelWrapper',
})`
  .Text {
    line-height: normal;
  }
`;
