import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { Link } from 'react-router-dom';
import { Link as NewLink } from 'ui-redesign/elements';
import ImageStyled, { modifiers } from './Image.styled';
import { useBreakpoint, useIsInViewport } from 'utils/hooks';

import placeholder from './assets/placeholder.png';

// eslint-disable-next-line react/prop-types
const InnerImage = ({ modifiers, className, picture, alt, src, srcBlur, onClick = null }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInViewport, setTargetRef] = useIsInViewport(0.001);
  const srcSet = picture && (isInViewport || isLoaded) ? src : picture && srcBlur ? srcBlur : placeholder;

  useEffect(() => {
    let isUnmounted = false;

    if (isInViewport && !isLoaded && !isUnmounted) {
      setIsLoaded(true);
    }
    return () => {
      return (isUnmounted = true);
    };
    // eslint-disable-next-line
  }, [isInViewport]);

  return (
    <ImageStyled modifiers={modifiers} className={className} onClick={onClick}>
      <picture ref={setTargetRef}>
        <source srcSet={srcSet} alt={alt} type='image/webp' />
        <img src={picture} alt={alt} />
      </picture>
    </ImageStyled>
  );
};

const Image = ({ picture, modifiers, alt, className, imageRouteLink, absoluteLink, nativeWidth, hasQueryParams, onClick = null }) => {
  const screenSize = useBreakpoint();
  const blurWidth = nativeWidth ? Math.floor(nativeWidth * 0.04) + 1 : 45;
  const srcBlur = ['LG', 'XL'].includes(screenSize) && hasQueryParams ? `${picture}?fm=webp&w=${blurWidth}` : null;
  // add contentful format param
  let src = hasQueryParams ? `${picture}?fm=webp` : picture;

  if (nativeWidth && hasQueryParams) {
    src += `&w=${nativeWidth}`;
  }

  return imageRouteLink ? (
    <Link to={imageRouteLink}>
      <InnerImage modifiers={modifiers} className={className} picture={picture} alt={alt} src={src} srcBlur={srcBlur} />
    </Link>
  ) : absoluteLink ? (
    <NewLink href={absoluteLink} target='_blank'>
      <InnerImage modifiers={modifiers} className={className} picture={picture} alt={alt} src={src} srcBlur={srcBlur} />
    </NewLink>
  ) : (
    <InnerImage onClick={onClick} modifiers={modifiers} className={className} picture={picture} alt={alt} src={src} srcBlur={srcBlur} />
  );
};

Image.propTypes = {
  modifiers: styleModifierPropTypes(modifiers),
  picture: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  imageRouteLink: PropTypes.string,
  absoluteLink: PropTypes.string,
  nativeWidth: PropTypes.number,
  hasQueryParams: PropTypes.bool,
  onClick: PropTypes.func,
};

Image.defaultProps = {
  hasQueryParams: true,
};

export default Image;
