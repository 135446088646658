import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { PillLabel } from 'ui-redesign/components';
import { SelectBundleModal } from 'ui-redesign/components/modal';
import { Title, Text, Image, SquaredButton, Icon } from 'ui-redesign/elements';

import {
  BundleCardStyled,
  BundleCardWrapper,
  BundleImage,
  BundleHeader,
  BundleInfoWrapper,
  BundleName,
  BundleLabel,
  BundleDescription,
  IconFeaturesList,
  IconFeature,
  SeedlingsList,
  List,
  Seedling,
  ButtonGroup,
} from './BundleCard.styled';

import paths from 'constants/paths';
import { FYFBundleContext, setSelectedBundle } from 'pages/fill-your-farmstand-bundles/utils/fyfBundlesContext';
import { useBreakpoint, useBundleSeedlings } from 'utils/hooks';
import { getSeedlingsListFromCustomBundle } from 'utils/bundles-utils';
import { getNumSeedlingsInCart, replaceSeedlings } from 'redux/cartCheckout/cartCheckout';

import { ReactComponent as Farmstand } from 'ui-redesign/elements/icon/assets/farmstand-icon.svg';
import { ReactComponent as Sun } from 'ui-redesign/elements/icon/assets/sun-icon.svg';
import { ReactComponent as Home } from 'ui-redesign/elements/icon/assets/home-icon.svg';
import { ReactComponent as LoadingIcon } from 'assets/images/loading.svg';

const BundleCard = (props) => {
  const {
    id: bundleId,
    labelBundle,
    bundleTitle,
    bundlePrice,
    bundleDescription,
    bundleImage,
    qtyBundle,
    pillStyle,
    pillContent,
    fillsFarmstand,
    growsOutdoor,
    growsIndoor,
    isYourOwn,
    isReview,
    modifiers = [],
    seedlingsCatalog = {},
    chooseModalData = {},
    history,
  } = props;

  const isDesktop = ['XL', 'LG'].includes(useBreakpoint());
  const dispatch = useDispatch();
  const dispatchContext = useContext(FYFBundleContext)?.dispatch;
  const seedlingsInCartQty = useSelector(getNumSeedlingsInCart) || 0;

  const [showChooseModal, setShowChooseModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isReview || false);
  const [seedlings, setSeedlings] = useState([]);
  const bundlesSeedlingsList = useBundleSeedlings(bundleId, isExpanded);
  const ctaLabel = isYourOwn ? 'GET STARTED' : isExpanded ? 'CLOSE' : 'VIEW PLANTS';
  const ctaModifier = isYourOwn ? 'tertiary' : isExpanded ? 'primaryNoHover' : 'whiteActive';

  useEffect(() => {
    if (bundlesSeedlingsList.length && !seedlings.length)
      setSeedlings(getSeedlingsListFromCustomBundle(bundlesSeedlingsList, null, seedlingsCatalog));
  }, [bundlesSeedlingsList, seedlings, seedlingsCatalog]);
  const toggleChooseModal = () => setShowChooseModal(!showChooseModal);

  const onShowMore = () => {
    if (!isYourOwn) setIsExpanded(!isExpanded);
    else history.push(paths.FILL_YOUR_FARMSTAND);
  };

  const onSelectBundle = () => {
    if (seedlingsInCartQty > 0) toggleChooseModal();
    else if (!isReview && dispatchContext) dispatchContext(setSelectedBundle(props));
  };

  const onConfirmSelection = (shouldRemoveSeeds = false) => {
    toggleChooseModal();
    if (!isReview && dispatch && dispatchContext) {
      if (shouldRemoveSeeds) dispatch(replaceSeedlings({ callback: () => dispatchContext(setSelectedBundle(props)) }));
      else dispatchContext(setSelectedBundle(props));
    }
  };

  const renderSeedling = (item, index) => {
    const { qty, name } = item;
    return (
      <Seedling key={index}>
        <Text modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={`${qty} ${name}`} />
      </Seedling>
    );
  };

  return (
    <BundleCardStyled modifiers={[...modifiers, isReview && 'reviewSeedlings']}>
      <BundleCardWrapper>
        <PillLabel modifiers={pillStyle} content={pillContent} />
        <BundleImage>
          <Image picture={bundleImage} nativeWidth={isDesktop ? 800 : 400} />
        </BundleImage>
        <BundleInfoWrapper>
          <BundleHeader>
            <BundleName>
              <BundleLabel>
                <Text as='span' modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={labelBundle} />
                <Text as='span' modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content={`${qtyBundle} PLANTS`} />
              </BundleLabel>
              <Title as='h3' modifiers={['tertiarySmall', 'brandColor']} content={bundleTitle} />
            </BundleName>
            <Text as='span' modifiers={['brandFont', 'noLineHeight', 'brandColor']} content={bundlePrice} />
          </BundleHeader>
          <BundleDescription>
            <Text modifiers={['brandFont', 'noLineHeight']} content={bundleDescription} />
          </BundleDescription>
          <IconFeaturesList>
            {fillsFarmstand && (
              <IconFeature>
                <Icon>
                  <Farmstand />
                </Icon>
                <Text modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content='FILLS YOUR FARMSTAND' />
              </IconFeature>
            )}
            {growsOutdoor && (
              <IconFeature>
                <Icon>
                  <Sun />
                </Icon>
                <Text modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content='GROWS WELL OUTDOORS' />
              </IconFeature>
            )}
            {growsIndoor && (
              <IconFeature>
                <Icon>
                  <Home />
                </Icon>
                <Text modifiers={['brandFont', 'noLineHeight', 'lightGrayColor']} content='GROWS WELL INDOORS' />
              </IconFeature>
            )}
          </IconFeaturesList>
          {isExpanded && (
            <SeedlingsList>
              <Title as='h3' modifiers={['tertiaryXSmall', 'brandColor']} content={`Includes ${qtyBundle} Seedlings`} />
              {seedlings?.length > 0 ? (
                <List>{seedlings.map(renderSeedling)}</List>
              ) : (
                <div className='LoadingWrapper'>
                  <Icon>
                    <LoadingIcon />
                  </Icon>
                </div>
              )}
            </SeedlingsList>
          )}
          {!isReview && (
            <ButtonGroup>
              <SquaredButton label={ctaLabel} modifiers={ctaModifier} onClick={onShowMore} />
              {!isYourOwn && <SquaredButton modifiers='tertiary' label='SELECT' onClick={onSelectBundle} />}
            </ButtonGroup>
          )}
        </BundleInfoWrapper>
      </BundleCardWrapper>
      {!!chooseModalData?.body && (
        <SelectBundleModal
          show={showChooseModal}
          title={chooseModalData?.title || ''}
          body={chooseModalData?.body || ''}
          onReplace={() => onConfirmSelection(true)}
          onAdd={() => onConfirmSelection()}
          onCloseAction={toggleChooseModal}
        />
      )}
    </BundleCardStyled>
  );
};

BundleCard.propTypes = {
  id: PropTypes.string,
  shopifyVariantId: PropTypes.string,
  labelBundle: PropTypes.string,
  qtyBundle: PropTypes.number,
  bundleTitle: PropTypes.string,
  bundlePrice: PropTypes.string,
  bundleDescription: PropTypes.string,
  bundleImage: PropTypes.string,
  pillStyle: PropTypes.string,
  pillContent: PropTypes.string,
  fillsFarmstand: PropTypes.bool,
  growsOutdoor: PropTypes.bool,
  growsIndoor: PropTypes.bool,
  isFeatured: PropTypes.bool,
  isYourOwn: PropTypes.bool,
  isReview: PropTypes.bool,
  modifiers: PropTypes.array,
  seedlingsCatalog: PropTypes.object,
  chooseModalData: PropTypes.object,
  history: PropTypes.object,
};

export default BundleCard;
