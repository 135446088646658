import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { Text, SquaredButton, Input, Select, TextArea } from 'ui-redesign/elements';
import { ImageUploads } from 'ui-redesign/components';
import { FormWrapper, FormGroup, AddPhotoWrapper } from './ContactUsForm.styled';

import contactFormSubjects from 'constants/contactSubjects';
import { phoneNumberRegex, toPhoneNumber } from 'utils/pattern-utils';
import { getLoginState } from 'redux/user';
import logError from 'utils/errorHandler';
import { dbFormatPhone } from 'utils/form-utils';
import { axiosPost } from 'utils/api-utils';
import { authDomain } from 'utils/envConfig';
import { useContent } from 'utils/hooks';
import { CONTACT_US } from 'constants/contentful';

const MAX_BYTE_SIZE = 36000000; // 36 MB

const ContactUsForm = () => {
  const messages = useSelector((state) => state.contentful.messages);
  const history = useHistory();
  const isLoggedIn = useSelector(getLoginState);
  const user = useSelector((state) => state.user);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const content = useContent(CONTACT_US);

  useEffect(() => {
    let timeout;
    if (isSubmitted) {
      timeout = setTimeout(() => setIsSubmitted(false), 4000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isSubmitted]);

  if (!content || !messages) return null;

  const fields = content[0]?.fields;

  // check url for default selected subject
  const queryParams = history.location.search ? queryString.parse(history.location.search) : null;
  const subjectValue = queryParams ? queryParams.subject : '';

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { image1, image2, image3, phone, ...rest } = values;
    setSubmitting(true);
    const data = new FormData();
    let command = { ...rest };
    if (!!phone) {
      command = { phone: dbFormatPhone(phone), ...rest };
    }
    data.append('command', JSON.stringify(command));

    !!image1?.name && data.append('file', image1);
    !!image2?.name && data.append('file', image2);
    !!image3?.name && data.append('file', image3);

    try {
      if (isLoggedIn) {
        await axiosPost('/app/lgcom/contactFormMulti', data, {}, user.authToken);
      } else {
        await axiosPost('/app/public/contactFormPublicMulti', data, {
          headers: { authDomain },
        });
      }

      setIsSubmitted(true);
      resetForm();

      window.dataLayer.push({
        event: 'formSubmitted',
        formId: 'contact',
      });
    } catch (error) {
      window.dataLayer.push({
        event: 'formSubmitFailure',
        formId: 'contact',
      });

      logError(error);
    }
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik
          enableReinitialize
          initialValues={{
            ...(!isLoggedIn && { name: '', phone: '', email: '' }),
            subject: subjectValue,
            body: '',
            image1: null,
            image2: null,
            image3: null,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            ...(!isLoggedIn && {
              name: Yup.string().required(messages.LGE_required),
              email: Yup.string().email(messages.LGE_email_invalid).required(messages.LGE_required),
              phone: Yup.string().required(messages.LGE_required).matches(phoneNumberRegex, messages?.LG_invalid_phone_number),
            }),
            subject: Yup.string().required(messages.LGE_required),
            body: Yup.string().required(messages.LGE_required),
          })}
        >
          {({ setFieldValue, isSubmitting, values, errors }) => {
            const totalFileSize = (values.image1?.size || 0) + (values.image2?.size || 0) + (values.image3?.size || 0);
            const phoneChange = (event) => {
              setFieldValue('phone', toPhoneNumber(event.target.value));
            };
            return (
              <Form noValidate>
                {!isLoggedIn && (
                  <>
                    <Field
                      required
                      variant='filled'
                      name='name'
                      type='text'
                      label='Full Name'
                      autocomplete='name'
                      helperText='*Required'
                      component={Input}
                    />
                    <Field
                      variant='filled'
                      name='phone'
                      label='Phone (555-555-5555)'
                      component={Input}
                      autoComplete='tel'
                      inputMode='numeric'
                      helperText='*Required'
                      onChange={phoneChange}
                    />
                    <Field
                      required
                      variant='filled'
                      name='email'
                      type='text'
                      label='Email'
                      helperText='*Required'
                      component={Input}
                      autocomplete='email'
                    />
                  </>
                )}
                <Field
                  required
                  name='subject'
                  label='Select Subject'
                  helperText='*Required'
                  component={Select}
                  options={Object.keys(contactFormSubjects).map((key) => ({
                    value: key,
                    label: contactFormSubjects[key],
                  }))}
                />
                <Field required variant='filled' name='body' type='text' label='Message' helperText='*Required' component={TextArea} />
                <AddPhotoWrapper>
                  <Text modifiers={['brandFont']} content='Add Photos' as='label' for='image1' />
                  <Text modifiers='tiny' content={fields.sections?.[0]?.fields?.body2} />
                  <ImageUploads values={values} setFieldValue={setFieldValue}></ImageUploads>
                </AddPhotoWrapper>
                <SquaredButton
                  label={isSubmitting ? '...SENDING' : isSubmitted ? 'THANKS!' : 'SEND'}
                  type='submit'
                  disabled={totalFileSize > MAX_BYTE_SIZE}
                  modifiers={[totalFileSize > MAX_BYTE_SIZE && 'disabled']}
                />
              </Form>
            );
          }}
        </Formik>
      </FormGroup>
    </FormWrapper>
  );
};

ContactUsForm.propTypes = {};
export default ContactUsForm;
