import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  inverted: ({ theme }) => ({
    styles: `
      .Title {
        color: ${theme.farmProject.gray_3};
      }
      .Text {
        color: ${theme.farmProject.gray_3};
      }
    `,
  }),
  darker: () => ({
    styles: `
      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background-color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        opacity: 0.25;
      }
    `,
  }),
};

export const HeroSectionStyled = styled.div.attrs({
  className: 'HeroSection',
})`
  width: 100%;
  height: calc(100vh - 3.9rem);
  background-color: #f6f6f6;
  padding: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  ${applyStyleModifiers(modifiers)};
`;

export const HeroInner = styled.div.attrs({
  className: 'HeroInner',
})`
  width: 100%;
  height: auto;

  .Title {
    font-size: 2.8125rem;
    padding-right: 50%;
    margin-bottom: 2.375rem;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
  }

  .Text {
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
  }

  .Button {
    position: relative;
    z-index: 10;
  }

  .Text + .Text {
    margin-bottom: 0;
  }

  > .Image,
  > a .Image {
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    picture {
      width: 100%;
      height: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }
  }
`;
