import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import formatDate from 'utils/date-utils';
import { lgUrlPrefix } from '../utils/envConfig';
import paths from 'constants/paths';

class Head extends Component {
  render() {
    //images from contentful get urls that begin with //, which fb and twitter link sharing don't like
    let ogImage = this.props.ogImage;
    if (ogImage.startsWith('//')) {
      ogImage = 'https:' + ogImage;
    }
    let twitterImage = this.props.twitterImage;
    if (twitterImage.startsWith('//')) {
      twitterImage = 'https:' + twitterImage;
    }

    return (
      <Helmet
        title={this.props.title}
        meta={[
          { name: 'og:title', property: 'og:title', content: this.props.title },
          { name: 'twitter:title', property: 'twitter:title', content: this.props.title },
          { name: 'description', property: 'description', content: this.props.description },
          { name: 'og:description', property: 'og:description', content: this.props.description },
          { name: 'twitter:description', property: 'twitter:description', content: this.props.description },
          { name: 'og:image', property: 'og:image', content: ogImage },
          { name: 'og:url', property: 'og:url', content: `${lgUrlPrefix}${this.props.canonicalRoute}` },
          { name: 'twitter:image', property: 'twitter:image', content: twitterImage },
          { name: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' }, //format of link preview to display
          { name: 'author', property: 'author', content: this.props.author },
          {
            name: 'date',
            property: 'date',
            content: this.props.date ? formatDate(this.props.date) : '',
          },
        ]}
        link={[{ rel: 'canonical', href: `${lgUrlPrefix}${this.props.canonicalRoute}` }]}
      >
        {this.props.children}
      </Helmet>
    );
  }
}

Head.defaultProps = {
  title: 'The future of food is grow-it-yourself.',
  description: "We're on a mission to help everyone grow 20% of their own food.",
  ogImage: lgUrlPrefix + '/social_meta_share.jpg',
  twitterImage: lgUrlPrefix + '/social_meta_share.jpg',
  author: '', //only for blog posts and recipes
  date: '', //only for blog posts
  canonicalRoute: paths.HOME,
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ogImage: PropTypes.string,
  twitterImage: PropTypes.string,
  author: PropTypes.string,
  date: PropTypes.string,
  canonicalRoute: PropTypes.string,
};

export default Head;

/*
Without prerendering, Helmet on front end not expect to work with fb/twitter fields. See:
https://github.com/nfl/react-helmet/issues/26
https://stackoverflow.com/questions/50193960/react-helmet-facebook-open-graph
https://stackoverflow.com/questions/50968603/sharing-on-social-media-the-url-does-not-render-any-meta-data
Can test it for fb with the sharing debugger: https://developers.facebook.com/tools/debug/sharing

Example of how add other fields (can see helmet-builder.js in the-farm-project repo for reference as well):
<Helmet
title={this.props.title} description={this.props.description}
meta={[
    { name: 'description', content: this.state.dataForMeta.metaDescription },
    {
        property: 'og:image',
        content: this.state.dataForMeta.metaFogImage
            ? this.state.dataForMeta.metaFogImage.fields.file.url
            : '',
    },
    {
        property: 'og:title',
        content: 'test og title',
    },
]}
 />
*/
