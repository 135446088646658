import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const MainContentStyled = styled.main.attrs({
  className: 'MainContent',
})`
  width: 100%;
  height: calc(100% - 3.9rem);
  box-sizing: border-box;
  position: relative;

  @media ${breakPoints.medium_break} {
  }
`;
