import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const HowItIsMadeSection = styled.div.attrs({
  className: 'HowItIsMade',
})`
  .ContentSection {
    overflow: hidden;

    .SectionInner {
      max-width: 33.5rem;
      margin: auto;

      .ContentWrapper {
        .Title {
          font-size: 1.75rem;
          text-align: left;
          margin-bottom: 3rem;
        }

        .Image {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          picture {
            img {
              height: 100%;
              top: 50%;
              left: 50%;
              height: 105%;
              width: auto;
              left: auto;
              right: -11.5rem;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.25rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.5rem;
            max-width: 38rem;
          }

          .Image {
            picture {
              img {
                height: 110%;
                right: -14.5rem;
              }
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        max-width: 62rem;

        .ContentWrapper {
          .Image {
            picture {
              img {
                height: 105%;
                width: auto;
                right: 0;
                left: auto;
                transform: translate(10%, -50%);
              }
            }
          }
        }
      }
    }
  }
`;

export const HowItMadeList = styled.ul.attrs({
  className: 'HowItMadeList',
})`
  margin: 0;
  padding: 0;
  list-style: none;

  @media ${breakPoints.large_break} {
    max-width: 34rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const HowItMadeItem = styled.li.attrs({
  className: 'HowItMadeItem',
})`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.green};
    position: relative;
    padding-left: 1.25rem;

    &:before {
      content: '';
      width: 0.35rem;
      height: 0.35rem;
      border-radius: 100%;
      background-color: transparent;
      border: solid 1px ${({ theme }) => theme.farmProject.green};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  @media ${breakPoints.large_break} {
    margin-bottom: 0;
  }
`;
