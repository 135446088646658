import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  off: ({ theme }) => ({
    styles: `
      .Button {
        background-color: ${theme.farmProject.gray_2};

        span {
          color: ${theme.farmProject.dark_gray};
        }
      }

      .Button + .Button {
        background-color: ${theme.farmProject.green};

        span {
          color: ${theme.farmProject.gray_3};
        }
      }
    `,
  }),

  on: ({ theme }) => ({
    styles: `
      .Button {
        &:hover {
          background-color: ${theme.farmProject.green};
          span {
            color: ${theme.farmProject.gray_3};
          }
        }
      }
      .Button + .Button {
        background-color: ${theme.farmProject.gray_2};

        span {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
};

export const ToggleStyled = styled.div.attrs({
  className: 'ToggleStyled',
})`
  width: 100%;
  max-width: 14rem;
  margin: auto;
`;

export const ToggleInner = styled.div.attrs({
  className: 'ToggleInner',
})``;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  padding: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;

  .Button {
    width: 50%;
    border: none;

    &:hover {
      border: solid 1px ${({ theme }) => theme.farmProject.green};
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
