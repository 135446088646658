import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const GrowSection = styled.div.attrs({
  className: 'GrowSection',
})`
  position: relative;

  .ContentSection {
    .SectionInner {
      max-width: 33.5rem;
      margin: auto;

      .Image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        picture {
          img {
            width: auto;
            height: 115%;
          }
        }
      }
      .ContentWrapper {
        .Title {
          font-size: 2.8125rem;
          margin-bottom: 2rem;
          text-align: left;
        }

        .Text {
          text-align: left;

          &:first-of-type {
            margin-bottom: 1rem;
          }

          &:last-of-type {
            margin-top: 1rem;
          }
        }

        > .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          max-width: 30rem;

          .Title {
            font-size: 3rem;
            max-width: 22rem;
          }

          > .Button {
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 62rem;

        .ContentWrapper {
          width: calc(50% - 1rem);

          .Title {
            font-size: 5rem;
            max-width: 22rem;
            margin-bottom: 0;
          }

          .Text {
            font-size: 1rem;

            &:first-of-type {
              margin-bottom: 2rem;
            }
          }

          .Image {
            picture {
              img {
                width: 115%;
                height: auto;
              }
            }
          }

          .Button {
            margin-top: 2rem;
          }
        }
      }
    }
  }
`;
