export const HALO_LIGHTS = 'Z3Jvdy5kYi5CdXlhYmxlLTEzMzQ3MzUx';
export const HALO_LIGHTS_1 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0Mzc1ODUx';
export const HALO_LIGHTS_2 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0ODI0MzUx';
export const HALO_LIGHTS_3 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0ODI0MzUy';
export const HALO_LIGHTS_5 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0MjU5ODUx';
export const HALO_LIGHTS_6 = 'Z3Jvdy5kYi5CdXlhYmxlLTEzOQ';

/** IMPORTANT: WE SHOULD NOT PUT HALO_LIGHTS_1 IN THE HALO_LIGHTS_SKU ARRAY */
export const HALO_LIGHTS_SKUS = [HALO_LIGHTS, HALO_LIGHTS_2, HALO_LIGHTS_3, HALO_LIGHTS_5, HALO_LIGHTS_6];
export const HALO_LIGHTS_ORDERED_SKUS = [HALO_LIGHTS_2, HALO_LIGHTS_3, HALO_LIGHTS, HALO_LIGHTS_5, HALO_LIGHTS_6];
export const ALL_HALOS = [HALO_LIGHTS_1, ...HALO_LIGHTS_SKUS];

export const glowRingsMapSizeSku = {
  12: HALO_LIGHTS_ORDERED_SKUS[0],
  18: HALO_LIGHTS_ORDERED_SKUS[1],
  24: HALO_LIGHTS_ORDERED_SKUS[2],
  30: HALO_LIGHTS_ORDERED_SKUS[3],
  36: HALO_LIGHTS_ORDERED_SKUS[4],
};

export const PERSONAL_PLANT_BUNDLE_SKU = 'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0yMTU4ODM1MQ';
