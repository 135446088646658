import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { Title, Text } from '../../elements';

import { CenteredSliderStyled } from './CenteredSlider.styled';

const CenteredSliderConfig = {
  className: 'center',
  centerMode: true,
  infinite: true,
  centerPadding: '20px',
  slidesToShow: 4,
  speed: 500,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        speed: 500,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },

    {
      breakpoint: 840,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 620,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CenteredSlider = ({ children, titleSlider, sectionDescription }) => {
  return (
    <CenteredSliderStyled>
      <Title as='h3' modifiers='tertiarySmall' content={titleSlider} />
      <Text modifiers='noLineHeight' content={sectionDescription} />
      <Slider {...CenteredSliderConfig}>{children}</Slider>
    </CenteredSliderStyled>
  );
};

CenteredSlider.propTypes = {
  children: PropTypes.node,
  titleSlider: PropTypes.string,
  sectionDescription: PropTypes.string,
};

export default CenteredSlider;
