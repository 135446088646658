import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  fixed: ({ navHeight }) => ({
    styles: `
      .HeroDetailInner {
        .ProductHeroSliderStyled {
          position: fixed;
          top: ${navHeight || 63}px;
        }
      }
    `,
  }),
  absolute: () => ({
    styles: `
      .HeroDetailInner {
        .ProductHeroSliderStyled {
          position: absolute;
          top: auto;
          bottom: 170px;
        }
      }
    `,
  }),
};

export const HeroWrapper = styled.div.attrs({
  className: 'HeroWrapper',
})`
  * {
    box-sizing: border-box;
  }
`;

export const HeroDetailStyled = styled.div.attrs({
  className: 'HeroDetailStyled',
})`
  @media ${breakPoints.large_break} {
    padding: 4rem 1rem;
    max-width: 64rem;
    margin: auto;
  }
  @media ${breakPoints.xxLarge_break} {
    padding: 4rem 3rem;
    max-width: 69rem;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const HeroDetailInner = styled.div.attrs({
  className: 'HeroDetailInner',
})`
  position: relative;

  .ProductHeroSliderStyled {
    position: relative;
  }

  @media ${breakPoints.large_break} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
  }
`;

export const ProductInformation = styled.div.attrs({
  className: 'ProductInformation',
})`
  padding: 2rem 1rem;
  max-width: 26rem;
  margin: auto;

  > .Button {
    height: 2.5rem;
    width: 100%;
    margin-top: ${({ isGlowRing }) => (isGlowRing ? 1.25 : 0)}rem;

    span {
      font-size: 0.875rem;
    }
  }

  .RateStarsWrapper {
    margin: 0.25rem 0;
    justify-content: flex-start;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.7rem;
  }

  .ProductGiftListStyled {
    margin: 0.8rem 0;
  }

  @media ${breakPoints.large_break} {
    padding-top: 0;
    margin-right: 0;
    padding-right: 0;
    max-width: 23rem;
    flex-shrink: 0;
    width: 40%;
  }
  @media ${breakPoints.xxLarge_break} {
    padding-top: 0;
    margin-right: 0;
    padding-right: 0;
    max-width: 26rem;
    flex-shrink: 0;
    width: 40%;
  }
`;

export const ProductDescription = styled.div.attrs({
  className: 'ProductDescription',
})`
  margin: 1rem 0;

  .Text {
    font-size: 1rem;
    a {
      color: ${({ theme }) => theme.farmProject.green};
    }
  }
`;
