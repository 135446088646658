import styled from 'styled-components/macro';
import breakpoints from 'theme-redesign/Media';

export default styled.div.attrs({ className: 'Search' })`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: initial;
  top: 60px;
  width: 100%;
  z-index: 20;
  margin-bottom: 2rem;
  > .Icon {
    margin: 1rem;
    position: absolute;
    right: 1rem;
    top: 0;
    display: none;
  }
  .ButtonText {
    margin-top: 0.2rem;
    span {
      font-size: 0.75rem;
    }
  }
  .InputStyled {
    min-width: 250px;
    margin: 1rem 0;
    height: 100%;
    input {
      font-size: 0.75rem;
    }
    div {
      max-width: unset;
    }
  }

  @media ${breakpoints.large_break} {
    position: fixed;
    display: flex;
    background: ${({ theme }) => theme.farmProject.gray_2};
    height: ${({ isExpanded }) => (isExpanded ? '115px' : '0px')};
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    transition: height 0.3s, opacity 0.3s;
    margin-bottom: unset;
    > .Icon {
      display: block;
    }
    .InputStyled {
      width: 60%;
      max-width: 500px;
      margin: 2rem 0;
    }
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  top: 80px;
  left: 15px;
  background: ${({ theme }) => theme.farmProject.gray_2};
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.12);
  padding: 2rem 3rem 3rem;
  width: 60%;
  max-width: 500px;
  min-width: 250px;
  max-height: calc(100vh - 300px);
  overflow: auto;
  z-index: 20;

  .Text {
    font-size: 0.7rem;
    margin: 1rem 0;
  }

  .Link .Text {
    margin-left: 1rem;
  }

  @media ${breakpoints.medium_break} {
    margin-top: 1rem;
    left: unset;
  }
`;

export const Spacer = styled.div`
  background: ${({ theme }) => theme.farmProject.gray_2};
  height: ${({ isExpanded }) => (isExpanded ? '115px' : '0px')};
  transition: height 0.5s;
  width: 100%;
  display: none;

  @media ${breakpoints.large_break} {
    display: block;
  }
`;
