import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { TitleStyled, modifiers } from './Title.styled';

const Title = ({ as, children, content, modifiers, size, isHTML, dataNw, className }) => {
  if (isHTML) {
    return <TitleStyled modifiers={modifiers} as={as} size={size} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return (
    <TitleStyled className={className} modifiers={modifiers} as={as} size={size} data-nw={dataNw}>
      {children || content}
    </TitleStyled>
  );
};

Title.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  modifiers: styleModifierPropTypes(modifiers),
  size: PropTypes.string,
  isHTML: PropTypes.bool,
  dataNw: PropTypes.string,
};

Title.defaultProps = {
  as: '',
  children: null,
  content: '',
  isHTML: false,
  dataNw: null,
};

export default Title;
