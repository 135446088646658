import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Text } from 'ui-redesign/elements';

import { IconFeatureStyled, FeatureList, ListItem } from './FarmIconFeatures.styled';

import { ReactComponent as RecycleIcon } from 'ui-redesign/elements/icon/assets/recycle-icon.svg';
import { ReactComponent as WaterIcon } from 'ui-redesign/elements/icon/assets/water-icon.svg';
import { ReactComponent as Farmstands } from 'ui-redesign/elements/icon/assets/farmstands-group-icon.svg';
import { ReactComponent as Home } from 'ui-redesign/elements/icon/assets/home.svg';
import { ReactComponent as Glowrings } from 'ui-redesign/elements/icon/assets/glowrings.svg';
import { ReactComponent as Savings } from 'ui-redesign/elements/icon/assets/glowring-savings.svg';
import { ReactComponent as SeedlingPack } from 'ui-redesign/elements/icon/assets/seedling_NEW.svg';

const iconMap = {
  '2NhdJyWgwxZWriJIbcmOlF': <RecycleIcon />,
  '26KCpRX1b6gssDVvqYLgws': <WaterIcon />,
  '386f71mvxTGlWQmclyBA22': <Farmstands />,
  '5OO8bxs67Y0HpmD8LOofDL': <Home />,
  '3gOrqfKtZ9OOzNEbBB5YRD': <Glowrings />,
  vP40lG2Em20ogOY1lSAix: <Savings />,
  '7fDGN4pB8WNTD4ItCTRHvu': <SeedlingPack />,
};

const IconFeature = ({ data }) => {
  const renderListItem = (itemData) => {
    const id = itemData?.sys?.id;
    const text = itemData?.fields?.text;

    return (
      <ListItem key={id}>
        <Icon>{iconMap[id]}</Icon>
        <Text modifiers={['brandFont', 'noLineHeight']} content={text} />
      </ListItem>
    );
  };

  return (
    <IconFeatureStyled>
      <FeatureList>{data.map(renderListItem)}</FeatureList>
    </IconFeatureStyled>
  );
};

IconFeature.propTypes = {
  data: PropTypes.array,
};

export default IconFeature;
