import React from 'react';
import PropTypes from 'prop-types';

import Wrapper, { InputWrapper, Input } from './Radio.styled.js';

/**
 * * Radio input
 */

const Radio = ({ label, className, isSelected, onChange, dataNw, field }) => {
  return (
    <Wrapper className={className} onClick={onChange}>
      <InputWrapper isSelected={isSelected}>
        <Input data-nw={dataNw} type='radio' {...field} />
      </InputWrapper>
      {label}
    </Wrapper>
  );
};

Radio.defaultProps = {
  label: '',
};

Radio.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  dataNw: PropTypes.string,
};

export default Radio;
