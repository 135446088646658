import { createSelector, createSlice } from '@reduxjs/toolkit';

import { AXIOS } from './middleware/apiMiddleware';

const SEEDLINGS_BASE_PATH = '/app/public/v2/bundleJumpstart/';

/**
 * * BundlesSeedlings - Redux Reducer - fetches and store Custom Bundles seedlings list
 *
 * @param state
 *
 */

const initialState = {};

const bundlesSeedlingsSlice = createSlice({
  name: 'bundlesSeedlings',
  initialState,
  reducers: {
    setBundleSeedlings(state, { payload }) {
      state[payload.path] = payload.seedlings;
    },
  },
});

const { actions, reducer } = bundlesSeedlingsSlice;

export const { setLabel, setBundleSeedlings } = actions;

export default reducer;

export const getSeedlingsList = createSelector(
  [(state) => state.bundlesSeedlings, (_, bundleId, env, zip, email) => ({ bundleId, env, zip, email })],
  (seedlings, { bundleId, env, zip, email }) => {
    const path = `${SEEDLINGS_BASE_PATH}${bundleId}/${env}/${zip}${email ? `/${email}` : ''}`;
    return seedlings[path] || [];
  }
);

/**
 * * fetchCustomBundleSeedlings - Async Action Creator to hit bundleJumpstart BE endpoint
 *
 */
export const fetchCustomBundleSeedlings = (bundleId, environment, zip, email) => {
  const path = `${SEEDLINGS_BASE_PATH}${bundleId}/${environment}/${zip}${email ? `/${email}` : ''}`;

  const onSuccess = (seedlings) => setBundleSeedlings({ seedlings, path });

  return {
    type: AXIOS,
    payload: {
      url: path,
      method: 'GET',
      onSuccess,
    },
  };
};
