import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme-redesign/Media';

import LeftArrow from 'ui-redesign/elements/icon/assets/left.svg';
import RightArrow from 'ui-redesign/elements/icon/assets/right.svg';
import LeftArrowWhite from 'ui-redesign/elements/icon/assets/left-white.svg';
import RightArrowWhite from 'ui-redesign/elements/icon/assets/right-white.svg';

export const modifiers = {
  backgroundGreen: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.green};

      .Text {
        color: ${theme.farmProject.gray_3};
      }

      .Link {
        color: ${theme.farmProject.gray_3};
      }

      .slick-slider {
        .slick-next::after {
          background-image: url(${RightArrowWhite});
        }
      }

      .slick-track {
        .slick-slide {
          .PressSlider {
            .Image {
              picture {
                img {
                  filter: invert(100%) brightness(200%);
                }
              }
            }
          }
        }
      }
      .slick-slider {
        .slick-prev::after {
          background-image: url(${LeftArrowWhite});
        }
      }
    `,
  }),
};

export const PressReviewSliderWrapper = styled.div.attrs({
  className: 'PressReviewSlider',
})`
  background: ${({ theme }) => theme.farmProject.gray_2};
  .Title {
    font-size: 2rem;
    line-height: normal;
    letter-spacing: normal;
  }
  ${applyStyleModifiers(modifiers)};
`;

export const PressSliderWrapper = styled.div.attrs({
  className: 'PressSliderWrapper',
})`
  margin-top: 2rem;
  max-width: 100%;
  margin: auto;

  .slick-slide {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .slick-list {
    height: 100%;
    max-width: 29rem;
    margin: auto;
  }

  .slick-track {
    display: flex;
    .slick-slide {
      .PressSlider {
        .Text {
          text-align: center;
          line-height: normal;
        }
        .Image {
          margin: auto;
          height: 4rem;
          width: 100%;

          img {
            height: 100%;
            object-fit: contain;
            padding: 0.2rem;
          }
        }
      }
    }

    @media ${breakPoints.xLarge_break} {
     .slick-slide {
        width: 25% !important;
     }
    }
  }

  .slick-prev, .slick-next {
      top: 50%;
      background-color: transparent;
      box-shadow: none;
      &::after {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  }

  .slick-prev {
      left: -3rem;
      &::after {
        background-image: url(${LeftArrow});
      }
    }

    .slick-next {
      right: -3rem;

      &::after {
        background-image: url(${RightArrow});
      }
    }
  }

  @media ${breakPoints.large_break} {
    .slick-list {
      max-width: 64rem;
    }
  }

  @media ${breakPoints.xLarge_break} {
    max-width: 70rem;
  }
`;

export const PressSlider = styled.div.attrs({
  className: 'PressSlider',
})`
  max-width: 14rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: 1.5rem 0 1rem;
  box-sizing: border-box;

  .Text {
    margin: 1.5rem 0 0.75rem;
  }

  .Link {
    display: block;
    font-size: 0.75rem;
    text-align: center;
    width: 8rem;
    margin: auto;
    margin-top: 1rem;
  }

  @media ${breakPoints.xLarge_break} {
    padding: 1rem;
  }
`;
