import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Text } from 'ui-redesign/elements';
import PromoContainerStyled from './PromoContainer.styled';
import { RemoveItemButton } from 'ui-redesign/components';

import { removeDiscount } from 'redux/cartCheckout/cartCheckout';

const PromoContainer = ({ discount }) => {
  const dispatch = useDispatch();

  const handleRemoveDiscount = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    dispatch(removeDiscount(discount));
  };

  return (
    <PromoContainerStyled>
      <RemoveItemButton onClick={handleRemoveDiscount}></RemoveItemButton>
      <Text modifiers={['brandFont', 'brandColor']} content={discount}></Text>
    </PromoContainerStyled>
  );
};

PromoContainer.defaultProps = {};
PromoContainer.propTypes = {
  discount: PropTypes.string.isRequired,
};
export default PromoContainer;
