import { setStorage, removeStorage } from 'utils/storageManager';
import storageKeys from 'constants/storageKeys';

import { setLastAddedFarmstandId, setCheckout, clearCart } from 'redux/cartCheckout/cartCheckout';
import { setUserValue, setUserZip, setMyFarm } from 'redux/user';
import { setLettuceModal } from 'redux/lettuceModal';
import { getLoginState } from 'redux/user';
import { setReservation, resetReservation } from 'redux/reservation/reservation';
/**
 * * Redux Middleware for Saving to local storage
 *
 */

const cartMiddleware = ({ getState }) => (next) => (action) => {
  next(action);

  // Set cart in local storage for cart actions
  if (action.type === setLastAddedFarmstandId.toString() || action.type === setCheckout.toString()) {
    const cartCheckout = getState().cartCheckout;
    setStorage(storageKeys.CART, {
      lastAddedFarmstandId: cartCheckout.lastAddedFarmstandId,
      checkoutId: cartCheckout.checkout.id,
      itemCount: cartCheckout.checkout.lineItems.reduce((total, item) => {
        total += item.quantity;
        return total;
      }, 0),
    });
    return;
  }

  // Delete cart from local storage if action is clearCart
  if (action.type === clearCart.toString()) {
    removeStorage(storageKeys.CART);
    removeStorage(storageKeys.OLD_CART);
    return;
  }

  // Set user in local storage for user actions
  if (
    action.type === setUserValue.toString() ||
    action.type === setMyFarm.toString() ||
    (action.type === setUserZip.toString() && !getLoginState(getState()))
  ) {
    const currUser = getState().user;
    // Not saving auth token
    setStorage('lettucegrow-user-0106', {
      email: currUser.email,
      password: currUser.password, // temp guest pass
      firstName: currUser.firstName,
      lastName: currUser.lastName,
      address: currUser.address,
      postStop: currUser.postStop,
      city: currUser.city,
      state: currUser.state,
      zip: currUser.zip,
      phone: currUser.phone,
      isGuest: !!action.payload.label && action.payload.label === 'isGuest' ? action.payload.value : currUser.isGuest,
    });
    return;
  }

  // Set lettuce modal in local storage for lettuce modal actions
  if (action.type === setLettuceModal.toString()) {
    setStorage('lettuceModal', getState().lettuceModal);
    return;
  }

  if (action.type === setReservation.toString() || action.type === resetReservation.toString()) {
    // always set hasExpired to false (reset) so that a fresh page load won't show user error message
    setStorage('lettucegrow-reservation-428', { ...getState().reservation, hasExpired: false });
    return;
  }
};

export default cartMiddleware;
