import React from 'react';
import PropTypes from 'prop-types';
import { Title, Text } from '../../elements';
import { useBreakpoint } from 'utils/hooks';
import {
  ProductOverviewStyled,
  ProductDescription,
  ProductDetails,
  MobileImage,
  DesktopImage,
  ListDetailWrapper,
  ListDetailList,
  Detail,
} from './ProductOverview.styled';

const ProductOverview = ({ name, title, overview, benefitsImage, benefitsImageMobile, benefitsTitle, benefitsList }) => {
  const screenSize = useBreakpoint();
  const isMobile = screenSize === 'SM' || screenSize === 'MD';
  return (
    <ProductOverviewStyled>
      <ProductDescription>
        <Title as='h3' modifiers='tertiarySmall' content={title} />
        <Text modifiers='noLineHeight' content={overview} />
      </ProductDescription>
      <ProductDetails>
        {isMobile ? (
          <MobileImage picture={benefitsImageMobile?.fields?.file?.url} />
        ) : (
          <DesktopImage picture={benefitsImage?.fields?.file?.url} />
        )}
        <ListDetailWrapper>
          <Title as='h4' modifiers={['tertiaryXSmall', 'inverted']} content={benefitsTitle} />
          <ListDetailList>
            {benefitsList?.map((benefit, index) => (
              <Detail key={index}>
                <Text modifiers={['inverted', 'small']} content={benefit} />
              </Detail>
            ))}
          </ListDetailList>
        </ListDetailWrapper>
      </ProductDetails>
    </ProductOverviewStyled>
  );
};

ProductOverview.defaultProps = {
  title: 'BUNDLE',
  name: '',
  benefitsTitle: 'TIPS FROM OUR KITCHEN',
};

ProductOverview.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  overview: PropTypes.string,
  benefitsTitle: PropTypes.string,
  benefitsList: PropTypes.arrayOf(PropTypes.string),
  benefitsImage: PropTypes.object,
  benefitsImageMobile: PropTypes.object,
};

export default ProductOverview;
