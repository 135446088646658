import styled from 'styled-components';

import LeftArrowWhite from 'ui-redesign/elements/icon/assets/left-white.svg';
import RightArrowWhite from 'ui-redesign/elements/icon/assets/right-white.svg';

export const CustomerReviewSliderStyled = styled.div.attrs({
  className: 'CustomerReviewSliderStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.green};

  .slick-slider {
    padding-bottom: 0;

    .slick-list {
      .slick-track {
        .slick-slide {
          height: 32rem;

          > div {
            height: 100%;
          }
        }
      }
    }
  }

  .slick-prev {
    width: 2rem;
    height: 2rem;
    top: auto;
    left: 0.25rem;
    top: 50%;
    background-color: transparent;
    box-shadow: none;

    &::after {
      background-image: url(${LeftArrowWhite});
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .slick-next {
    width: 2rem;
    height: 2rem;
    top: auto;
    right: 0;
    top: 50%;
    background-color: transparent;
    box-shadow: none;

    &::after {
      background-image: url(${RightArrowWhite});
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .ReviewWrapper {
    max-width: unset;
    margin: auto;
  }
`;
