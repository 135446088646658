import { createSlice } from '@reduxjs/toolkit';

import { AXIOS } from 'redux/middleware/apiMiddleware';
import { contentCacheUrl } from 'utils/envConfig';

/**
 * * Contentful - Redux Reducer
 *
 * Contenful App Data
 *
 * @param state
 *
 */

// reducer, action types, action creators all in 1 createSlice
const contentfulSlice = createSlice({
  name: 'contentful',
  initialState: { loading: '' },
  reducers: {
    // set fetched data into store
    setContentful(state, { payload }) {
      state[payload.label] = payload.data.items;
    },
    setContentCacheMap(state, { payload }) {
      state.cacheMap = payload;
    },
    // set fetched messages data into store
    setContentfulMessages(state, { payload }) {
      state.messages = payload.data.items.reduce((acc, curr) => {
        acc[curr.fields.errorCode] = curr.fields.message;
        return acc;
      }, {});
    },
    // set start loading state
    fetchContentfulStart(state, { payload }) {
      state.loading = payload;
    },
    // clear loading state
    fetchContentfulEnd(state, { payload }) {
      state.loading = '';
    },
    // set loading error
    fetchContentfulError(state, { payload }) {
      state.error = payload.error;
    },
    // clear contentful by specific label
    clearContentful(state, { payload }) {
      state[payload.label] = null;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = contentfulSlice;

// Extract and export action creators from slice by name
export const {
  setContentful,
  setContentCacheMap,
  fetchContentfulStart,
  fetchContentfulEnd,
  fetchContentfulError,
  clearContentful,
  setContentfulMessages,
} = actions;

// Export the reducer as the default
export default reducer;

// Async Action Types
export const FETCH_CONTENTFUL = 'contentful/FETCH_ENTRIES';

// Async action creator for fetching contentful data
export const fetchContentful = payload => ({
  type: FETCH_CONTENTFUL,
  payload,
});

// Async action creator for fetching contentful data
export const fetchContentCacheMap = () => ({
  type: AXIOS,
  payload: {
    url: `${contentCacheUrl}/cache.json`,
    method: 'GET',
    onSuccess: setContentCacheMap,
    // TODO: onFailure: retryFetchContentCacheMap,
  },
});

// Async action creator for fetching contentful messages data
export const fetchContentfulMessages = () => ({
  type: FETCH_CONTENTFUL,
  payload: {
    label: 'messages',
    content_type: 'errorMessages',
    customSetContent: setContentfulMessages,
  },
});
