import { setCatalog } from 'redux/catalog';
import { setUserValue } from 'redux/user';

/**
 * * Redux Middleware for Catalog actions
 *
 */

const catalogMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  if (action.type === setCatalog.toString()) {
    // Only do this if we're dispatching "setCatalog"
    // get zip from catalog and user
    const { zip: catalogZip, zipSupported } = action.payload;
    const { zip: userZip } = getState().user;
    const hasZipChanged = userZip !== catalogZip;

    if (hasZipChanged) dispatch(setUserValue({ label: 'zip', value: catalogZip }));
    dispatch(setUserValue({ label: 'isZipSupported', value: zipSupported }));
  }
};

export default catalogMiddleware;
