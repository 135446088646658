/* eslint-disable react/display-name */
import React from 'react';

import { Text, Title } from 'ui-redesign/elements';
import ContactListSection from './ContactList';
import { ContactSectionStyled, ContentWrapper } from './styled';

import { ContentSection, Loading } from 'ui-redesign/components';

import { useContent } from 'utils/hooks';

import { CONTACT_SECTION } from 'constants/contentful';

const ContactSection = () => {
  const content = useContent(CONTACT_SECTION);
  if (!content) return <Loading isFullHeight />;

  const { contentBlock, title, body } = content[0].fields;

  return (
    <ContactSectionStyled key={CONTACT_SECTION}>
      <ContentSection>
        <ContentWrapper>
          <Title content={title} modifiers='secondarySmall' as='h3' />
          <Text content={body} />
        </ContentWrapper>
        <ContactListSection contentBlock={contentBlock} />
      </ContentSection>
    </ContactSectionStyled>
  );
};

export default ContactSection;
