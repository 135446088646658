import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import { useHistory } from 'react-router-dom';

import { ButtonTextStyled, modifiers } from './ButtonText.styled';

const Button = ({ id, type, disabled, onClick, label, modifiers, children, href, as, dataNw }) => {
  const history = useHistory();

  const handleClick = (event) => {
    onClick && onClick(event);

    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

    if (isHardCodedProdLink) {
      const pathPattern = /lettucegrow.com(\/\S+)/;
      const path = href.match(pathPattern);
      path && path[1] && history.push(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && history.push(href);
    }
  };

  return (
    <ButtonTextStyled id={id} type={type} disabled={disabled} onClick={handleClick} modifiers={modifiers} as={as} data-nw={dataNw}>
      <span>{label}</span>
      {children}
    </ButtonTextStyled>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
  modifiers: styleModifierPropTypes(modifiers),
  href: PropTypes.string,
  dataNw: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
};

export default Button;
