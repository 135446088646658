import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import { Title } from '../../elements';

import { CardRecipeSliderStyled } from './CardRecipeSlider.styled';

const RecipeSlider = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const CardRecipeSlider = ({ children, titleSlider }) => {
  return (
    <CardRecipeSliderStyled>
      <Title modifiers='tertiarySmall' content={titleSlider} />
      <Slider {...RecipeSlider}>{children}</Slider>
    </CardRecipeSliderStyled>
  );
};

CardRecipeSlider.defaultProps = {
  titleSlider: 'What’s on the menu?',
};

CardRecipeSlider.propTypes = {
  children: PropTypes.node,
  titleSlider: PropTypes.string,
};

export default CardRecipeSlider;
