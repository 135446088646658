import TagManager from 'react-gtm-module';

import store from 'redux/store';

import { environment } from './envConfig';
import { getStorage } from 'utils/storageManager';
import logError from 'utils/errorHandler';
import { formatProductsForDataLayer } from 'utils/cart-utils';

class MyTagManager {
  constructor() {
    this.lastPath = undefined;
    try {
      if (window.location.host.includes('lettucegrow.com') && environment === 'production') {
        TagManager.initialize({
          gtmId: 'GTM-PKP52R3',
          auth: 'C4r4wZBRgohiYiCjCY2rxg',
          preview: 'env-2',
        });
      } else {
        TagManager.initialize({
          gtmId: 'GTM-PKP52R3',
          auth: 'e7Is3mhveoqnVwOhAOcGBA',
          preview: 'env-5',
        });
      }
    } catch (error) {
      logError(error);
    }
  }

  pushPath(currentPath, params) {
    if (currentPath !== this.lastPath) {
      const { email, isLoggedIn } = store.getState().user;
      //not related to tag manager - makes sure you start at top of page when go to a new page on site.
      window.scroll(0, 0);

      const dataLayerHistory = getStorage('_dataLayerHistory');
      let allPageParams = {};

      if (dataLayerHistory?.timeout > Date.now()) {
        allPageParams = dataLayerHistory?.history?.find?.((data) => data.event === 'Pageview');
      }

      const campaignParams = {
        campaignName: params.utm_campaign || allPageParams?.campaignName,
        campaignMedium: params.utm_medium || allPageParams?.campaignMedium,
        campaignSource: params.utm_source || allPageParams?.campaignSource,
        campaignKeyword: params.utm_term || allPageParams?.campaignKeyword,
        campaignContent: params.utm_content || allPageParams?.campaignContent,
      };

      //For google tag manager - analytics, etc
      window.dataLayer.push({
        event: 'Pageview',
        url: currentPath,
        email,
        isLoggedIn,
        ...(campaignParams.campaignName && { campaignName: campaignParams.campaignName }),
        ...(campaignParams.campaignMedium && { campaignMedium: campaignParams.campaignMedium }),
        ...(campaignParams.campaignSource && { campaignSource: campaignParams.campaignSource }),
        ...(campaignParams.campaignContent && { campaignContent: campaignParams.campaignContent }),
        ...(campaignParams.campaignKeyword && { campaignKeyword: campaignParams.campaignKeyword }),
      });

      this.lastPath = currentPath;
    }
  }
}

export const gtmAddToCart = (cartItem, catalogItem, cart) => {
  window.dataLayer.push({
    event: 'eec.addToCart',
    productCategory: catalogItem.category,
    productName: cartItem.title,
    price: cartItem.variant.priceV2.amount,
    sku: catalogItem.sku,
    itemsInCart: formatProductsForDataLayer(cart.lineItems),
    total: cart.totalPriceV2.amount,
    url: window.location.pathname,
    ecommerce: {
      add: {
        products: [
          {
            name: cartItem.title,
            id: catalogItem.sku,
            price: cartItem.variant.priceV2.amount,
            category: catalogItem.category,
            quantity: cartItem.quantity,
          },
        ],
      },
    },
  });
};

export const gtmRemoveFromCart = (itemId) => {
  window.dataLayer.push({
    event: 'eec.removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: 'Product',
            id: itemId,
          },
        ],
      },
    },
  });
};

// maintain gid cookie data by adding query param to link
// credit to https://www.analyticsmania.com/post/google-analytics-cross-domain-tracking-with-google-tag-manager/
// see above post for GTM updates needed when tracking cross-domain
export const trackUrl = (urlString) => {
  const ga = window[window['GoogleAnalyticsObject']];
  let decorated = urlString;
  if (ga && !!ga.getAll && window.gaplugins?.Linker) {
    const tracker = ga.getAll()[0]; // Uses the first tracker created on the page
    decorated = new window.gaplugins.Linker(tracker).decorate(urlString);
  }
  return decorated;
};

const LGTagManager = new MyTagManager();

export default LGTagManager;
