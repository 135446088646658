import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'ui-redesign/elements';

import { PromoPriceStyled } from './PromoPrice.styled';

const PromoPrice = ({ modifiers, oldPrice, newPrice }) => (
  <PromoPriceStyled modifiers={modifiers}>
    <Text modifiers={['brandFont', 'brandColor', 'bold', 'noLineHeight']} content={newPrice} />
    <Text modifiers={['brandFont', 'noLineHeight', 'lineThrough', 'lightGrayColor']} content={oldPrice} />
  </PromoPriceStyled>
);

PromoPrice.propTypes = {
  oldPrice: PropTypes.string,
  newPrice: PropTypes.string,
  modifiers: PropTypes.any,
};

export default PromoPrice;
