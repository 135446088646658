import styled from 'styled-components';
import breakPoints from '../../../theme-redesign/Media';

import LeftArrow from 'ui-redesign/elements/icon/assets/left.svg';
import RightArrow from 'ui-redesign/elements/icon/assets/right.svg';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const SliderSectionStyled = styled.div.attrs({
  className: 'SliderSection',
})`
  .ContentSection {
    .SectionInner {
      .ContentWrapper {
        .Title {
          margin-bottom: 3rem;
          text-align: left;
          max-width: 21rem;
        }

        > .Text {
          font-size: 1rem;
          text-align: left;
          color: ${({ theme }) => theme.farmProject.green};
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3.4rem;
            max-width: 30rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .ContentWrapper {
            width: calc(50% - 2rem);
          }
        }
      }
    }
  }
`;

export const SliderWrapper = styled.div.attrs({
  className: 'SliderWrapper',
})`
  .slick-slide {
    height: auto;
  }

  .slick-prev {
    top: 40%;
    left: -3.25rem;
    background-color: transparent;
    box-shadow: none;

    &::after {
      background-image: url(${LeftArrow});
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .slick-next {
    top: 40%;
    right: -3rem;
    background-color: transparent;
    box-shadow: none;

    &::after {
      background-image: url(${RightArrow});
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media ${breakPoints.large_break} {
    width: calc(50% - 2rem);
  }
`;

export const SliderItem = styled.div.attrs({
  className: 'SliderItem',
})``;

export const ItemInner = styled.div.attrs({
  className: 'ItemInner',
})`
  padding: 0 0.5rem;

  > .Text {
    text-align: left;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    color: ${({ theme }) => theme.farmProject.green};

    span {
      margin-left: 0.25rem;
      padding-left: 0.25rem;
      border-left: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    &:last-of-type {
      font-family: ${({ theme }) => theme.farmProject.font_family_text};
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }
  }

  .Image {
    picture {
      img {
        width: 100%;
        height: auto;
        max-height: 27.513rem;
        margin-top: 1rem;
        margin-bottom: 1.75rem;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }
`;
