let environment = process.env.REACT_APP_ENVIRONMENT; // Environment - local, dev, staging, production
let gcsUrlPrefix = 'https://dev.lettucegrow.com'; // For backend points
let lgUrlPrefix = 'http://localhost:3000'; // For front end (affirm redirect)
let iosAppUrl = 'https://lettucegrow.page.link/shopSeedlings'; // For deep linking to IOS app/appstore
let contentCacheUrl = 'https://content.dev.lettucegrow.com';
let authDomain = 'lg-shop-dev.firebaseapp.com'; // For firebase authentication
let stripeKey = 'pk_test_3h08GSkuR3mMucA4zBUtd8yH'; // for Stripe payments
let klaviyoKey = 'U3UvnU'; // Klaviyo email integration public API key is linked to allan.araujo@oowlish.com
let shopifyToken = '98b15dd813f7f44534eca211291f92b8';
let shopifyDomain = 'lettucegrow-dev.myshopify.com';
// add shopify env keys for prod
//for if accessing staging db when running app locally
if (process.env.REACT_APP_BACKEND === 'staging') {
  authDomain = 'lg-staging.firebaseapp.com';
}

//When making static build locally, can't set domain to not be local
//This means that requests to gcs server will be to the dev server for dev, staging, and prod static builds.
if (window.location.host.includes('dev.lettucegrow.com') || window.location.host.includes('shopify')) {
  document.domain = 'dev.lettucegrow.com';
  gcsUrlPrefix = 'https://dev.lettucegrow.com';
  lgUrlPrefix = 'https://www.dev.lettucegrow.com';
  iosAppUrl = 'https://devlettucegrow.page.link/shopSeedlings';
  contentCacheUrl = 'https://content.dev.lettucegrow.com';
} else if (window.location.host.includes('staging.lettucegrow.com')) {
  document.domain = 'staging.lettucegrow.com';
  authDomain = 'lg-staging.firebaseapp.com';
  gcsUrlPrefix = 'https://staging.lettucegrow.com';
  lgUrlPrefix = 'https://www.staging.lettucegrow.com';
  iosAppUrl = 'https://staginglettucegrow.page.link/shopSeedlings';
  contentCacheUrl = 'https://content.staging.lettucegrow.com';
} else if (window.location.host.includes('release.lettucegrow.com')) {
  document.domain = 'release.lettucegrow.com';
  authDomain = 'lg-staging.firebaseapp.com';
  gcsUrlPrefix = 'https://staging.lettucegrow.com';
  lgUrlPrefix = 'https://www.release.lettucegrow.com';
  iosAppUrl = 'https://staginglettucegrow.page.link/shopSeedlings';
  contentCacheUrl = 'https://content.staging.lettucegrow.com';
} else if (window.location.host.includes('lettucegrow.com')) {
  authDomain = 'lg-production.firebaseapp.com';
  gcsUrlPrefix = 'https://lettucegrow.com';
  lgUrlPrefix = 'https://lettucegrow.com';
  iosAppUrl = 'https://lettucegrow.page.link/shopSeedlings';
  contentCacheUrl = 'https://content.lettucegrow.com';
  stripeKey = 'pk_live_5sFaj9IW1BIchEBb0wFwi0o2';
  try {
    document.domain = 'lettucegrow.com';
  } catch (error) {
    console.error(error);
  }
}

export {
  environment,
  gcsUrlPrefix,
  authDomain,
  stripeKey,
  lgUrlPrefix,
  iosAppUrl,
  contentCacheUrl,
  klaviyoKey,
  shopifyDomain,
  shopifyToken,
};
