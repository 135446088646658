/* eslint-disable react/display-name */
import React from 'react';
import { useLocation } from 'react-router-dom';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { paths } from 'constants/index';

import { Text, ButtonText } from 'ui-redesign/elements';
import { ContactList, ContactListItem } from './styled';

const ContactListSection = ({ contentBlock }) => {
  const { pathname } = useLocation();
  const numbersList = _get(contentBlock, 'fields.percentages');
  const contactSections = {
    CHAT: '6hz1Y2bjo2gBe6RYlzMmP',
    PHONE: '48LRToMRY2Q5xh29WAHZz3',
    EMAIL: '33w0xgroT08qiecLXCxGBn',
    VIRTUAL_CONSULTATION: '6uLQ2lbcxd03lh3DHv5Mhv',
  };

  const contactSectionsRenderes = {
    [contactSections.CHAT]: (numberList) => (
      <ContactListItem key={numberList.sys.id}>
        <ButtonText as='a' label={numberList.fields.body} modifiers={['underlined', 'brand']} onClick={() => window?.GorgiasChat?.open()} />
        <Text as='span' modifiers={['brandFont', 'small', 'darkGrayColor']} content={numberList.fields.body2} />
      </ContactListItem>
    ),
    [contactSections.PHONE]: (numberList) => (
      <ContactListItem key={numberList.sys.id}>
        <ButtonText
          as='a'
          modifiers={['underlined', 'brand']}
          label={numberList.fields.body}
          href={`tel:${numberList.fields.lowerTitle}`}
        />
        <Text as='span' modifiers={['brandFont', 'small', 'darkGrayColor']} content={numberList.fields.body2} isHTML />
      </ContactListItem>
    ),
    [contactSections.EMAIL]: (numberList) => (
      <ContactListItem key={numberList.sys.id}>
        <ButtonText
          as='a'
          modifiers={['underlined', 'brand']}
          label={numberList.fields.body}
          href={`mailto:${numberList.fields.lowerTitle}`}
        />
        <Text as='span' modifiers={['brandFont', 'small', 'darkGrayColor']} content={numberList.fields.body2} isHTML />
      </ContactListItem>
    ),
    [contactSections.VIRTUAL_CONSULTATION]: (numberList) =>
      pathname === paths.CONTACT_US && (
        <ContactListItem key={numberList.sys.id}>
          <ButtonText as='a' modifiers={['underlined', 'brand']} label={numberList.fields.body} href={paths.VIRTUAL_SHOPPING} />
          <Text as='span' modifiers={['brandFont', 'small', 'darkGrayColor']} content={numberList.fields.body2} isHTML />
        </ContactListItem>
      ),
  };

  return (
    <ContactList>
      {numbersList.map((numberList) => {
        const toRender = contactSectionsRenderes[numberList.sys.id];
        return toRender?.(numberList);
      })}
    </ContactList>
  );
};

ContactListSection.displayName = 'ContactListSection';
ContactListSection.propTypes = { contentBlock: PropTypes.object };

export default ContactListSection;
