import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const NutrientsPackSection = styled.div.attrs({
  className: 'NutrientsPack',
})`
  .ContentSection {
    .SectionInner {
      .ContentWrapper {
        .Title {
          font-size: 1.75rem;
          margin-bottom: 0;
          text-align: left;
        }

        .Text {
          font-family: ${({ theme }) => theme.farmProject.font_family};
          font-size: 1.75rem;
          font-weight: 300;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2rem;
          }

          .Text {
            font-size: 1.5rem;
            margin-top: 0.25rem;
            display: block;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .ContentWrapper {
          width: 35%;

          .Title {
            font-size: 1.875rem;
          }

          .Text {
            font-size: 1.5rem;
            margin-top: 0.25rem;
            display: block;
          }
        }

        .CheckListNutrients {
          width: 63%;
          margin-top: 0;

          .ItemCheck {
            margin-bottom: 2rem;

            .Text {
              font-size: 0.875rem;
              width: 100%;

              span {
                font-size: 0.75rem;
                width: 70%;
              }
            }

            &:nth-child(even) {
              .Text {
                p {
                  span {
                    width: 55%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CheckListNutrients = styled.ul.attrs({
  className: 'CheckListNutrients',
})`
  padding: 0;
  margin: 0;
  margin-top: 3rem;
  list-style: none;
`;

export const ItemCheck = styled.li.attrs({
  className: 'ItemCheck',
})`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .Icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    flex-shrink: 0;

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  .Text {
    p {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      font-size: 0.875rem;
      color: ${({ theme }) => theme.farmProject.green};
      display: block;
      text-align: left;
      line-height: normal;
      width: 14rem;

      span {
        display: block;
        color: ${({ theme }) => theme.farmProject.dark_gray};
        margin-top: 0.25rem;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .Text {
      p {
        font-size: 0.9rem;
        width: 30rem;
        display: flex;
        align-items: baseline;

        span {
          font-size: 0.875rem;
          width: 18rem;
          margin-left: 0.5rem;
        }
      }
    }

    &:nth-child(even) {
      .Text {
        p {
          width: 33rem;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .Text {
      p {
        width: 35rem;

        span {
          font-size: 0.875rem;
          width: 18rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
`;
