import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchContentful } from 'redux/contentful';

/**
 * * useContent - Custom hook to fetch data from contentful, store in redux, then return from redux store
 *
 * @param {Object} fetchThis - Contentful constant with label and either id or content_type
 * @param {string} fetchThis.label - label for redux (home, howItWorks)
 * @param {string} fetchThis.id - contentful id
 * @param {string} shouldFetch - optional boolean flag to trigger fetch
 *
 * @return {object} contentful data from redux
 * */
export default function useContent(fetchThis, shouldFetch = true) {
  // Get redux dispatch function
  const dispatch = useDispatch();
  const appSettings = useSelector((state) => state.appSettings);

  // Get page content from Redux store
  const content = useSelector((state) => state.contentful[fetchThis.label]);

  // Only fetch for content from contentful if we haven't already
  useEffect(() => {
    if (!content && fetchThis.label && shouldFetch) dispatch(fetchContentful(fetchThis));
    // eslint-disable-next-line
  }, [appSettings.disableContentCache, shouldFetch]);

  return content;
}
