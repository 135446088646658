import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Odometer from 'react-odometerjs';

import useIsInViewport from 'utils/hooks/useIsInViewport';
import breakpoints from 'constants/breakpoints';
import colors from 'constants/styledColors';
/**
 * * Stats Tickers Section
 *
 */

// Styled "Section"
const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 1440px;
  margin: 140px auto;

  /* "Section" Tablet/Desktop Styles */
  @media (min-width: ${breakpoints.MOBILE}px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Ticker = styled.div`
  .odometer {
    color: ${(props) => props.color};
    font-size: 18vw;
    font-weight: bold;
    visibility: ${(props) => (props.value > 0 ? 'visible' : 'hidden')};
  }
  p {
    font-size: 6vw;
    font-weight: bold;
  }

  /* "Section" Tablet/Desktop Styles */
  @media (min-width: ${breakpoints.MOBILE}px) {
    .odometer {
      font-size: 3.5rem;
      font-weight: bold;
    }
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
`;

const StatsTicker = ({ numMilkJugs, milkJugsText, numGallonsOfWater, gallonsOfWaterText, numFruitsVeggies, fruitsVeggiesText }) => {
  const [milkJugs, setMilkJugs] = useState(0);
  const [gallonsWater, setGallonsWater] = useState(0);
  const [fruitsVeggies, setFruitsVeggies] = useState(0);
  const [isInViewport, targetRef] = useIsInViewport();

  // Water for component in viewport and set the numbers.
  useEffect(() => {
    if (isInViewport) {
      import('assets/odometer-theme.css').then(() => {
        setMilkJugs(numMilkJugs);
        setGallonsWater(parseInt(numGallonsOfWater));
        setFruitsVeggies(parseInt(numFruitsVeggies));
      });
    }
    /* eslint-disable-next-line */
  }, [isInViewport]);

  return (
    <Section ref={targetRef}>
      <Ticker color={colors.MAIN_GREEN} value={fruitsVeggies}>
        <Odometer value={fruitsVeggies} theme='minimal' format='(,ddd)' />
        <p>{fruitsVeggiesText}</p>
      </Ticker>
      <Ticker color={colors.SECTION_BLUE} value={gallonsWater}>
        <Odometer value={gallonsWater} theme='minimal' format='(,ddd)' />
        <p>{gallonsOfWaterText}</p>
      </Ticker>
      <Ticker color={colors.MAIN_YELLOW} value={milkJugs}>
        <Odometer value={milkJugs} theme='minimal' format='(,ddd)' />
        <p>{milkJugsText}</p>
      </Ticker>
    </Section>
  );
};

StatsTicker.propTypes = {
  numMilkJugs: PropTypes.number,
  numGallonsOfWater: PropTypes.number,
  numFruitsVeggies: PropTypes.number,
  milkJugsText: PropTypes.string,
  gallonsOfWaterText: PropTypes.string,
  fruitsVeggiesText: PropTypes.string,
};
export default StatsTicker;
