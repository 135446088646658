import React from 'react';
import { Field } from 'formik';
import { useDispatch } from 'react-redux';

import { Text, Input, Icon, Image } from 'ui-redesign/elements';
import { AddPhotoBtnGroup, AddPhotoBtn, PhotoWrapper } from './ImageUploads.styled';
import { ReactComponent as CloseIcon } from 'ui-redesign/elements/icon/assets/close.svg';
import { ReactComponent as AddPhotoIcon } from 'ui-redesign/elements/icon/assets/photo-icon.svg';

import { setOpenModal, setLabel } from 'redux/modal';

const MAX_BYTE_SIZE = 36000000; // 36 MB

const ImageUploads = ({ setFieldValue, values }) => {
  const dispatch = useDispatch();
  const totalFileSize = (values.image1?.size || 0) + (values.image2?.size || 0) + (values.image3?.size || 0);
  const handleImageUpload = (id) => async (ev) => {
    const file = ev.currentTarget?.files?.[0];
    try {
      if (file?.type?.includes('image')) {
        const imageCompression = await import('browser-image-compression');
        const compressed = await imageCompression?.default(file, { maxSizeMB: 2 });
        !!compressed && setFieldValue(id, compressed);
      } else {
        !!file && setFieldValue(id, file);
      }
    } catch (error) {
      if (error?.message) dispatch(setLabel({ id: 'error', label: error.message }));
      dispatch(setOpenModal('error'));
    }
  };
  const handleRemoveImage = (id) => () => {
    setFieldValue(id, null);
  };
  return (
    <>
      <AddPhotoBtnGroup>
        {['image1', 'image2', 'image3'].map((id) => {
          if (!values[id]) {
            return (
              <Field
                key={id}
                component={() => (
                  <AddPhotoBtn>
                    <Input name={id} type='file' onChange={handleImageUpload(id)} accept='image/*, video/*' />
                    <Icon modifiers='darkGrayColor'>
                      <AddPhotoIcon />
                    </Icon>
                  </AddPhotoBtn>
                )}
              />
            );
          } else {
            return (
              <PhotoWrapper key={id} hasError={values[id].size > MAX_BYTE_SIZE}>
                {values[id].type.includes('video') ? (
                  <video width='86'>
                    <source src={URL.createObjectURL(values[id])} type={values[id].type} />
                  </video>
                ) : (
                  <Image picture={URL.createObjectURL(values[id])} hasQueryParams={false} />
                )}
                <Icon onClick={handleRemoveImage(id)} modifiers='small'>
                  <CloseIcon />
                </Icon>
              </PhotoWrapper>
            );
          }
        })}
      </AddPhotoBtnGroup>
      {totalFileSize > MAX_BYTE_SIZE && (
        <Text modifiers={['tiny', 'error']} content={`Maximum total file size is ${MAX_BYTE_SIZE / 1000000} MB`} />
      )}
    </>
  );
};

export default ImageUploads;
