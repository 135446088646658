import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme-redesign/Media';

export const modifiers = {
  teeny: () => ({
    styles: `
      &:before {
        content: '';
        width: 1px;
        height: 50%;
        transform: translate(-50%,-11%);
      }
      
      .Text {
        transform: translate(-65%,3rem);
      }
    
      .Image {
        position: absolute;
        left: 50%;
        transform: translate(-20%, -50%);
      }
    `,
  }),
  tiny: () => ({
    styles: `
      &:before {
        content: '';
        width: 1px;
        height: 55%;
        transform: translate(-50%,-18%);
        
      }
      
      .Text {
        transform: translate(-70%, 3rem); 
      }
    
      .Image {
        position: absolute;
        left: 50%;
        transform: translate(-20%, -50%);
      }
    `,
  }),
  small: () => ({
    styles: `
      &:before {
        content: '';
        width: 1px;
        height: 65%;
        transform: translate(-50%,-32%);
      }
      
      .Text {
        transform: translate(-50%, 100%); 
      }
    
      .Image {
        position: absolute;
        left: 50%;
        transform: translate(-20%, -50%);
      }
    `,
  }),
  medium: () => ({
    styles: `
      &:before {
        content: '';
        width: 1px;
        height: 75%;
        transform: translate(-50%,-40%);
      }
      
      .Text {
        right: -0.8rem;
        transform: translate(-50%, 0%); 
      }
    
      .Image {
        position: absolute;
        left: 50%;
        transform: translate(-23%, -50%);
      }
    `,
  }),
  large: () => ({
    styles: `
      &:before {
        content: '';
        width: 1px;
        height: 80%;
        transform: translate(-50%,-44%);
      }
      
      .Text {
        right: -0.6rem;
        transform: translate(-50%, 0%); 
      }
    
      .Image {
        position: absolute;
        left: 50%;
        transform: translate(-28%, -50%);
      }
    `,
  }),
  mostPopular: () => ({
    styles: `
      &:after {
        display: flex;
      }
    `,
  }),

  centerOneFarmstand: () => ({
    styles: `
      margin: auto !important;
    `,
  }),
};

export const FarmStandCardStyled = styled.div.attrs({
  className: 'FarmstandCard',
})`
  max-width: 20rem;
  margin: auto;

  ${applyStyleModifiers(modifiers)};
`;

export const FarmstandCardInner = styled.div.attrs({
  className: 'FarmstandCardInner',
})`
  height: 25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &:after {
    content: 'MOST POPULAR';
    display: none;
    text-align: center;
    line-height: normal;
    font-size: 0.65rem;
    font-family: ${({ theme }) => theme.farmProject.font_family};
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
    border-radius: 100%;
    position: absolute;
    top: 2.5rem;
    left: 2.25rem;
    transform: translate(-50%, -50%);
  }

  ${applyStyleModifiers(modifiers)};
`;

export const FarmstandInformation = styled.div.attrs({
  className: 'FarmstandInformation',
})`
  /* z-index: 30; */
  width: 50%;
  height: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;

export const PlantsNumber = styled.div.attrs({
  className: 'PlantsNumber',
})`
  > .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 4rem;
    line-height: normal;
    margin-bottom: 0;
    display: block;
  }

  .Text + .Text {
    font-size: 0.875rem;
  }
`;

export const AccordionWrapper = styled.div.attrs({
  className: 'AccordionWrapper',
})`
  margin: 1rem 0;

  .AccordionSection {
    .Accordion {
      .lg-accordion__inner {
        p {
          font-family: ${({ theme }) => theme.farmProject.font_family};
          font-size: 0.75rem;
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const PriceInformation = styled.div.attrs({
  className: 'PriceInformation',
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  max-width: 7rem;
  height: 4.7rem;

  > span {
    position: absolute;
    top: 33%;
    left: auto;
    right: 0;
    transform: translate(-50%, -50%);
    width: 1rem;
    height: 0.8rem;
    z-index: 50;
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 1.25rem;
    color: ${({ theme }) => theme.farmProject.green};
    display: block;
    line-height: normal;
    margin-bottom: 0;

    p {
      font-family: ${({ theme }) => theme.farmProject.font_family};
      color: ${({ theme }) => theme.farmProject.green};
      display: block;
      line-height: normal;
      margin-bottom: 0;
      font-size: 0.7rem;
      width: 7.5rem;
      line-height: 1rem;

      strong {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  .Text + .Text {
    font-size: 0.6rem;
    width: 4rem;
    margin-left: 0.25rem;
  }

  .Button {
    width: 7rem;
    min-width: 5.5rem;
    padding: 0.25rem;
    margin-top: 0.5rem;
  }

  .IncreaseInput,
  .Button {
    z-index: 30;
  }
`;

export const FarmstandModel = styled.div.attrs({
  className: 'FarmstandModel',
})`
  &:before {
    content: '';
    width: 1px;
    height: 100%;
    display: block;
    background-color: ${({ theme }) => theme.farmProject.dark_gray};
    position: absolute;
    top: 50%;
    left: calc(100% - 1rem);
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.5;
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 0.75rem;
    color: ${({ theme }) => theme.farmProject.light_gray};
    display: block;
    background-color: ${({ theme }) => theme.farmProject.gray_3};
    position: absolute;
    top: 50%;
    left: auto;
    right: -1.2rem;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .Image {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 55%;
    pointer-events: none;

    picture {
      pointer-events: none;

      img {
        width: 92%;
        transform: translate(-60%, -50%);
        pointer-events: none;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .Image {
      picture {
        img {
          transform: translate(-57%, -50%);
        }
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
