import { getItemByVariantId } from 'redux/catalog';
import { v1 as uuidv1 } from 'uuid';

export const cartAddItems = (items, cart, reduxState) => {
  items.forEach((item) => {
    const checkoutItem = cart.lineItems.find((lineItem) => lineItem.variant.id === item.variantId);
    const catalogItem = getItemByVariantId(reduxState, item.variantId);
    const cartParsed = {
      $value: checkoutItem.variant?.priceV2?.amount,
      AddedItemProductName: checkoutItem.title + checkoutItem.variant.title,
      AddedItemProductID: checkoutItem.variant?.product?.handle,
      AddedItemSKU: catalogItem.sku,
      AddedItemCategories: catalogItem.subcategory ? [catalogItem.category, catalogItem.subcategory] : [catalogItem.category],
      AddedItemImageURL: checkoutItem.variant?.image?.src,
      AddedItemPrice: checkoutItem.variant?.priceV2?.amount,
      AddedItemQuantity: item.quantity,
      ItemNames: [checkoutItem.title, checkoutItem.variant?.title],
      CheckoutURL: cart.webUrl,
      Items: cart.lineItems.map(({ title, variant, quantity }) => {
        const existingCatalogItem = getItemByVariantId(reduxState, item.variantId);
        return {
          ProductID: variant?.product?.handle,
          SKU: existingCatalogItem.sku,
          ProductName: title + variant.title,
          Quantity: quantity,
          ItemPrice: variant.priceV2.amount,
          ImageURL: variant.image.src,
          ProductCategories: existingCatalogItem.subcategory
            ? [existingCatalogItem.category, existingCatalogItem.subcategory]
            : [existingCatalogItem.category],
        };
      }),
    };

    if (window._learnq) {
      window._learnq.push(['track', 'Added to Cart', cartParsed]);
    }
  });
};

export const viewedProduct = (item) => {
  const { priceCents, name, sku, slug, category, subcategory, imageUrl } = item;

  const product = {
    URL: window.location.href,
    ProductName: name,
    ProductID: slug,
    SKU: sku,
    Categories: subcategory ? [category, subcategory] : [category],
    ImageURL: imageUrl,
    Price: priceCents / 100,
  };

  if (window._learnq) {
    window._learnq.push(['track', 'Viewed Product', product]);
    window._learnq.push([
      'trackViewedItem',
      {
        Title: product.ProductName,
        ItemId: product.ProductID,
        Categories: product.Categories,
        ImageUrl: product.ImageURL,
        Url: product.URL,
        Metadata: {
          Price: product.Price,
        },
      },
    ]);
  }
};

export const startedCheckout = ({ webUrl, totalPriceV2, lineItems }, reduxState) => {
  const Items = lineItems.map(({ title, quantity, variant }) => {
    const catalogItem = getItemByVariantId(reduxState, variant.id);
    return {
      ProductID: variant.product.handle,
      SKU: catalogItem.sku,
      ProductName: title + variant.title,
      Quantity: quantity,
      ItemPrice: variant.priceV2.amount,
      ImageURL: variant.image.src,
    };
  });

  const payload = {
    $event_id: uuidv1(),
    $value: totalPriceV2.amount,
    CheckoutURL: webUrl,
    ItemsNames: lineItems.map(({ title }) => title),
    Items: Items,
  };

  if (window._learnq) {
    window._learnq.push(['track', 'Started Checkout', payload]);
  }
};

export const identify = ({ user = {}, customer = {} }) => {
  const track = {
    $email: user.email,
    $first_name: user.firstName,
    $last_name: user.lastName,
    $phone_number: user.phone,

    $address1: user.address || customer.address,
    $city: user.address || customer.city,
    $region: user.address || customer.state,
    $zip: user.address || customer.zip,
  };
  if (window._learnq) {
    window._learnq.push(['identify', track]);
  }
};
