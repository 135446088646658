import paths from 'constants/paths';
import shopCategories from 'constants/shopCategories';

export const formatPrice = (number, fixed = 2, hasDollarSign = true) => {
  let fixedDecimal = `${Number.parseFloat(number).toFixed(fixed)}`;
  if (hasDollarSign) fixedDecimal = '$' + fixedDecimal;

  //https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  // if formatting for view with $ - add a comma for thousands
  return hasDollarSign ? fixedDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : fixedDecimal.toString();
};

// if price is not whole dollar show cents (2 decimal points), otherwise just show whole dollar
export const formatDynamicDecimalPrice = (priceCents, hasDollarSign = true) => {
  return formatPrice((priceCents / 100) * 1, priceCents % 100 !== 0 ? 2 : 0, hasDollarSign);
};

// used for analytics formatting in checkout events
export const formatProductsForDataLayer = (checkoutItems) => {
  return checkoutItems.map((item) => {
    return {
      id: item.variant?.product?.id,
      sku: item.variant?.product?.id,
      shopifyVariantId: item.variant?.id,
      title: item.title,
      price: item.variant?.priceV2?.amount,
      quantity: item.quantity,
    };
  });
};

export const getItemPath = (item) => {
  if (item?.category === shopCategories.SEEDLINGS) {
    return `${paths.SEEDLINGS}/${item?.slug}`;
  } else if (item?.category === shopCategories.BUNDLES) {
    return `${paths.BUNDLES}/${item?.slug}`;
  } else if (item?.category === shopCategories.FARMSTAND) {
    return paths.FARMSTAND;
  }
  return `${paths.SHOP_PRODUCTS}/${item?.slug}`;
};
