import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { SquaredButton } from 'ui-redesign/elements';
import { ShopWrapper } from './Navigation.styled';

import paths from 'constants/paths';

const Shop = () => {
  const goToTop = () => {
    if (window.location.pathname === paths.SHOP_PRODUCTS) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <ShopWrapper>
      <RouterLink to={paths.SHOP_PRODUCTS}>
        <SquaredButton label='SHOP' modifiers='tertiary' onClick={goToTop} dataNw='shop-button' />
      </RouterLink>
    </ShopWrapper>
  );
};

export default Shop;
