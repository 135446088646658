import { useEffect, useState } from 'react';

const SCROLL_THRESHOLD = 50; // Page Y scroll offset

export default () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window && window.scrollY > SCROLL_THRESHOLD) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return isScrolled;
};
