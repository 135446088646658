import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { ThemeProvider, createGlobalStyle, StyleSheetManager } from 'styled-components/macro';

import './css/main.css';
import paths from 'constants/paths';
import theme from 'utils/theme';
import retry from 'utils/retry';
import { TERMS_OF_SERVICE, PRIVACY, WARRANTY } from 'constants/contentful';
import { axiosGet } from 'utils/api-utils';
import { setStorage, getStorage } from 'utils/storageManager';

//redesign
import { farmTheme } from './theme-redesign/Theme';

import { getCustomer } from 'redux/user';

// Components needed for initial load. No code splitting
import Home from 'pages/newHome';
import ErrorBoundary from 'components/ErrorBoundary';
import Head from 'components/Head';
import { LettuceGiveBanner, MessageBanner, Navigation, Footer, Loading, CookieComponent } from 'ui-redesign/components';
import { useGorgiasChat, useKlaviyo, useAffirm, useRouteChange, useQueryParams } from 'utils/hooks';
import LoginModal from 'ui-redesign/components/modal/login-modal/LoginModal';
import { ErrorModal } from 'ui-redesign/components/modal/';

import { environment } from 'utils/envConfig';
import { fetchContentCacheMap } from 'redux/contentful';
import { FARMSTAND_BUNDLE_PDP_2020, NEW_FARMSTAND_BUNDLE_PDP_2021 } from 'constants/contentful';

// Lazy load / code split routes
// use retry function to resolve chunk load errors after deployments
const LettuceModal = lazy(() => retry(() => import('ui-redesign/components/lettuce-modal/LettuceModal')));
const NotFound = lazy(() => retry(() => import('pages/notFound')));
const Login = lazy(() => retry(() => import('pages/loginNew')));
const CreateAccount = lazy(() => retry(() => import('pages/createAccount/CreateAccount')));
const ProductStore = lazy(() => retry(() => import('pages/productStore')));
const SeedlingPackRoute = lazy(() => retry(() => import('components/SeedlingPackRoute')));
const SeedlingsBundle = lazy(() => retry(() => import('pages/seedlings_bundle/SeedlingsBundle')));
const SurveyResults = lazy(() => retry(() => import('pages/survey-results/SurveyResults')));
const ContactUs = lazy(() => retry(() => import('pages/contact-us/ContactUs')));
const MyFarm = lazy(() => retry(() => import('pages/myFarm')));
const FAQContainer = lazy(() => retry(() => import('pages/faqs/FAQContainer')));
const BlogLanding = lazy(() => retry(() => import('pages/blog/BlogLanding')));
const ContentfulPage = lazy(() => retry(() => import('components/deprecated/ContentfulPage')));
const RecipeLanding = lazy(() => retry(() => import('pages/recipes/RecipeLanding')));
const SeedlingLanding = lazy(() => retry(() => import('pages/seedlings-landing/SeedlingLP')));
const SeedlingDetail = lazy(() => retry(() => import('pages/seedlings/SeedlingDetail')));
const PrivateRoute = lazy(() => retry(() => import('components/PrivateRoute')));
const SurveyEmbed = lazy(() => retry(() => import('pages/surveyEmbed')));
const GiftCard = lazy(() => retry(() => import('pages/giftCard')));
const MobileShopLogic = lazy(() => retry(() => import('pages/mobileShopLogic')));
const SupplyDetail = lazy(() => retry(() => import('pages/supply-detail/SupplyDetail')));
const HowItWorksNew = lazy(() => retry(() => import('pages/howItWorksNew/HowItWorks')));
const Cart = lazy(() => retry(() => import('ui-redesign/components/cart/Cart')));
const OurMission = lazy(() => retry(() => import('pages/ourMission/OurMission')));
const FillYourFarmstand = lazy(() => retry(() => import('pages/fill-your-farmstand/FillYourFarmStand')));
const FillYourFarmstandBundles = lazy(() => retry(() => import('pages/fill-your-farmstand-bundles/FillYourFarmstandBundles')));
const FarmstandProduct = lazy(() => retry(() => import('pages/farmstand-product/FarmstandProduct')));
const GlowRings = lazy(() => retry(() => import('pages/glowrings-pdp/GlowRings')));
const VirtualConsultation = lazy(() => retry(() => import('pages/virtual-consultation/VirtualConsultation')));
const SearchResults = lazy(() => retry(() => import('pages/search-results/SearchResults')));
const CustomerReviews = lazy(() => retry(() => import('pages/customer-reviews/CustomerReviews')));
const SeedlingReview = lazy(() => retry(() => import('pages/seedling-review/SeedlingReview')));
const SeedlingReviewConfirmation = lazy(() => retry(() => import('pages/seedling-review-confirmation/SeedlingReviewConfirmation')));
const PromotionPage = lazy(() => retry(() => import('pages/promotion-page/PromotionPage')));
const CheckoutRedirectRoute = lazy(() => retry(() => import('pages/checkoutRedirect/CheckoutRedirect')));

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${theme.font.mainRegular};
    font-weight: normal;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    // no background color on mobile safari touch
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    *:focus {
      outline: none;
    }

    // button reset
    button {
      background: none;
      border: none;
      outline: none;
      padding: 0;
    }

    .MuiPopover-root {
      z-index: 1000000 !important;
    }

    #swell-overlay, #swell-popup {
      display: none !important; // yotpo referral code popup
    }
  }
`;

export default function App() {
  const [sessionId] = useState(uuidv1());
  const location = useLocation();
  const dispatch = useDispatch();
  const customerId = useSelector(getCustomer).id;
  const isFromMobile = useSelector((state) => state.user.isFromMobile);
  const contentCacheMap = useSelector((state) => state.contentful.cacheMap);
  const isLoading = useSelector((state) => state.appSettings.isLoading);
  useGorgiasChat();
  useKlaviyo();
  useRouteChange();
  useAffirm();
  const { referralCode } = useQueryParams(['pc', 'res', 'sref_id']);

  useEffect(() => {
    if (!contentCacheMap) dispatch(fetchContentCacheMap());

    // set custom user id if not already set
    const lgUserId = getStorage('lgClientId');
    if (!lgUserId) {
      setStorage('lgClientId', sessionId);
    }

    if (environment === 'production') {
      window.LogRocket && window.LogRocket.identify(lgUserId || sessionId);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axiosGet('/app/public/hello');
  }, []);

  if (!contentCacheMap) return null;

  return (
    <>
      <StyleSheetManager disableCSSOMInjection /* resolves styling issues seen 6/9 */>
        <ThemeProvider theme={{ ...theme, ...farmTheme }}>
          <Suspense fallback={<Loading isFullHeight noImage />}>
            <ErrorBoundary>
              <Head>
                {referralCode && (
                  <script
                    async
                    type='text/javascript'
                    src='https://cdn-loyalty.yotpo.com/loader/oOhx507myfnTz5h1YD1eHA.js
'
                  ></script>
                )}
              </Head>
              <GlobalStyle />
              <Navigation />
              <CookieComponent />
              <Cart />
              {!location.pathname.includes('ios') && <MessageBanner location={location} />}
              {isLoading && <Loading isFullHeight />}
              <Switch>
                <Route path={paths.HOME} exact component={Home} />
                <Route path={paths.NOT_FOUND} exact component={NotFound} />
                <Route path={paths.SHOP_PRODUCTS} exact component={ProductStore} />
                <SeedlingPackRoute path={`${paths.SEEDLING_PACK}/:count`} />
                <Route path={paths.LOGIN} component={Login} />
                <Route path={paths.CREATE_ACCOUNT} component={CreateAccount} />
                <Route path={paths.FILL_YOUR_FARMSTAND} component={FillYourFarmstand} />
                <Route path={paths.FILL_YOUR_FARMSTAND_BUNDLES} component={FillYourFarmstandBundles} />
                <Route path={paths.SURVEY_RESULTS} component={SurveyResults} />
                <Route path={paths.EXPERIENCE} component={HowItWorksNew} />
                <Route path={paths.SEEDLINGS} exact component={SeedlingLanding} />
                <Route path={paths.CONTACT_US} exact component={ContactUs} />
                <Route path={paths.GIFT_CARD} exact component={GiftCard} />
                <PrivateRoute path={paths.MY_FARM} component={MyFarm} />
                <Route path={[paths.FARMSTAND, paths.FARMSTAND_SIZE]} exact component={FarmstandProduct} />
                <Route path={paths.SEARCH_RESULTS} component={SearchResults} />
                <Route path={`${paths.SEEDLING_REVIEW}/:id`} component={SeedlingReview} />
                <Route path={`${paths.SEEDLING_REVIEW_CONFIRMATION}/:id`} exact component={SeedlingReviewConfirmation} />
                <Route path={`${paths.CUSTOMER_REVIEWS}`} exact component={CustomerReviews} />
                <Route
                  path={paths.PROMOTION_PAGE}
                  exact
                  render={({ history }) => (
                    <PromotionPage path={paths.PROMOTION_PAGE} contentfulPage={FARMSTAND_BUNDLE_PDP_2020} history={history} />
                  )}
                />
                <Route
                  path={paths.PROMOTION_PAGE_2}
                  exact
                  render={({ history }) => (
                    <PromotionPage path={paths.PROMOTION_PAGE_2} contentfulPage={NEW_FARMSTAND_BUNDLE_PDP_2021} history={history} />
                  )}
                />

                {/* ***Route*** - Survey Embed - /survey-results */}
                <Route
                  path={`${paths.OPEN_SURVEY}/:surveyId`}
                  exact
                  render={(props) => {
                    return (
                      <SurveyEmbed
                        surveyId={props.match.params.surveyId}
                        sessionId={sessionId}
                        history={props.history}
                        location={props.location}
                        customerId={customerId}
                      />
                    );
                  }}
                />
                {/* ***Route*** - Authenticated Survey Embed */}
                <PrivateRoute
                  path={`${paths.OPEN_SURVEY}/private/:surveyId`}
                  component={(props) => {
                    return (
                      <SurveyEmbed
                        surveyId={props.match.params.surveyId}
                        sessionId={sessionId}
                        history={props.history}
                        location={props.location}
                        customerId={customerId}
                      />
                    );
                  }}
                />

                {/* LEGACY REDIRECTS - /our-plans, /starter-packs-> /starter-packs  - Kept for legacy links out in the wild */}
                <Route
                  path='/get-started/cart'
                  exact
                  render={() => {
                    return <Redirect to={paths.SHOP_PRODUCTS} />;
                  }}
                />
                <Redirect from='/get-started' to={paths.FARMSTAND} />
                <Redirect from='/our-plans' to={paths.EXPERIENCE} />
                <Redirect from={paths.STARTER_PACKS} to={paths.EXPERIENCE} />
                <Redirect from={`/the-journey`} to={paths.EXPERIENCE} />
                <Redirect from='/starter-packs' to={paths.EXPERIENCE} />
                <Redirect from={`${paths.WHAT_WE_GROW}/:seedlingSlug?`} to={`${paths.SEEDLINGS}/:seedlingSlug?`} />
                <Redirect from='/indoor' to={paths.blogPosts.INDOOR_GROW_GUIDE} />
                <Redirect from={paths.blogPosts.GROWING_TIPS} to={paths.blogPosts.INDOOR_GROW_GUIDE} />
                <Redirect from={'/eatgreen'} to={paths.blogPosts.EAT_GREEN} />
                <Redirect from={'/virtual-shopping'} to={paths.VIRTUAL_SHOPPING} />
                <Redirect
                  from='/99'
                  to={`${paths.EXPERIENCE}?pc=99percentpod&utm_source=99pi&utm_medium=podcast&utm_campaign=99pi_podcast`}
                />
                <Redirect from={`/blog/:postSlug?`} to={`${paths.BLOG}/:postSlug?`} />
                <Redirect from={`/resources/recipes`} to={paths.RECIPES} />

                <Route path='/checkout' component={CheckoutRedirectRoute} />

                {/* Shop from iOS App. */}
                <Route path='/ios-shop' component={MobileShopLogic} />

                {/* redirect for web app store link - /ios is IOS deep linking default to handle web */}
                <Route
                  path='/ios/:context'
                  render={() => {
                    window.location.href = 'https://apps.apple.com/us/app/lettuce-grow/id1452410072';
                  }}
                />

                {/* ***Route*** - Seedling Detail - /seedlings/:seedlingSlug? */}
                <Route
                  path={`${paths.SEEDLINGS}/:seedlingSlug?`}
                  exact
                  render={(props) => <SeedlingDetail seedlingSlug={props.match.params.seedlingSlug} history={props.history} />}
                />
                <Route
                  path={`${paths.BUNDLES}/:bundleSlug`}
                  exact
                  render={(props) => <SeedlingsBundle bundleSlug={props.match.params.bundleSlug} history={props.history} />}
                />
                {/* ***Route*** - Supply Detail - /shop/:supplySlug? */}
                <Redirect from='/shop/lg-lighting-kit' to={paths.shopPages.GLOW_RINGS.SIZE_4} />
                <Redirect from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_2} to={paths.shopPages.GLOW_RINGS.SIZE_2} />
                <Redirect from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_3} to={paths.shopPages.GLOW_RINGS.SIZE_3} />
                <Redirect from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_4} to={paths.shopPages.GLOW_RINGS.SIZE_4} />
                <Redirect from={paths.shopPages.PREORDER_GLOW_RINGS.SIZE_5} to={paths.shopPages.GLOW_RINGS.SIZE_5} />
                <Route path={Object.values(paths.shopPages.GLOW_RINGS)} exact component={GlowRings} />
                <Route path={`${paths.SHOP_PRODUCTS}/:supplySlug?`} exact component={SupplyDetail} />

                {/* /blog/valid-category-or-post/ANYTHING will redirect to /blog/valid-category-or-post */}
                <Redirect from={`${paths.BLOG}/:postOrCategory/:anything`} to={`${paths.BLOG}/:postOrCategory?`} />
                <Route path={`${paths.BLOG}/:postOrCategory?`} component={BlogLanding} />

                {/* ***Route*** - FAQ - /faq */}
                <Route
                  path={`${paths.FAQ}/:question?`}
                  render={(props) => <FAQContainer params={props.match.params} history={props.history} />}
                />
                {/* ***Route*** - Recipes - /recipes */}
                <Route path={paths.RECIPES} component={RecipeLanding} />
                <Route path={paths.VIRTUAL_SHOPPING} exact component={VirtualConsultation} />
                {/* ***Route*** - Terms and Conditions - /terms */}
                <Route
                  path={paths.TERMS}
                  exact
                  render={(props) => <ContentfulPage key={paths.TERMS} pageFunc={TERMS_OF_SERVICE} history={props.history} />}
                />
                {/* ***Route*** - Privacy Policy - /privacy */}
                <Route
                  path={paths.PRIVACY}
                  exact
                  render={(props) => <ContentfulPage key={paths.PRIVACY} pageFunc={PRIVACY} history={props.history} />}
                />
                {/* ***Route*** - Warranty - /warranty */}
                <Route
                  path={paths.WARRANTY}
                  exact
                  render={(props) => <ContentfulPage key={paths.WARRANTY} pageFunc={WARRANTY} history={props.history} />}
                />
                {/* ***Route*** - Our Mission - /our-mission */}
                <Route path={paths.OUR_MISSION} exact render={() => <OurMission />} />
                {/* redirect tracking links sent in emails to fedex for tracking */}
                <Route
                  path='/app/track/:trackingNum'
                  render={(props) => {
                    window.location.href = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${props.match.params.trackingNum}`;
                    return null;
                  }}
                />
                {/* PDF Routes */}
                <Route
                  path={paths.GROWING_GUIDE}
                  render={() => {
                    window.location.href = paths.GROWING_GUIDE_PDF;
                    return <Redirect to={paths.faqPosts.FARMSTAND_UP_AND_RUNNING} />;
                  }}
                />
                <Route
                  path={paths.ASSEMBLY_GUIDES}
                  render={() => {
                    window.location.href = paths.ASSEMBLY_GUIDES_PDF;
                    return <Redirect to={paths.faqPosts.ASSEMBLE_MY_FARMSTAND} />;
                  }}
                />
                <Route
                  path={paths.PANTRY_SHOPPING_LIST}
                  render={() => {
                    window.location.href = paths.PANTRY_SHOPPING_LIST_PDF;
                    return <Redirect to={paths.faqPosts.FARMSTAND_PANTRY} />;
                  }}
                />
                <Redirect from='/timer' to={paths.faqPosts.FARMSTAND_TIMER} />
                <Redirect from='/planting' to={paths.faqPosts.TRANSPLANT_SEEDLINGS} />
                <Redirect from='/ph' to={paths.faqPosts.ADJUST_PH} />
                <Redirect from='/growingguide' to={paths.blogPosts.GROWING_GUIDE} />
                <Redirect from='/reset' to={paths.faqPosts.RESET_FARMSTAND} />
                <Redirect from='/lighting' to={paths.shopPages.GLOW_RINGS.SIZE_4} />
                {/* ***Route*** - Default - Catch Random URLs to HOME. Should be 404 page / */}
                <NotFound />
              </Switch>
            </ErrorBoundary>
            <ErrorBoundary>
              {!location.pathname.includes(paths.OPEN_SURVEY) &&
                !location.pathname.includes(paths.SURVEY_RESULTS) &&
                !isFromMobile &&
                !location.pathname.includes(paths.FILL_YOUR_FARMSTAND) &&
                !location.pathname.includes(paths.FILL_YOUR_FARMSTAND_BUNDLES) &&
                !location.pathname.includes('/ios') && (
                  <>
                    <LettuceModal />
                    <LettuceGiveBanner />
                  </>
                )}
              {!location.pathname.includes('/ios') && !isFromMobile && <Footer />}
            </ErrorBoundary>
            <LoginModal />
            <ErrorModal />
          </Suspense>
        </ThemeProvider>
      </StyleSheetManager>
    </>
  );
}
