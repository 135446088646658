import styled from 'styled-components';

import LeftArrow from 'ui-redesign/elements/icon/assets/left.svg';

export const ProductCollapseStyled = styled.div.attrs({
  className: 'ProductCollapseStyled',
})``;

export const CollapseWrapper = styled.div.attrs({
  className: 'CollapseWrapper',
})`
  padding-bottom: 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin-bottom: 1rem;

  .AccordionSection {
    cursor: pointer;

    .AccordionTitleSection {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .icon {
        &::before {
          content: '';
          display: block;
          width: 1rem;
          height: 1rem;
          background-image: url(${LeftArrow});
          background-position: center center;
          background-repeat: no-repeat;
          transform: rotate(-90deg);
          transition: all 0.35s ease-in-out;
        }
      }

      .icon-minus-thin {
        &::before {
          transform: rotate(90deg);
        }
      }

      .AccordionTitle {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 1rem;
      }
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})`
  :nth-child(1) {
    padding-top: 0.3rem;
  }

  .Text {
    display: flex;
    padding-top: 0.4rem;
    margin-bottom: 0.4rem;
    padding-left: 1rem;
    font-size: 0.785rem;
    text-align: left;
    position: relative;

    a {
      color: ${({ theme }) => theme.farmProject.green};
    }

    &::before {
      content: '';
      display: block;
      background-color: ${({ theme }) => theme.farmProject.dark_gray};
      width: 4px;
      height: 4px;
      border-radius: 100%;
      position: absolute;
      left: 0.35rem;
      top: 0.875rem;
      transform: translate(-50%, -50%);
    }
  }
`;
