import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon, Text } from 'ui-redesign/elements';
import { CookieComponentStyled, ContentInfo } from './CookieComponent.styled';
import { getStorage, setStorage } from 'utils/storageManager';
import { useHideGorgiasChat } from 'utils/hooks';

import { ReactComponent as CloseIcon } from 'ui-redesign/elements/icon/assets/close.svg';

const CookieComponent = ({ modifiers }) => {
  const messages = useSelector((state) => state.contentful.messages);
  const ACCEPTED_COOKIES_KEY = 'ACCEPTED_COOKIES';
  const [acceptedCookies, setAcceptedCookies] = useState(getStorage(ACCEPTED_COOKIES_KEY));
  const cookiesMessage = messages?.cookies_message;

  useHideGorgiasChat(!acceptedCookies);

  const handleAccepted = () => {
    setStorage(ACCEPTED_COOKIES_KEY, true);
    setAcceptedCookies(true);
  };

  if (!messages) return null;

  return (
    !acceptedCookies && (
      <CookieComponentStyled modifiers={modifiers}>
        <ContentInfo>
          <Text modifiers='noLetterSpace' content={cookiesMessage} isHTML />
          <Icon onClick={handleAccepted}>
            <CloseIcon />
          </Icon>
        </ContentInfo>
      </CookieComponentStyled>
    )
  );
};

CookieComponent.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.string]),
};

export default CookieComponent;
