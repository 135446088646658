import styled from 'styled-components';
import breakPoints from '../../../theme-redesign/Media';

export const CardRecipeSliderStyled = styled.div.attrs({
  className: 'CardRecipeSliderStyled',
})`
  width: 100%;
  max-width: 64rem;
  margin: 1rem auto;

  > .Title {
    text-align: center;
    letter-spacing: normal;
    margin-bottom: 1.5rem;
  }

  .slick-slide {
    height: auto;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    margin-top: 1rem;

    li {
      button {
        border: 1px solid ${({ theme }) => theme.farmProject.green};
      }
    }

    .slick-active {
      button {
        background-color: ${({ theme }) => theme.farmProject.green};
        border: 1px solid ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  @media ${breakPoints.large_break} {
    > .Title {
      font-size: 5rem;
    }
  }
`;
