import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Image, Title, Text, SquaredButton } from 'ui-redesign/elements';

import { GlowingRingCallStyled, GlowingCallInner, GlowingCallInformation } from './FarmGlowRings.styled';

import { replaceText } from 'utils/string-utils';
import { formatDynamicDecimalPrice as formatPrice } from 'utils/cart-utils';
import paths from 'constants/paths';

const defaultName = 'Glow Rings™';
const defaultGR = paths.shopPages.GLOW_RINGS.SIZE_4;
const glowRingsPaths = Object.values(paths.shopPages.GLOW_RINGS);

const GlowingRingsCall = ({ title, body, primaryButton, image = '', glowRing, onSelectGlowRing, hasFarmstand = false }) => {
  const [isGRAdded, setIsGRAdded] = useState(false);
  const glowRingLink = glowRingsPaths.find((e) => e.includes(glowRing?.slug)) || defaultGR;
  const { name, priceCents: price } = glowRing || {};

  const fixedName = name === defaultName ? `${defaultName} (4-ring)` : name || defaultName;
  const hasFarmAndGR = hasFarmstand && isGRAdded;
  const buttonPrice = hasFarmstand && price ? `(${formatPrice(price)})` : '';
  const buttonText = `${hasFarmAndGR ? 'ADDED' : primaryButton?.fields?.linkText || ''} ${buttonPrice}`;
  const isButtonInactive = !hasFarmAndGR;
  const buttonModifiers = [isButtonInactive && 'invertedNoHover', !isButtonInactive && 'primaryNoHover', !hasFarmstand && 'ghost'];

  const onAddGR = () => {
    onSelectGlowRing(!isGRAdded);
    setIsGRAdded(!isGRAdded);
  };

  return (
    <GlowingRingCallStyled>
      <Text modifiers={['brandFont', 'noLineHeight']} content={title} isHTML />
      <GlowingCallInner>
        <Image picture={image} />
        <GlowingCallInformation>
          <Title modifiers={['lineHeightNormal']} content={fixedName} />
          <Text modifiers={['brandFont', 'noLineHeight']} content={replaceText(body, [{ key: '__GR__', value: glowRingLink }])} isHTML />
          <SquaredButton label={buttonText} modifiers={buttonModifiers} onClick={onAddGR} />
        </GlowingCallInformation>
      </GlowingCallInner>
    </GlowingRingCallStyled>
  );
};

GlowingRingsCall.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  image: PropTypes.string,
  primaryButton: PropTypes.object,
  glowRing: PropTypes.object,
  onSelectGlowRing: PropTypes.func,
  hasFarmstand: PropTypes.bool,
};

export default GlowingRingsCall;
