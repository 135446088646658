import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LoadingIcon } from 'assets/images/loading.svg';

import { Icon } from 'ui-redesign/elements';
import LoadingStyled, { LoadIconWrapper, LoaderCircle, LoaderLineMask, LoaderLine } from './Loading.styled';

const Loading = ({ isFullHeight, noImage }) => {
  return (
    <LoadingStyled isFullHeight={isFullHeight} as={isFullHeight ? 'main' : 'section'}>
      {!noImage && (
        <LoadIconWrapper>
          <LoaderCircle />
          <LoaderLineMask>
            <LoaderLine />
          </LoaderLineMask>
          <Icon>
            <LoadingIcon />
          </Icon>
        </LoadIconWrapper>
      )}
    </LoadingStyled>
  );
};

Loading.defaultProps = {
  isFullHeight: false,
  noImage: false,
};

Loading.propTypes = {
  isFullHeight: PropTypes.bool,
  noImage: PropTypes.bool,
};
export default Loading;
