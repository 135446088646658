import React, { useEffect, useContext, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebaseApp from 'firebase/app';

import firebase from 'utils/firebaseConfig';
import { userLogin, userLogout, setUserValue, getLoginState } from 'redux/user';
import logError from 'utils/errorHandler';

const auth = firebaseApp.auth;

/**
 * * useAuth hook for firebase auth functions and user from redux
 */

export const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginState);
  const user = useSelector((state) => state.user);

  //Sign in function
  const signIn = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        if (error?.code === 'auth/wrong-password') return null;
        logError(error);
        return null;
      });
  };

  const signUp = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        logError(error);
        return null;
      });
  };

  const signOut = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        // signed out
        dispatch(userLogout());
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  const checkActionCode = (code) => {
    return firebase
      .auth()
      .checkActionCode(code)
      .then((code) => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  // programmatically change a user's password.
  // currently used for updating guest -> account status
  const changePassword = async (oldPassword, newPassword) => {
    try {
      const user = await firebase.auth().currentUser;
      if (!user) return false;
      const credential = await auth.EmailAuthProvider.credential(user.email, oldPassword);
      await user.reauthenticateWithCredential(credential);
      return user
        .updatePassword(newPassword)
        .then(() => true)
        .catch(() => false);
    } catch (error) {
      logError(error);
      return false;
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // not called if only idToken changes
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((authToken) => {
          dispatch(userLogin({ authToken, email: user.email }));
          dispatch(setUserValue({ label: 'isAuthenticationPending', value: false }));
        });
      } else {
        dispatch(setUserValue({ label: 'isAuthenticationPending', value: false }));
        if (isLoggedIn) {
          signOut();
        }
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // update authToken if it was refreshed
  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged((fbUser) => {
      if (fbUser) {
        fbUser.getIdToken().then((authToken) => {
          if (authToken !== user.authToken) {
            dispatch(setUserValue({ label: 'authToken', value: authToken }));
          }
        });
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // Return the user object and auth methods
  return {
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    checkActionCode,
    changePassword,
  };
};
