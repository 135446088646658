import { createSlice, createSelector } from '@reduxjs/toolkit';
import { AXIOS } from 'redux/middleware/apiMiddleware';
import { prettyFormatPhone } from 'utils/form-utils';

/**
 * * user - Redux Reducer
 *
 * user  Content type from Contentful
 *
 * @param state
 *
 */

export const initialState = {
  email: '',
  password: '', // only used for temp guest password
  firstName: '',
  lastName: '',
  address: '',
  postStop: '',
  city: '',
  state: '',
  zip: '', // no default here- will be set by initial /catalog response (BE guesses user zip for guest user)
  isZipSupported: true,
  phone: '',
  authToken: '',

  isLoggedIn: false,
  myFarm: null,
  myOrders: null,
  hasTakenSurvey: false,
  isFromMobile: false,
  isAuthenticationPending: true,
  isGuest: true,
  userSetEnvironment: 'DEFAULT',
};

// reducer, action types, action creators all in 1 createSlice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // set fetched data into store
    setUserValue(state, { payload }) {
      state[payload.label] = payload.value;
    },
    setUserZip(state, { payload }) {
      state.zip = payload;
      state.userSetEnvironment = 'DEFAULT';
    },
    userLogin(state, { payload }) {
      state.isLoggedIn = true;
      state.authToken = payload.authToken;
      state.email = payload.email;
    },
    userLogout(state) {
      return initialState;
    },
    setMyFarm(state, { payload }) {
      const { address, postStop, city, state: userState, zip, phone } = payload.customer;
      state.myFarm = payload;
      state.email = payload.contact.email;
      state.firstName = payload.contact.name ? payload.contact.name.split(' ')[0] : '';
      state.lastName = payload.contact.name ? payload.contact.name.split(' ')[1] : '';
      state.address = address;
      state.postStop = postStop;
      state.city = city;
      state.state = userState;
      state.zip = city ? zip : ''; // dont set zip after GC only purchase (no other address field will be present)
      state.phone = phone.includes('+1') ? prettyFormatPhone(phone) : phone;
    },
    setMyOrders(state, { payload }) {
      state.myOrders = payload;
    },
    setUserSetEnvironment(state, { payload }) {
      if (payload) {
        /**
         * OUTDOOR should be considered OUTDOOR.
         * Any other values (WITH_HEAT, CUSTOM, INDOOR etc) is considered INDOOR.
         */
        state.userSetEnvironment = payload === 'OUTDOOR' ? 'OUTDOOR' : 'INDOOR';
      } else {
        state.userSetEnvironment = 'DEFAULT';
      }
    },
    setIsZipSupported(state, { payload }) {
      state.isZipSupported = payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

// Extract and export action creators from slice by name
export const {
  setUserValue,
  userLogin,
  userLogout,
  setUserZip,
  setMyFarm,
  setMyOrders,
  setUserSetEnvironment,
  setIsZipSupported,
} = actions;

// Export the reducer as the default
export default reducer;

export const getLoginState = createSelector([(state) => state.user], (user) => user.isLoggedIn && !user.isGuest);

export const getCustomer = createSelector([(state) => state.user], (user) => (user.myFarm ? user.myFarm.customer : {}));

export const getHasTakenSurvey = createSelector([(state) => state.user], (user) => user.hasTakenSurvey);

export const getMyFarm = createSelector([(state) => state.user], (user) => user.myFarm);

export const getDeliveryById = (id) =>
  createSelector(getMyFarm, (myFarm) => myFarm?.deliveries?.find((delivery) => delivery.customerDelivery?.id === id));

/**
 * * fetchMyFarm - Async Action Creator to hit catalog BE endpoint
 *
 */ export const fetchMyFarm = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/v2/myFarm',
    method: 'GET',
    onSuccess: setMyFarm,
  },
});

/**
 * * fetchMyOrders - Async Action Creator to hit myOrders BE endpoint
 */

export const fetchMyOrders = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/myOrders',
    method: 'GET',
    onSuccess: setMyOrders,
  },
});
