import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WidthProvider from 'components/deprecated/hoc/WidthProvider';

import AccordionStyled from './Accordion.styled';

const Accordion = ({ width, setHeight, content, isOpen }) => {
  const [maxHeight, setMaxHeight] = useState('');
  const contentRef = useRef();

  useEffect(() => {
    const getMaxHeight = () => {
      // check for a default height from parent
      if (setHeight) {
        // set state height if not equal
        if (setHeight !== maxHeight) setMaxHeight(setHeight);
      } else if (contentRef?.current?.offsetHeight > maxHeight || !maxHeight) {
        setMaxHeight(contentRef?.current?.offsetHeight);
      }
    };
    getMaxHeight();
    /* eslint-disable-next-line */
  }, [setHeight, width, content, contentRef]);

  return (
    <AccordionStyled maxHeight={isOpen ? maxHeight : 0}>
      <div className='lg-accordion__inner' ref={contentRef}>
        {content}
      </div>
    </AccordionStyled>
  );
};

Accordion.defaultProps = {
  isOpen: false,
};

Accordion.propTypes = {
  content: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
};

export default WidthProvider(Accordion);
