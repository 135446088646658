import { createSlice, createSelector } from '@reduxjs/toolkit';

import { HALO_LIGHTS_SKUS } from 'constants/sku';
import { getItemByVariantId } from 'redux/catalog';
import shopCategories from 'constants/shopCategories';

const { FARMSTAND, SEEDLINGS, SUPPLIES, EXTENSION, BUNDLES, CUSTOM_BUNDLES, GIFT } = shopCategories;

/**
 * * cartCheckout - Redux Reducer
 *
 * cart  Content type from Contentful
 *
 * @param state
 *
 */

export const initialState = {
  isCartOpen: false,
  areSeedlingsExpanded: false,
  lastAddedFarmstandId: null,
  hasUpdate: false, // have we programmtically updated cart based on inventory or availability?
  isLoading: false,
  checkoutError: '',
  checkout: { lineItems: [], discountApplications: [], userErrors: [] },
};

const cartSlice = createSlice({
  name: 'cartCheckout',
  initialState,
  reducers: {
    initializeClient(state) {
      // see middleware
      return state;
    },
    addItems(state) {
      // HEADS UP - adding items requires variant ids of items to add from product catalog
      // see middleware
      return state;
    },
    removeItems(state) {
      // HEADS UP - removing items requires ids of items to remove from shopify checkout object itself (not variant id)
      // see middleware
      return state;
    },
    updateItems(state) {
      // HEADS UP - updating items requires ids of items to update from shopify checkout object itself (not variant id)
      // see middleware
      return state;
    },
    replaceSeedlings(state) {
      // see middleware
      return state;
    },
    addDiscount(state) {
      // see middleware
      return state;
    },
    removeDiscount(state) {
      // see middleware
      return state;
    },
    notifyCartUpdate(state) {
      state.hasUpdate = true;
    },
    addMetadata(state) {
      // see middleware
      return state;
    },
    openCart(state, { payload }) {
      state.isCartOpen = true;
      state.areSeedlingsExpanded = !!payload;
    },
    closeCart(state) {
      state.isCartOpen = false;
      state.areSeedlingsExpanded = false;
      if (state.hasUpdate) {
        state.hasUpdate = false; // reset card update flag
      }
    },
    setLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setLastAddedFarmstandId(state, { payload }) {
      state.lastAddedFarmstandId = payload;
    },
    clearCart() {
      return initialState;
    },
    setCheckout(state, { payload }) {
      state.checkout = payload;
      state.isLoading = false;
    },
    setCheckoutError(state, { payload }) {
      state.checkoutError = payload;
    },
    goToCheckout(state) {
      return state;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = cartSlice;

// Extract and export action creators from slice by name
export const {
  setLastAddedFarmstandId,
  setLoading,

  initializeClient,
  setCheckout,
  setCheckoutError,
  openCart,
  closeCart,
  addItems,
  removeItems,
  updateItems,
  replaceSeedlings,
  addDiscount,
  removeDiscount,
  notifyCartUpdate,
  addMetadata,
  clearCart,
  goToCheckout,
} = actions;

// Minimum # of seedlings that need to be in cart to checkout
const MIN_NUM_SEEDLINGS = 6;

export const getCheckout = createSelector(
  (state) => state.cartCheckout,
  (cartCheckout) => cartCheckout.checkout
);

// Selector for getting number of seedlings in cart, counting also the seedlings in the bundle
export const getNumSeedlingsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  return checkout.lineItems.reduce((acc, curr) => {
    const catSeedling = getItemByVariantId(state, curr.variant?.id);
    if (catSeedling?.category === SEEDLINGS) {
      return acc + curr.quantity;
    }
    if (catSeedling?.category === BUNDLES || catSeedling?.category === CUSTOM_BUNDLES) {
      return acc + curr.quantity * catSeedling.plantCount;
    }
    return acc;
  }, 0);
});

export const getNotEnoughSeedlingsInCart = createSelector(getNumSeedlingsInCart, (numSeedlings) => {
  return numSeedlings > 0 && numSeedlings < MIN_NUM_SEEDLINGS;
});

export const getShouldShowAffirm = createSelector(
  getCheckout,
  (state) => state,
  (checkout, state) => {
    return parseInt(checkout.subtotalPriceV2?.amount) >= parseInt(state.appSettings.affirmMin);
  }
);

export const getGiftOptionError = createSelector(getCheckout, (checkout) => {
  return (
    checkout.customAttributes.find((attr) => attr.key === 'isGift' && attr.value === 'true') &&
    (checkout.customAttributes.find((attr) => attr.key === 'toEmail' && !attr.value) ||
      !checkout.customAttributes.find((attr) => attr.key === 'toEmail'))
  );
});

export const getShipDateError = createSelector(getCheckout, (checkout) => {
  const shipDateAttr = checkout.customAttributes.find((attr) => attr.key === 'shipDate');
  return shipDateAttr && shipDateAttr.value?.includes?.('Invalid');
});

// Selector for getting an item in cart
export const getItemInCart = createSelector([getCheckout, (_, variantId) => variantId], (checkout, variantId) => {
  if (!checkout.lineItems.length) return null;

  return checkout.lineItems.find((cartItem) => cartItem.variant?.id === variantId);
});

export const getSeedlingsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  const catalogItems = [];
  checkout.lineItems.forEach((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem.variant?.id);
    if (
      catItem?.category === shopCategories.SEEDLINGS ||
      catItem?.category === shopCategories.BUNDLES ||
      catItem?.category === shopCategories.CUSTOM_BUNDLES
    ) {
      catalogItems.push(catItem);
    }
  });
  return catalogItems;
});

export const getNonSeedlingsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  const catalogItems = [];
  checkout.lineItems.forEach((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem.variant?.id);
    if (catItem && catItem?.category !== SEEDLINGS && catItem?.category !== BUNDLES && catItem?.category !== CUSTOM_BUNDLES) {
      catalogItems.push(catItem);
    }
  });
  return catalogItems;
});

export const getSeedlingsPriceTotal = createSelector([getSeedlingsInCart, (state) => state], (seedlings, state) => {
  return seedlings.reduce((total, seedling) => {
    const quantity = getItemInCart(state, seedling.shopifyVariantId)?.quantity;
    total += seedling.priceCents * quantity;
    return total;
  }, 0);
});

export const getFarmstandsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  const catalogItems = [];
  checkout.lineItems.forEach((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem.variant?.id);
    if (catItem?.category === FARMSTAND) {
      catalogItems.push(catItem);
    }
  });
  return catalogItems;
});

export const getSuppliesInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  return checkout.lineItems.filter((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem.variant?.id);
    return catItem?.category === SUPPLIES || catItem?.category === EXTENSION;
  });
});

export const getLightsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  return checkout.lineItems.filter((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem.variant?.id);
    return HALO_LIGHTS_SKUS.includes(catItem.sku);
  });
});

export const getCatalogItemsInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  const catItems = [];
  checkout.lineItems.forEach((cartItem) => {
    const item = getItemByVariantId(state, cartItem?.variant?.id);
    item && catItems.push(item);
  });
  return catItems;
});

export const getGiftCardInCart = createSelector([getCheckout, (state) => state], (checkout, state) => {
  return checkout.lineItems.find((cartItem) => {
    const catItem = getItemByVariantId(state, cartItem?.variant?.id);
    return catItem?.category === GIFT;
  });
});

export const getCartHasFarmstand = createSelector([getFarmstandsInCart], (farmstands) => !farmstands.length);

export const getCheckoutId = createSelector([(state) => state.cartCheckout], (cart) => cart.checkout.id);

export default reducer;
