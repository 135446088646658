import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import { IncreaseInputStyled, Input, BtnInput, modifiersIncrementInput } from './IncrementInput.styled';
import { SquaredButton } from 'ui-redesign/elements';

const IncreaseInput = ({
  sku,
  quantity,
  onIncrease,
  onDecrease,
  disabledDecrease,
  disabledIncrease,
  inputDisabled,
  isActive,
  className,
  modifiers,
}) => {
  const [optimisticQty, setOptimisticQty] = useState(quantity);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading && quantity === optimisticQty) {
      setIsLoading(false);
    }
  }, [isLoading, quantity, optimisticQty]);

  const handleDecrease = () => {
    setOptimisticQty(quantity - 1);
    setIsLoading(true);
    onDecrease();
  };
  const handleIncrease = () => {
    setOptimisticQty(quantity + 1);
    setIsLoading(true);
    onIncrease();
  };
  return (
    <IncreaseInputStyled className={className} modifiers={modifiers}>
      {isLoading ? <SquaredButton modifiers='loading' /> : <Input value={quantity.toString()} disabled={inputDisabled} readOnly />}
      <BtnInput modifiers={(disabledDecrease || !isActive || isLoading) && 'disabled'}>
        <span id={sku} onClick={handleDecrease}>
          -
        </span>
      </BtnInput>
      <BtnInput modifiers={(disabledIncrease || !isActive || isLoading) && 'disabled'}>
        <span id={sku} onClick={handleIncrease}>
          +
        </span>
      </BtnInput>
    </IncreaseInputStyled>
  );
};

IncreaseInput.propTypes = {
  sku: PropTypes.string,
  quantity: PropTypes.number,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
  disabledIncrease: PropTypes.bool,
  disabledDecrease: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  modifiers: styleModifierPropTypes(modifiersIncrementInput),
};

IncreaseInput.defaultProps = {
  quantity: 0,
  disabledIncrease: false,
  disabledDecrease: false,
  inputDisabled: false,
  isActive: true,
  className: '',
  modifiers: null,
};

export default IncreaseInput;
