import contentful from './contentfulMiddleware';
import appSettings from './appSettingsMiddleware';
import api from './apiMiddleware';
import cartCheckout from '../cartCheckout/cartCheckoutMiddleware';
import storage from './storageMiddleware';
import catalog from './catalogMiddleware';
import user from './userMiddleware';
import reservation from '../reservation/reservationMiddleware';

// export array of middleware
export default [contentful, appSettings, api, cartCheckout, storage, catalog, user, reservation];
