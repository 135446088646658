import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from 'redux/appSettings';

/**
 * * useStopLoading - Custom hook to stop app globalLoading
 *
 * @param {boolean} shouldStop - Condition in which the stop loading action should be dispatched
 *
 * */
export default function useStopLoading(shouldStop) {
  // Get redux dispatch function
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.appSettings.isLoading);

  // dispatch loading false when satisfying the condition (shouldStop === true) and (isLoading === true)
  useEffect(() => {
    if (shouldStop && isLoading) {
      /**
       * Timeout to avoid cases when this is dispatched before a dispatch(setIsLoading(true)) in the App.js,
       * which can causes an infinite loading - the conditions (shouldStop && isLoading) were already true,
       * and the dispatch below is triggered. But after that, a setIsLoading(true) is dispatched on App.js,
       * and before the selector can see the isLoading going to 'false', its value was already changed back to 'true',
       * what makes this useEffect not being triggered anymore - and causes the infinite Loading.
       */
      setTimeout(() => dispatch(setIsLoading(false)), 10);
      dispatch(setIsLoading(false));
    }
  }, [shouldStop, isLoading, dispatch]);
}
