import styled, { css } from 'styled-components/macro';

export default styled.div.attrs({ className: 'TextArea' })`
  position: relative;
  width: ${(props) => props.width};
  background: ${({ theme }) => theme.farmProject.gray_2};
  margin-bottom: 0.5rem;
  & + .Text {
    margin-bottom: 3.25rem;
  }
`;

export const Input = styled.textarea`
  height: 80px;
  background: ${({ theme }) => theme.farmProject.gray_2};
  margin: 0.9em 0 -0.5rem;
  width: 100%;
  color: ${({ theme }) => theme.farmProject.dark_gray};
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.farmProject.font_family};
  padding: 0.8em 1.1em;
  box-sizing: border-box;
  border-bottom: ${({ theme, hasError }) =>
    hasError ? `2px solid ${theme.farmProject.error}` : `1px solid ${theme.farmProject.dark_gray}`};

  &:focus {
    outline: none;
  }

  &:active ~ label,
  &:focus ~ label,
  &:not(:empty) ~ label {
    top: 0.5rem;
    font-size: 0.6rem;
  }
`;

export const Label = styled.label`
  background: ${({ theme }) => theme.farmProject.gray_2};
  position: absolute;
  padding-left: 0.8rem;
  top: 1.4rem;
  left: 0;
  right: 0;
  color: ${({ theme, hasError }) => (hasError ? theme.farmProject.error : theme.farmProject.dark_gray)};
  font-size: 0.75rem;
  pointer-events: none;
  transition: 300ms ease all;
  text-transform: initial;
  text-align: left;
  font-weight: 400;

  ${(props) =>
    props.required &&
    css`
      &::after {
        content: '*';
        color: ${({ theme }) => theme.farmProject.error};
      }
    `}
`;
