import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme-redesign/Media';
import { centeredMixin } from 'theme-redesign/Mixins';

export const modifiers = {
  modal: () => ({
    styles: `
      @media ${breakPoints.medium_break} {
        .Title {
          line-height: 4.6875rem;
        }

        form {
          .Button {
            margin-top: 0.5rem;
          }
        }
      }
    `,
  }),
};

export const LoginForm = styled.div.attrs({
  className: 'LoginForm',
})`
  .Title {
    line-height: normal;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .Text {
    text-align: center;
    margin-bottom: 0;
  }

  .Text + .Text {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  form {
    ${centeredMixin};

    .InputStyled {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    .Button {
      width: 100%;
      max-width: 18rem;
      height: 2.25rem;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    .ButtonText {
      text-decoration-color: ${({ theme }) => theme.farmProject.dark_gray};
      span {
        font-size: 0.8rem;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .Title {
      line-height: 4.6875rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    .Button {
      max-width: 10rem;
      margin-top: 3.4375rem;
    }

    form {
      margin-top: 1.5rem;
      ${centeredMixin};

      .InputStyled {
        margin-top: 0;

        &:last-of-type {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }

      .Button {
        max-width: 10rem;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
      }
    }
  }
  ${applyStyleModifiers(modifiers)}
`;
