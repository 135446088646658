export const BLOG_PAGE = { id: '4VQ6UbfsfxtfD58LOsNNh1', label: 'blogPage' };
export const BLOG_POST_PAGE = { id: '6EIU5zv0YnxJXlYTrz3Lj6', label: 'blogPostPage' };
export const BLOG_CATEGORY_PAGE = { id: '11OGqr7VcmUSGFJNvVtCWB', label: 'blogCategoryPage' };
export const BLOG_POSTS = { content_type: 'blogPost', label: 'blogPosts', order: 'fields.rank' };
export const BLOG_CATEGORIES = { content_type: 'postCategory', label: 'blogCategories', order: 'fields.rank' };
export const BUYABLES = { content_type: 'buyable', label: 'buyables', order: 'fields.rank' };
export const CART_CHANGED_MODAL = { id: '6l361sQ9wycxfi6RrW0Qcg', label: 'cartChangedModal' };
export const CLIMATE_HEATER_MODAL = { id: '7jkBtw4Ml8cwIwaHGyoIdz', label: 'climateHeaterModal' };
export const CLIMATE_LIGHTING_MODAL = { id: '7ek9Uyu8r8KzUF7KMZaGih', label: 'climateLightingModal' };
export const CLIMATE_GOOD_GROW_MODAL = { id: '4QjBqSuXnH2etBDrYOqNMm', label: 'climateGoodToGrowModal' };
export const CONTACT_SECTION = { id: '509tndw5LI2g5DPGrK1qJl', label: 'contactSection' };
export const CONTACT_US = { id: '50Q7hcdtkUEdAAP3BhXuOQ', label: 'contactUs' };
export const CUSTOMER_REVIEWS = { id: '5iKB67LikQEZLgZsUyhQQb', label: 'customerReviews' };
export const FILL_YOUR_FARMSTAND = { id: '6dL5NaxbVodpbLfvGyZLjO', label: 'fillYourFarmstand' };
export const FYF_BUNDLES_2020 = { id: '1jekSX2sbuSPQHe39SKiqA', label: 'fillYourFarmstandBundles' };
export const FAQ_LANDING = { id: '5rfCTDpIVEryyB7FWU9vRc', label: 'faqLanding' };
export const FAQ_CATEGORIES = { content_type: 'faqCategory', label: 'faqCategories' };
export const FAQS = { content_type: 'faq', label: 'faqs' };
export const GIFT_CARD = { id: 'Z3Jvdy5kYi5CdXlhYmxlLTEwOQ', label: 'giftCard' };
export const HOME_2020 = { id: '2p3fSt6Jm4B1LGRSoi57A6', label: 'home' };
export const NOT_FOUND = { id: '7BwcwkHvbK9lExXnbwxQQ9', label: 'notFound' };
export const FARMSTAND_LANDING_2020 = { id: '62YdyVtZxmU6rI3YsdaHRv', label: 'farmstandLanding' };
export const FARMSTAND_PDP_2020 = { id: '6k2CuCkYPRUnbN0PIwrRFV', label: 'farmstand-pdp' };
export const FARMSTANDS_2020 = { id: '1368lss8SXKqO26v9USf0B', label: 'farmstands' };
export const GLOWRINGS_PDP_2020 = { id: 'rF9TSF2zbumtm8LKbPIv5', label: 'glowrings-pdp' };
export const FARMSTAND_BUNDLE_PDP_2020 = { id: '5uPCyUZX7GQrkTXIIAOGkd', label: 'farm-bundle-pdp' };
export const NEW_FARMSTAND_BUNDLE_PDP_2021 = { id: '7FIgQlGBdtWDyd6zVxNjZ2', label: 'farm-bundle-2-pdp' };
export const HOW_IT_WORKS_2020 = { id: 'ciUtgSZxfjZ78n2oi58aK', label: 'howItWorks' };
export const LETTUCE_GIVE = { id: '42pooB1pqiCGIPsYI9QBWu', label: 'lettuceGive' };
export const LETTUCE_MODAL = { id: 'cgkabhycKr2LiZBzG8V6S', label: 'lettuceModal' };
export const LOGIN_PAGE = { id: '78Ul6L3qyACyjA2oDpQzxR', label: 'loginPage' };
export const MESSAGE_BANNER = { id: '2jRn8BRX9EHvCl6gejBnvg', label: 'messageBanner' };
export const MY_FARM = { id: '1OT1PzOAjOQw6y0u24YM4c', label: 'myFarm' };
export const NEWSLETTER_SIGNUP = { id: '5n5iTS7J96iiYassgicaMu', label: 'newsletterSignup' };
export const OUR_MISSION = { id: '1Yh6fQ4XBgqdSEmfUS8MZ2', label: 'ourMission' };
export const PLANT_TYPE = { content_type: 'plantType', label: 'plantType', limit: 500, query: { 'fields.weblistingEnabled': true } };
export const PRIVACY = { id: '1hx5O6QSgwM0QGmwsem24q', label: 'privacy' };
export const PROMO_BANNER = { id: '7w9wY5M6ap22YJwju51AVe', label: 'promoBanner' };
export const PRESS_SECTION = { id: 'ImssOjXCN4kLN3lnPuxxP', label: 'pressSection' };
export const RECIPES = { content_type: 'recipe', label: 'recipes', limit: 500 };
export const RECIPE_PAGE = { id: '56qoZ9K8ElMjWGdvoVDYC7', label: 'recipePage' };
export const RECIPE_DETAIL_PAGE = { id: '2daRgzc1RNpQ2DOmb0x7OP', label: 'recipeDetailPage' };
export const REVIEWS_PAGE = { id: '5FY8CU13VToFohBjJxUY7g', label: 'reviewsPage' };
export const SEEDLINGS_SUPPLIES = { id: '44ZPb1DhKgGMgtjK6FBuUl', label: 'seedlingsSupplies' };
export const SEEDLING_PACKS = { id: '34nLDcIepP7KUs1vUtc0ny', label: 'packRecommendation' };
export const SEEDLING_LANDING = { id: '3INTUuZFsHVFEm2pSnuwk', label: 'seedlingLanding' };
export const SEEDLING_DETAIL = { id: '4aVH0AS5Ghmicqteg62Oor', label: 'seedlingDetail' };
export const SOCIAL_POSTS = { id: '5w57D0LlHZOHN1jv8WksL9', label: 'socialPosts' };
export const SEEDLINGS_BUNDLE_PAGE = { id: '37sari6WHauDSQ26Jwdenm', label: 'bundleDetail' };
export const PLANT_BUNDLE = { content_type: 'plantBundle', label: 'plantBundle', limit: 500, query: { 'fields.weblistingEnabled': true } };
export const SURVEY_RESULTS_2020 = { id: '6rdwKjaoZnIjsy1a71v1ph', label: 'surveyResults' };
export const TERMS_OF_SERVICE = { id: '4mfd5ulHReeIy2GKUUqIQs', label: 'terms' };
export const VERIFY_ADDRESS_MODAL = { id: '19X61XaESFtKvDkDQpU13J', label: 'verifyAddressModal' };
export const VIRTUAL_SHOPPING_2020 = { id: '4biLPOsFACdbhPwWgxjqGo', label: 'virtualConsultation' };
export const WARRANTY = { id: '5epifsm2g72hAzo7qrKgsY', label: 'warranty' };
