import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Text } from 'ui-redesign/elements';
import { AddToCart } from 'ui-redesign/components';

import { setLastAddedFarmstandId } from 'redux/cartCheckout/cartCheckout';
import { getItemById } from 'redux/catalog';

import paths from 'constants/paths';

import icons from 'constants/icons';
import itemAvailabilities from 'constants/itemAvailabilities';

import { formatPrice } from 'utils/cart-utils';

import { PriceInformation } from './styled';

const Price = ({ id, price, showAddToCart, farmstandSize }) => {
  const history = useHistory();
  const farmstandFromCatalog = useSelector((state) => getItemById(state, id));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isOutOfSeason = farmstandFromCatalog?.availability === itemAvailabilities.OUT_OF_SEASON;

  const handleAddFarmstand = () => {
    setIsLoading(true);
    dispatch(setLastAddedFarmstandId(farmstandFromCatalog.sku));
    history.push(paths.FILL_YOUR_FARMSTAND_BUNDLES);
  };

  price = parseInt(price, 10);
  farmstandSize = parseInt(farmstandSize, 10);

  return (
    <PriceInformation data-nw={`price-information-${farmstandSize}`}>
      <Text content={`<strong>$${price}</strong> OR ${formatPrice(price / 12, 0)}/mo for 12 months`} isHTML />
      <span data-page-type='cart' data-amount={price * 100} className={`affirm-product-modal icon icon-${icons.INFO}`} />
      {showAddToCart && (
        <AddToCart
          item={farmstandFromCatalog}
          onAddItem={handleAddFarmstand}
          isLoading={isLoading}
          showPrice={false}
          isOutOfSeason={isOutOfSeason}
          outOfSeasonLabel='UNAVAILABLE'
        />
      )}
    </PriceInformation>
  );
};

Price.propTypes = {
  id: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showAddToCart: PropTypes.bool,
  farmstandSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Price.defaultProps = {
  showAddToCart: true,
};

export default Price;
