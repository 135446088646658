import React, { useEffect, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';

import { Text } from 'ui-redesign/elements';

import { FarmstandSizeStyled, SizeButtonGroup, SelectSizeButton, LabelWrapper } from './FarmSelectSize.styled';

import paths from 'constants/paths';
import { BundleContext, setSelectedBundleSize } from 'pages/promotion-page/utils/bundleContext';

const FarmstandSize = ({
  title,
  data = [],
  glowRingSlugs = [],
  onSelectFarmstand,
  imgSliderRef,
  glowRingsNumberLabel = '',
  hasFarmstand = false,
  isBundlePage = false,
  history = {},
}) => {
  const dispatch = useContext(BundleContext)?.dispatch;
  const [selectedFarm, setSelectedFarm] = useState(null);
  const farms = data?.map((e) => e?.size?.toString?.()) || [];
  const isGlowRing = !_isEmpty(glowRingSlugs);
  const slugParam = useRouteMatch(`${paths.SHOP_PRODUCTS}/:slug`)?.params?.slug;
  const farmSizeParam = useRouteMatch(paths.FARMSTAND_SIZE)?.params?.farmstandSize;

  useEffect(() => {
    const farmIndex = farms?.findIndex((e) => e === farmSizeParam?.toString() || '');
    const slugIndex = glowRingSlugs?.findIndex((e) => e === slugParam);
    if (!isGlowRing && !_isEmpty(farms) && !!farmSizeParam) {
      if (farmIndex < 0) history.push(paths.NOT_FOUND);
      onClickFarmSize(farmIndex)();
    } else if (isGlowRing && !!slugParam && selectedFarm !== slugIndex) {
      onClickFarmSize(slugIndex)();
    }
    // eslint-disable-next-line
  }, [isGlowRing, farms]);

  const onClickFarmSize = (index) => () => {
    if (isBundlePage) dispatch(setSelectedBundleSize(data[index]?.size));
    if (imgSliderRef?.current?.onSelectFarmstand && data[index]?.id) imgSliderRef.current.onSelectFarmstand(data[index].id);
    onSelectFarmstand && onSelectFarmstand(index);
    setSelectedFarm(index);
  };

  const renderSizeButton = (button, index) => {
    const isSelected = selectedFarm === index;
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover'];
    const href = isGlowRing ? `${paths.SHOP_PRODUCTS}/${glowRingSlugs[index]}` : farmSizeParam ? paths.FARMSTAND : null;

    return (
      <SelectSizeButton
        key={index}
        modifiers={modifiers}
        href={href}
        label={button}
        dataNw={`farmstand-size-${button}`}
        onClick={onClickFarmSize(index)}
      />
    );
  };
  return (
    <FarmstandSizeStyled>
      <Text modifiers={['brandFont']} content={title} />
      <SizeButtonGroup>{farms.map(renderSizeButton)}</SizeButtonGroup>
      <LabelWrapper>
        {isGlowRing && (
          <Text modifiers={[!hasFarmstand && 'midGrayColor', 'brandFont']} isHMTL content={glowRingsNumberLabel + '\u2122'}></Text>
        )}
      </LabelWrapper>
    </FarmstandSizeStyled>
  );
};

FarmstandSize.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  glowRingSlugs: PropTypes.array,
  onSelectFarmstand: PropTypes.func,
  imgSliderRef: PropTypes.object,
  glowRingsNumberLabel: PropTypes.string,
  isGlowRing: PropTypes.bool,
  hasFarmstand: PropTypes.bool,
  isBundlePage: PropTypes.bool,
  history: PropTypes.object,
};

export default FarmstandSize;
