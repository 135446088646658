import styled from 'styled-components';
import breakPoints from 'theme-redesign/Media';

import { Icon } from 'ui-redesign/elements';

export const ReviewContentStyled = styled.div.attrs({
  className: 'ReviewContentStyled',
})`
  margin-top: 1rem;

  .RateStarsWrapper {
    justify-content: flex-start;
  }

  .Text {
    text-align: left;
    margin: 0.75rem 0;
  }

  .Image {
    width: 7rem;
    height: 7rem;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }

  @media ${breakPoints.large_break} {
    width: calc(100% - 16rem);
    margin-top: 0.5rem;
  }
`;

export const CustomerInfoContent = styled.div.attrs({
  className: 'CustomerInfoContent',
})`
  padding-top: 0.5rem;
  margin-left: 1.25rem;

  > .Text {
    font-size: 1.125rem;
    text-align: left;
  }

  > .Text + .Text {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.farmProject.dark_gray};
  }
`;

export const CustomerInfo = styled.div.attrs({
  className: 'CustomerInfo',
})`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${breakPoints.large_break} {
    width: 15rem;
    padding-right: 1rem;
  }
`;

export const AnswerNumberCta = styled.button.attrs({
  className: 'AnswerNumberCta',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 0.75rem;
  cursor: pointer;

  .Text {
    font-size: 0.75rem;
    margin: 0 0.25rem;
  }

  .Text + .Text {
    margin: 0;
  }

  .Icon {
    width: 1rem;
    height: 1rem;
    pointer-events: none;

    svg {
      width: 95%;
    }
  }
`;

export const Answer = styled.button.attrs({
  className: 'Answer',
})`
  border-left: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin: 0.75rem 0;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;

  .CustomerInfo {
    width: 90%;
  }

  > .Text {
    margin-top: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    padding-left: 1rem;
    margin-left: 2rem;
    width: 90%;

    > .Text {
      padding-left: 4rem;
    }
  }
`;

export const CustomerAvatar = styled.div.attrs({
  className: 'CustomerAvatar',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_1};
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  position: relative;

  .Text {
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .Icon:not(.Logo) {
    width: 1.25rem;
    height: 1.25rem;
    top: 2.4rem;
    left: 2.5rem;

    svg {
      width: 95%;

      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }

      path + path {
        fill: white;
      }
    }
  }
`;

export const Logo = styled(Icon).attrs({ className: 'Logo' })`
  width: 2rem;
  height: 2rem;
  left: 50%;
  top: 50%;
  svg {
    width: 90%;
  }
`;

export const ImageWrappersReview = styled.div.attrs({
  className: 'ImageWrappersReview',
})`
  display: flex; 
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  
  .Image {
    margin-right: 0.5rem;
  }
`;