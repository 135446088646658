import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { ContentSection } from 'ui-redesign/components';
import { Text, Title, Image } from 'ui-redesign/elements';

import { ContentWrapper, HowItIsMadeSection, HowItMadeList, HowItMadeItem } from './HowItsMade.styled';

const howItsMadeImageId = (size) => {
  switch (size) {
    case 'SM':
      return '42BfmWUQYgPlXEfzZ4fETy';
    case 'MD':
      return '1O1XsaCWJo2MMAPHry3vHq';
    default:
      return '2OhiASMnotlvWmDWpnmAM8';
  }
};

const Item = ({ percentages }) =>
  percentages.map((item) => {
    const id = _get(item, 'sys.id');
    const text = _get(item, 'fields.text');
    return (
      <HowItMadeItem key={id}>
        <Text as='span' content={text} />
      </HowItMadeItem>
    );
  });

const HowItsMade = ({ title, percentages, mediaBackground, size, isDesktop }) => (
  <HowItIsMadeSection>
    <ContentSection>
      <ContentWrapper>
        <Title as='h3' modifiers='lineHeightNormal' content={title} />
        <HowItMadeList>
          <Item percentages={percentages} />
        </HowItMadeList>
        <Image
          picture={_get(mediaBackground.filter((m) => _get(m, 'sys.id') === howItsMadeImageId(size))[0] || {}, 'fields.file.url')}
          nativeWidth={isDesktop ? 2000 : 970}
        />
      </ContentWrapper>
    </ContentSection>
  </HowItIsMadeSection>
);

HowItsMade.propTypes = {
  title: PropTypes.string,
  percentages: PropTypes.array,
  mediaBackground: PropTypes.array,
  size: PropTypes.string,
  isDesktop: PropTypes.bool,
};

export default HowItsMade;
