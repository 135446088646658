import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  FooterStyled,
  FooterContent,
  FooterList,
  ListsLinksWrappers,
  ContactInfo,
  Contact,
  GroupIcon,
  MailingForm,
  ConfirmationForm,
  FooterBottom,
  BottomInfo,
  Signature,
  Lists,
  LinkWrapper,
} from './Footer.styled';

import { ListLinks } from '../index';
import { Title, Link, Text, Icon, Input, ButtonText } from '../../elements/index';

import { ReactComponent as LetterIcon } from 'ui-redesign/elements/icon/assets/mail.svg';
import { ReactComponent as PhoneIcon } from 'ui-redesign/elements/icon/assets/phone.svg';
import { ReactComponent as FacebookIcon } from 'ui-redesign/elements/icon/assets/facebook.svg';
import { ReactComponent as YoutubeIcon } from 'ui-redesign/elements/icon/assets/youtube.svg';
import { ReactComponent as InstagramIcon } from 'ui-redesign/elements/icon/assets/instagram.svg';
import { ReactComponent as Logo } from 'ui-redesign/elements/icon/assets/LG_logo.svg';
import { ReactComponent as TFP } from 'ui-redesign/elements/icon/assets/TFP_logo.svg';
import { paths } from 'constants/index';
import emailSignup from 'utils/newsletter-api';

const Footer = () => {
  const { LGE_email_invalid, LGE_email_required, LGE_0 } = useSelector((state) => state.contentful.messages) || {};
  const [status, setStatus] = useState({});
  return (
    <FooterStyled>
      <FooterContent>
        <LinkWrapper to='/'>
          <Icon>
            <Logo />
          </Icon>
        </LinkWrapper>
        <Lists>
          <ListsLinksWrappers>
            <FooterList>
              <Title as='h5' modifiers='inverted' content='SERVICE' />
              <ListLinks>
                <Link content='FAQ' href={paths.FAQ} />
                <Link content='Terms & Conditions' href={paths.TERMS} />
                <Link content='Privacy Policy' href={paths.PRIVACY} />
                <Link content='Careers' href={`${paths.EXTERNAL_TFP}/careers`} target='_blank' />
                <Link content='Gift Cards' href={paths.GIFT_CARD} />
                <Link content='Refer A Friend' href={paths.faqPosts.REFERRAL} />
                <Link content='Virtual Consultation' href={paths.VIRTUAL_SHOPPING} />
              </ListLinks>
            </FooterList>
            <FooterList>
              <Title as='h5' modifiers='inverted' content='LEARN' />
              <ListLinks>
                <Link content='How It Works' href={paths.EXPERIENCE} />
                <Link content='The Farmstand' href={paths.FARMSTAND} />
                <Link content='Our Mission' href={paths.OUR_MISSION} />
                <Link content='Resources' href={paths.BLOG} />
                <Link content='Recipes' href={paths.RECIPES} />
                <Link content='Seedlings' href={paths.SEEDLINGS} />
                <Link content='Customer Reviews' href={paths.CUSTOMER_REVIEWS} />
              </ListLinks>
            </FooterList>
          </ListsLinksWrappers>
          <ContactInfo>
            <Title as='h5' modifiers='inverted' content='CONNECT' />
            <Contact>
              <LinkWrapper to={paths.CONTACT_US}>
                <Text modifiers={['inverted', 'brandFont']} content='Contact Us' />
              </LinkWrapper>
            </Contact>
            <Contact>
              <LinkWrapper as='a' href='mailto:help@lettucegrow.com'>
                <Text modifiers={['inverted', 'brandFont']} content='help@lettucegrow.com' />
                <Icon modifiers='inverted'>
                  <LetterIcon />
                </Icon>
              </LinkWrapper>
            </Contact>
            <Contact>
              <LinkWrapper as='a' href='tel:+15122344001'>
                <Text modifiers={['inverted', 'brandFont']} content='Call us at (512) 234-4001' />
                <Icon modifiers='inverted'>
                  <PhoneIcon />
                </Icon>
              </LinkWrapper>
            </Contact>
            <GroupIcon>
              <LinkWrapper as='a' href={paths.EXTERNAL_FACEBOOK} target='_blank'>
                <Icon modifiers='inverted'>
                  <FacebookIcon />
                </Icon>
              </LinkWrapper>
              <LinkWrapper as='a' href={paths.EXTERNAL_YOUTUBE} target='_blank'>
                <Icon modifiers='inverted'>
                  <YoutubeIcon />
                </Icon>
              </LinkWrapper>
              <LinkWrapper as='a' href={paths.EXTERNAL_INSTAGRAM} target='_blank'>
                <Icon modifiers='inverted'>
                  <InstagramIcon />
                </Icon>
              </LinkWrapper>
            </GroupIcon>
          </ContactInfo>
        </Lists>
        <MailingForm>
          <Title as='h5' modifiers='inverted' content={(status || {}).success || 'JOIN OUR MAILING LIST!'} />
          <Formik
            onSubmit={async (values, { setSubmitting }) => {
              const params = {
                email: values.email,
                formType: 'footerNewsletter',
              };
              // Add zip to params if it was filled in
              if (values.zipCode) params.zip = values.zipCode;

              const response = await emailSignup(params);
              if (((response || {}).data || {}).success === true) {
                setStatus({ success: 'Welcome to Our Family!' });
              } else {
                setStatus({ error: LGE_0 });
              }
              setSubmitting(false);
            }}
            initialValues={{ email: '', zipCode: '', terms: false }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(LGE_email_invalid).required(LGE_email_required),
            })}
          >
            {({ isSubmitting }) => (
              <Form>
                {!(status || {}).success && (
                  <>
                    <Field name='email' variant='standard' modifiers={['standard', 'brandForStandard']} label='E-mail:' component={Input} />
                    <Field
                      name='zipCode'
                      variant='standard'
                      modifiers={['standard', 'brandForStandard']}
                      label='Zip Code:'
                      component={Input}
                    />
                    <ConfirmationForm>
                      <ButtonText type='submit' label='SUBMIT' modifiers='underline' disabled={isSubmitting}></ButtonText>
                      {/* <Field name='terms' component={Checkbox} label='I agree with privacy policy.' /> */}
                    </ConfirmationForm>
                    {!!(status || {}).error && <Text content={(status || {}).error} as='span' modifiers={['inverted', 'error']} isHTML />}
                  </>
                )}
              </Form>
            )}
          </Formik>
        </MailingForm>
        <GroupIcon>
          <LinkWrapper as='a' href={paths.EXTERNAL_FACEBOOK} target='_blank'>
            <Icon modifiers='inverted'>
              <FacebookIcon />
            </Icon>
          </LinkWrapper>
          <LinkWrapper as='a' href={paths.EXTERNAL_YOUTUBE} target='_blank'>
            <Icon modifiers='inverted'>
              <YoutubeIcon />
            </Icon>
          </LinkWrapper>
          <LinkWrapper as='a' href={paths.EXTERNAL_INSTAGRAM} target='_blank'>
            <Icon modifiers='inverted'>
              <InstagramIcon />
            </Icon>
          </LinkWrapper>
        </GroupIcon>
        <FooterBottom>
          <BottomInfo>
            <Text modifiers='inverted' as='span' content={`Copyright Ⓒ ${new Date().getFullYear().toString()}, Lettuce Grow®`} />
            <Signature>
              <Text modifiers='inverted' as='span' content='An Initiative of:' />
              <LinkWrapper as='a' href={paths.EXTERNAL_TFP} target='_blank'>
                <Icon>
                  <TFP />
                </Icon>
              </LinkWrapper>
            </Signature>
          </BottomInfo>
          <LinkWrapper to='/'>
            <Icon>
              <Logo />
            </Icon>
          </LinkWrapper>
        </FooterBottom>
      </FooterContent>
    </FooterStyled>
  );
};

export default Footer;
