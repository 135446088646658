/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import _get from 'lodash/get';
import Slider from 'react-slick';

import { SquaredButton, Image, Text, Title } from 'ui-redesign/elements';
import { PressReviewSlider } from 'ui-redesign/components';
import GrowIn from './GrowIn';

import StatsTicker from 'components/sections/StatsTicker';
import { GrowSection, FarmStandTableSection, SeasonNumbersSection, ContentWrapper, NumbersList, NumberListItem } from './Home.styled';

import { HeroSection, ContentSection, SliderSection } from 'ui-redesign/components';
import PreSprouted from './PreSprouted';

const sections = {
  GROWING: '3KDY88i0R2WWgJLDutU7YM',
  HERO_SLIDER: '6NTyb3CGTYuhv5Dic8R1aV',
  BRANDS: 'ImssOjXCN4kLN3lnPuxxP',
  PRE_SPROUTED: 'fURedAq9ChuDtJPr77LRs',
  GROW_IN: '3q9GAMH3NHWhPZSmX035la',
  GROW10: '6w0LFYY7oIoQtdztG3ZFCU',
  FARM_TABLE: '4I3ls92Q9zAxvRm25LWu62',
  SEASON_NUMBER: '18KnJM7VDtqMxRABjdS2WN',
  SLIDE: '743GnbUmH77iWrHvJ3sHGm',
};
const blocksClassMap = {
  '1nnx0xc7ixsh2k9ltry4ta': 'GlowRings',
  '93cjpg8lgggjvn3c6dw3v': 'WinterSeedlings',
};
const DESKTOP_IMAGE_SIZE = 529;
const MOBILE_IMAGE_SIZE = 313;

const NumbersListSection = ({ numFruitsVeggies, numGallonsOfWater, numMilkJugs, fruitsVeggiesText, gallonsOfWaterText, milkJugsText }) => (
  <NumberListItem>
    <StatsTicker
      numMilkJugs={numMilkJugs}
      numGallonsOfWater={numGallonsOfWater}
      numFruitsVeggies={numFruitsVeggies}
      milkJugsText={milkJugsText}
      gallonsOfWaterText={gallonsOfWaterText}
      fruitsVeggiesText={fruitsVeggiesText}
    />
  </NumberListItem>
);

const getBackgroundImage = (mediaList, size) => {
  let image;
  switch (size) {
    case 'SM':
      image = mediaList[2] || mediaList[1] || mediaList[0];
      break;
    case 'MD':
      image = mediaList[1] || mediaList[0];
      break;
    default:
      image = mediaList[0];
      break;
  }
  return image?.fields?.file?.url;
};

const sectionsRenderes = {
  [sections.HERO_SLIDER]: (props) => {
    const { percentages, isDesktop, size } = props;

    return (
      <Slider arrows={false} dots={true} className='HomeHeroSlider' key='heroSlider' lazyLoad='ondemand'>
        {percentages.map((block) => {
          const backgroundImage = getBackgroundImage(block.fields.mediaBackground, size);
          const buttonLabel = _get(block.fields.callToAction, 'fields.linkText');
          const buttonHref = _get(block.fields.callToAction, 'fields.linkUrl');
          return (
            <HeroSection
              key={block.sys.id}
              heroTitle={block.fields.title}
              imagebackground={backgroundImage}
              imageRouteLink={buttonHref}
              shouldResizeBackground
              isDesktop={isDesktop}
              className={blocksClassMap[block.sys.id?.toLowerCase()]}
              heroText={block.fields.body}
            >
              <SquaredButton as='a' label={buttonLabel?.toUpperCase?.()} href={buttonHref} />
            </HeroSection>
          );
        })}
      </Slider>
    );
  },
  [sections.BRANDS]: ({ body, percentages }) => <PressReviewSlider key='pressSlider' body={body} brands={percentages} showCallout />,
  [sections.PRE_SPROUTED]: ({ title, body, mediaContent, callToAction, isDesktop }) => (
    <PreSprouted
      key={sections.PRE_SPROUTED}
      title={title}
      body={body}
      mediaContent={mediaContent}
      callToAction={callToAction}
      desktopImgSize={DESKTOP_IMAGE_SIZE}
      mobileImgSize={MOBILE_IMAGE_SIZE}
      isDesktop={isDesktop}
    />
  ),
  [sections.GROW_IN]: ({ title, percentages }) => <GrowIn key={sections.GROW_IN} title={title} percentages={percentages} />,
  [sections.GROW10]: ({ title, body, mediaContent, callToAction, isDesktop }) => (
    <GrowSection key={sections.GROW10}>
      <ContentSection>
        <Image
          picture={_get(mediaContent, 'fields.file.url')}
          imageRouteLink={_get(callToAction, 'fields.linkUrl')}
          nativeWidth={isDesktop ? 2000 : 970}
        />
        <ContentWrapper>
          <Title content={title} as='h3' modifiers={['inverted', 'secondarySmall']} />
          <Text content={body} modifiers='inverted' />
          <SquaredButton
            as='a'
            label={_get(callToAction, 'fields.linkText') || _get(callToAction, 'fields.text')}
            href={_get(callToAction, 'fields.linkUrl') || _get(callToAction, 'fields.url')}
            modifiers='secondary'
          />
        </ContentWrapper>
      </ContentSection>
    </GrowSection>
  ),
  [sections.FARM_TABLE]: ({ title, body, mediaContent, callToAction, isDesktop }) => (
    <FarmStandTableSection key={sections.FARM_TABLE}>
      <ContentSection modifiers={['leftText']} animation='crossFade'>
        <Image
          picture={_get(mediaContent, 'fields.file.url')}
          imageRouteLink={_get(callToAction, 'fields.linkUrl')}
          nativeWidth={isDesktop ? DESKTOP_IMAGE_SIZE : MOBILE_IMAGE_SIZE}
        />
        <ContentWrapper>
          <Title content={title} modifiers='secondarySmall' as='h3' />
          <Text content={body} />
          <SquaredButton
            as='a'
            label={_get(callToAction, 'fields.linkText') || _get(callToAction, 'fields.text')}
            href={_get(callToAction, 'fields.linkUrl') || _get(callToAction, 'fields.url')}
          />
        </ContentWrapper>
      </ContentSection>
    </FarmStandTableSection>
  ),
  [sections.SEASON_NUMBER]: ({
    title,
    body,
    milkJugsBody,
    numMilkJugs,
    gallonsOfWaterText,
    numGallonsOfWater,
    fruitsAndVeggiesText,
    numFruitsVeggies,
    callToAction,
  }) => {
    return (
      <SeasonNumbersSection key={sections.SEASON_NUMBER}>
        <ContentSection>
          <ContentWrapper>
            <Title content={title} modifiers='secondarySmall' as='h3' />
            <Text content={body} />
            <SquaredButton
              as='a'
              label={_get(callToAction, 'fields.linkText') || _get(callToAction, 'fields.text')}
              href={_get(callToAction, 'fields.linkUrl') || _get(callToAction, 'fields.url')}
            />
          </ContentWrapper>
          <NumbersList>
            <NumbersListSection
              numMilkJugs={numMilkJugs}
              numGallonsOfWater={numGallonsOfWater}
              numFruitsVeggies={numFruitsVeggies}
              milkJugsText={milkJugsBody}
              gallonsOfWaterText={gallonsOfWaterText}
              fruitsVeggiesText={fruitsAndVeggiesText}
            />
          </NumbersList>
        </ContentSection>
      </SeasonNumbersSection>
    );
  },
  [sections.SLIDE]: ({ isDesktop, contentBlock, title, body }) => (
    <SliderSection key={sections.SLIDE} isDesktop={isDesktop} contentBlock={contentBlock} title={title} body={body} />
  ),
};

export default sectionsRenderes;
