import { createSlice, createSelector } from '@reduxjs/toolkit';
import { AXIOS } from 'redux/middleware/apiMiddleware';
import { getSeedlingAvailableDateObject, getDaysBetween } from 'utils/date-utils';
import { getFarmstandsInCart } from './cartCheckout/cartCheckout';
import itemAvailabilities from 'constants/itemAvailabilities';
import shopCategories from 'constants/shopCategories';
import { HALO_LIGHTS_SKUS } from 'constants/sku';

const { AVAILABLE, OUT_OF_SEASON } = itemAvailabilities;

/**
 * * catalog - Redux Reducer
 *
 * catalog - Shopable products from GCS
 *
 */

// reducer, action types, action creators all in 1 createSlice

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    seedlings: {},
    farmstands: {},
    supplies: {},
    bundles: {},
    isCatalogFetched: false,
    hasCatalogFetchError: false,
    packRecommendation: undefined,
  },
  reducers: {
    setCatalog(state, { payload }) {
      state.seedlings = payload.plantTypes;
      state.bundles = Object.keys(payload.buyables)
        .filter((item) => payload.buyables[item].category === shopCategories.BUNDLES)
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: payload.buyables[key],
          };
        }, {});
      state.farmstands = payload.deviceTypes;
      state.packRecommendation = Object.assign({}, payload.myPackRecommendation, { environment: payload.environment });
      state.supplies = payload.buyables;
      state.isCatalogFetched = true;
      state.hasCatalogFetchError = false;
    },
    setCatalogFetchError(state) {
      state.isCatalogFetched = false;
      state.hasCatalogFetchError = true;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = catalogSlice;

// Extract and export action creators from slice by name
export const { setCatalog, setCatalogFetchError } = actions;

// Export the reducer as the default
export default reducer;

// Selector for getting item
export const getItemById = createSelector([(state) => state.catalog, (_, sku) => sku], (catalog, sku) => {
  // Catalog not loaded yet. Return null
  if (!catalog) return null;
  // Try to get sku in farmstands
  // Return foundItem. Will be a farmstand, seedling, supplies, buyable or Null.
  return catalog.farmstands[sku] || catalog.seedlings[sku] || catalog.supplies[sku] || catalog.bundles[sku];
});

export const getItemByVariantId = createSelector([(state) => state.catalog, (_, variantId) => variantId], (catalog, variantId) => {
  if (!catalog) return null;
  return (
    Object.values(catalog.farmstands).find((farmstand) => farmstand.shopifyVariantId === variantId) ||
    Object.values(catalog.seedlings).find((seedling) => seedling.shopifyVariantId === variantId) ||
    Object.values(catalog.supplies).find((supply) => supply.shopifyVariantId === variantId) ||
    Object.values(catalog.bundles).find((bundle) => bundle.shopifyVariantId === variantId)
  );
});

// Selector for getting seedling categories
export const getSeedlingCategories = createSelector([(state) => state.catalog], (catalog) => {
  const cats = Object.keys(catalog.seedlings).reduce((acc, curr) => {
    if (!acc.includes(catalog.seedlings[curr].category)) acc.push(catalog.seedlings[curr].category);
    return acc;
  }, []);
  return cats;
});

export const getSeedlingSubcategories = createSelector([(state) => state.catalog], (catalog) => {
  const cats = Object.keys(catalog.seedlings).reduce((acc, curr) => {
    if (!acc.includes(catalog.seedlings[curr].subcategory)) acc.push(catalog.seedlings[curr].subcategory);
    return acc;
  }, []);
  return cats;
});

// Selector for getting all PlantBundles
export const getPlantBundles = createSelector(
  [(state) => getFarmstandsInCart(state), (state) => state.appSettings.outSeedsBuyableIn, (state) => state.catalog.bundles],
  (farmsInCart, buyableDays, catalogBundles) => {
    const bundles = Object.values(catalogBundles);
    return farmsInCart?.length ? mapSeedsWithinBuyableDaysToAvailable(bundles, buyableDays) : mapSeedsFutureShippingToAvailable(bundles);
  }
);

// Selector for getting seedling bundles of a category in the buyables catalog
export const getBundlesBySubcategory = createSelector([(state) => state.catalog, (_, category) => category], (catalog, category) => {
  const bundles = Object.values(catalog.bundles);
  if (!category || category === shopCategories.BUNDLES) {
    return bundles;
  }
  return bundles.filter((item) => item.subcategory && item.subcategory.split(',').includes(category));
});

// Selector for getting seedlings by category
export const getSeedlingsByCategory = createSelector([(state) => state.catalog, (_, category) => category], (catalog, category) => {
  const seedlings = Object.keys(catalog.seedlings).reduce((acc, curr) => {
    // if category is empty string then push all seedlings and return
    if (category === '') {
      acc.push(catalog.seedlings[curr]);
      return acc;
    }
    if (catalog.seedlings[curr].subcategory === category) acc.push(catalog.seedlings[curr]);
    return acc;
  }, []);
  return seedlings;
});

export const getFarmstands = createSelector([(state) => state.catalog], ({ farmstands }) => {
  return Object.values(farmstands)?.sort((a, b) => a.priceCents - b.priceCents) || [];
});

export const getGlowRings = createSelector([(state) => state.catalog], ({ supplies }) => {
  return HALO_LIGHTS_SKUS.map((glowSku) => supplies[glowSku]).sort((a, b) => a.priceCents - b.priceCents) || [];
});

export const getSupplies = createSelector([(state) => state.catalog], ({ supplies }) => supplies);

export const getSuppliesWithoutBundles = createSelector([(state) => state.catalog], ({ supplies }) => {
  const bundlesCategories = [shopCategories.BUNDLES, shopCategories.CUSTOM_BUNDLES];
  return Object.values(supplies)
    .filter((e) => !bundlesCategories.includes(e.category))
    .reduce((acc, e) => ({ ...acc, [e.sku]: e }), {});
});

/**
 * Return all seedlings from a specified category that are either AVAILABLE,
 * OR does have inSeasonDate not null OR inStockDate not null.
 * Used to filter out the NEXT SEASON seedlings.
 */
export const getNonNextSeasonSeedlingsBySubcategory = (state, category) => {
  return getSeedlingsByCategory(state, category).filter((seed) => {
    return seed.availability === AVAILABLE || seed.inStockDate || seed.inSeasonDate;
  });
};

export const getActiveSeedlingsBySubcategory = (state, category) => {
  return getSeedlingsByCategory(state, category).filter((seed) => seed.availability === AVAILABLE);
};

/**
 * Seedlings Selector for the Shop page.
 * If there is no Farmstands in the Cart, then it returns only the getNonNextSeasonSeedlingsBySubcategory selector.
 * Otherwise, it returns the getNonNextSeasonSeedlingsBySubcategory mapped with mapSeedsWithinBuyableDaysToAvailable ->
 * treat OUT_OF_SEASON seedlings with availabilityDate within the buyableDate as AVAILABLE
 */
export const getShopSeedlingsBySubcategory = (state, category) => {
  const { outSeedsBuyableIn: buyableDays } = state.appSettings;
  const hasFarmstandInCart = (getFarmstandsInCart(state) || []).length > 0;
  const filteredSeeds = getNonNextSeasonSeedlingsBySubcategory(state, category);

  return hasFarmstandInCart
    ? mapSeedsWithinBuyableDaysToAvailable(filteredSeeds, buyableDays)
    : mapSeedsFutureShippingToAvailable(filteredSeeds);
};

/**
 * Return all seedlings from a specified category that are either AVAILABLE,
 * OR OUT_OF_SEASON but are inStock,
 * OR OUT_OF_SEASON in the case that their inStockDate is NOT NULL
 * and (new Date() - inStockDate) <= buyableDays.
 * Note: buyableDays is a business rule time in days.
 * availabilityDate is the Date from the maximum of (seed.inSeasonDate and seed.inStockDate),
 * if inSeasonDate and inStockDate are both null, then availabilityDate will be undefined.
 *
 * For exclusive use on FYF page!
 */
export const getActiveOrNearToComeSeedlingsBySubcategoryFYF = (state, category) => {
  const buyableDays = state.appSettings.outSeedsBuyableIn;
  const reservation = state.reservation;
  const filteredSeeds = getSeedlingsByCategory(state, category).filter((seed) => {
    const isReserved = reservation.expiry > Date.now() && reservation.items.find((resItem) => resItem.sku === seed.sku);
    const isAvailable = seed.availability === AVAILABLE;
    const isOutSeason = seed.availability === OUT_OF_SEASON;
    const isBuyableSeedNotSeason = isOutSeason && !seed.inStockDate && !!seed.inSeasonDate;
    const isWithinBuyableDate =
      isOutSeason && seed.inStockDate && buyableDays ? getDaysBetween(new Date(), new Date(seed.inStockDate)) <= buyableDays : false;
    const isSeedInFutureShippingBuyable = isOutSeason && !!seed.shipsOnDate;

    return isAvailable || isBuyableSeedNotSeason || isWithinBuyableDate || isSeedInFutureShippingBuyable || isReserved;
  });

  return mapSeedsWithinBuyableDaysToAvailable(filteredSeeds, buyableDays);
};

/**
 *  Map: if the Seedling availabilityDate (major date between inStock and inSeason) is within the buyableDate,
 * then its availability is treated as AVAILABLE!
 * if inStockDate and inSeasonDate are within buyableDate, then these fields are set to NULL.
 */

const mapSeedsWithinBuyableDaysToAvailable = (seeds, buyableDays) =>
  seeds.map((seed) => {
    const isOutSeason = seed.availability === OUT_OF_SEASON;
    const availabilityDate = getSeedlingAvailableDateObject(seed);
    const isStockWithinBuyable =
      isOutSeason && seed.inStockDate && buyableDays ? getDaysBetween(new Date(), new Date(seed.inStockDate)) <= buyableDays : false;
    const isSeasonWithinBuyable =
      isOutSeason && seed.inSeasonDate && buyableDays ? getDaysBetween(new Date(), new Date(seed.inSeasonDate)) <= buyableDays : false;
    const isWithinBuyableDate =
      isOutSeason && availabilityDate && buyableDays ? getDaysBetween(new Date(), availabilityDate) <= buyableDays : false;
    const isSeedInFutureShippingBuyable = isOutSeason && !!seed.shipsOnDate;

    return {
      ...seed,
      availability: isWithinBuyableDate || isSeedInFutureShippingBuyable ? AVAILABLE : seed.availability,
      inStockDate: isStockWithinBuyable ? null : seed.inStockDate,
      inSeasonDate: isSeasonWithinBuyable ? null : seed.inSeasonDate,
      shipsOnDate: isWithinBuyableDate ? null : seed.shipsOnDate,
    };
  });

/**
 *  Map: if the seed is out of stock and has a shipping date,
 * then its availability is treated as AVAILABLE!
 */

const mapSeedsFutureShippingToAvailable = (seeds) =>
  seeds.map((seed) => {
    const hasShipDate = seed.availability === OUT_OF_SEASON && !!seed.shipsOnDate;
    const isSeedInFutureShippingBuyable = hasShipDate;

    return {
      ...seed,
      availability: isSeedInFutureShippingBuyable ? AVAILABLE : seed.availability,
    };
  });

/**
 * Return all seedlings from a specified category that are not AVAILABLE,
 * AND are not OUT_OF_SEASON in the case they are inStock,
 * AND are not OUT_OF_SEASON in the case that their inStockDate is NOT NULL
 * and (new Date() - inStockDate) <= buyableDays.
 * Note: buyableDays is a business rule time in days.
 *
 * For exclusive use on FYF page!
 */
export const getInactiveOrFarToComeSeedlingsBySubcategoryFYF = (state, category) => {
  const buyableDays = state.appSettings.outSeedsBuyableIn;
  const reservation = state.reservation;
  return getSeedlingsByCategory(state, category).filter((seed) => {
    const isReserved = reservation.expiry > Date.now() && reservation.items.find((resItem) => resItem.sku === seed.sku);
    const isUnavailable = seed.availability !== AVAILABLE;
    const isOutSeason = seed.availability === OUT_OF_SEASON;
    const isBuyableSeedNotSeason = isOutSeason && !seed.inStockDate && !!seed.inSeasonDate;
    const isWithinBuyableDate =
      isOutSeason && seed.inStockDate && buyableDays ? getDaysBetween(new Date(), new Date(seed.inStockDate)) <= buyableDays : false;
    const isSeedInFutureShippingBuyable = isOutSeason && !!seed.shipsOnDate;

    return isUnavailable && !isBuyableSeedNotSeason && !isWithinBuyableDate && !isReserved && !isSeedInFutureShippingBuyable;
  });
};

export const getCustomPlantBundlesBySize = createSelector([(state) => state.catalog, (_, size) => size], (catalog, size) => {
  return Object.values(catalog.supplies)?.filter?.((e) => e.category === shopCategories.CUSTOM_BUNDLES && e.plantCount === size) || [];
});
/**
 * * fetchCatalog - Async Action Creator to hit catalog BE endpoint
 *
 */ export const fetchCatalog = (zip, email, isLoggedIn, isZipOverride = false, environment = 'DEFAULT') => {
  let params = `/${environment}`;

  if (zip && email) {
    params = `${params}/${zip}/${email}`;
  } else if (zip) {
    params = `${params}/${zip}`;
  }

  const url = !isZipOverride && isLoggedIn ? '/app/lgcom/v2/catalog/' + environment : '/app/public/v2/catalog' + params;
  return {
    type: AXIOS,
    payload: {
      url,
      method: 'GET',
      onSuccess: setCatalog,
      onFailure: setCatalogFetchError,
    },
  };
};
