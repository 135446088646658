import styled from 'styled-components/macro';
import breakPoints from 'theme-redesign/Media';

import { Icon } from 'ui-redesign/elements';

export const modifiers = {
  filled: ({ theme }) => ({
    styles: `
      .Icon {
        svg {
          path {
            fill: ${theme.farmProject.green};
          }
        }
      }
    `,
  }),
};

export const ReviewsAndQuestionsStyled = styled.div.attrs({ className: 'ReviewsAndQuestions' })`
  background: ${({ theme }) => theme.farmProject.gray_3};
  > .Title {
    text-align: center;
    padding-top: 4rem;
  }
`;

export const RateButtonGroup = styled.div.attrs({
  className: 'RateButtonGroup',
})`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 20rem;
  margin: 2rem auto;

  .Button {
    width: calc(50% - 0.5rem);
  }
`;

export const ReviewsSection = styled.div.attrs({
  className: 'ReviewsSection',
})``;

export const ReviewToggleWrapper = styled.div.attrs({
  className: 'ReviewToggleWrapper',
})`
  padding: 1rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  display: flex;

  .ButtonText {
    margin-right: 2rem;
  }
`;

export const ReviewListWrapper = styled.div.attrs({
  className: 'ReviewListWrapper',
})`
  > .Button {
    margin: 2rem auto;
  }

  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        .ModalInner {
          max-width: unset;

          .Text:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const ReviewListHeader = styled.div.attrs({
  className: 'ReviewListHeader',
})`
  padding: 1rem 0;
  margin-bottom: 1rem;

  .Text {
    text-align: left;
  }
`;

export const ReviewList = styled.ul.attrs({
  className: 'ReviewList',
})``;

export const ReviewItem = styled.li.attrs({
  className: 'ReviewItem',
})`
  padding-bottom: 2rem;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin-bottom: 2rem;
`;

export const Review = styled.div.attrs({
  className: 'Review',
})`
  @media ${breakPoints.large_break} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const CustomerInfo = styled.div.attrs({
  className: 'CustomerInfo',
})`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${breakPoints.large_break} {
    width: 15rem;
    padding-right: 1rem;
  }
`;

export const CustomerAvatar = styled.div.attrs({
  className: 'CustomerAvatar',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_1};
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  position: relative;

  .Text {
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .Icon {
    position: absolute;
    transform: translate(-50%, -50%);
  }

  .Icon:not(.Logo) {
    width: 1.25rem;
    height: 1.25rem;
    top: 2.4rem;
    left: 2.5rem;

    svg {
      width: 95%;

      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }

      path + path {
        fill: white;
      }
    }
  }
`;

export const Logo = styled(Icon).attrs({ className: 'Logo' })`
  width: 2rem;
  height: 2rem;
  left: 50%;
  top: 50%;
  svg {
    width: 90%;
  }
`;

export const CustomerInfoContent = styled.div.attrs({
  className: 'CustomerInfoContent',
})`
  padding-top: 0.5rem;
  margin-left: 1.25rem;

  > .Text {
    font-size: 1.125rem;
    text-align: left;
  }

  > .Text + .Text {
    font-size: 0.875rem;
    margin-top: 0.25rem;
    color: ${({ theme }) => theme.farmProject.dark_gray};
  }
`;

export const ProductInfo = styled.div.attrs({
  className: 'ProductInfo',
})`
  padding-top: 0.5rem;

  .Text {
    text-align: left;
    margin: 0.125rem 0;
  }
`;

export const QuestionFormStyled = styled.div.attrs({
  className: 'QuestionForm',
})`
  .Title {
    font-size: 1.5rem;
  }

  .InputStyled {
    margin: 0;
    margin-bottom: 1rem;
  }

  .SelectStyled {
    max-width: 18rem;
    margin: 0 auto 2rem;
  }

  .Button {
    width: 100%;
    max-width: 15rem;
    margin: auto;
    margin-top: 2rem;
  }

  @media ${breakPoints.large_break} {
    form {
      > .InputStyled {
        div {
          max-width: 38rem;
          margin: auto;
        }
      }
    }
    .SelectStyled {
      max-width: 38rem;
    }
  }
`;

export const ReviewFormStyled = styled.div.attrs({
  className: 'ReviewForm',
})`
  .Title {
    font-size: 1.5rem;
  }

  .Text,
  .RateStarsWrapper {
    text-align: left;
    max-width: 18rem;
    margin: 0 auto;
  }
  .RateStarsWrapper {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  .InputStyled {
    margin: 0;
    margin-bottom: 1rem;
  }

  .TextArea {
    max-width: 18rem;
    margin: 0 auto 1rem;
    padding-top: 1.8rem;
  }

  .SelectStyled {
    max-width: 18rem;
    margin: 0 auto 2rem;
  }

  .Button {
    width: 100%;
    max-width: 15rem;
    margin: auto;
    margin-top: 2rem;
  }

  @media ${breakPoints.large_break} {
    form {
      > .InputStyled div,
      .TextArea {
        max-width: 38rem;
      }
    }
    .Text,
    .SelectStyled,
    .RateStarsWrapper {
      max-width: 38rem;
    }
  }
`;

export const NameEmail = styled.div.attrs({
  className: 'NameEmail',
})`
  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    .InputStyled {
      width: 20rem;
    }
  }
`;

export const QuestionListWrapper = styled.div.attrs({
  className: 'QuestionListWrapper',
})``;

export const QuestionList = styled.ul.attrs({
  className: 'QuestionList',
})`
  margin: 0;
  padding: 0;

  // no questions yet button
  .Button {
    margin: 1rem auto;
  }
`;

export const QuestionItem = styled.li.attrs({
  className: 'QuestionItem',
})`
  padding-bottom: 2rem;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin-bottom: 2rem;
`;

export const Question = styled.div.attrs({
  className: 'Question',
})`
  .ReviewFooterStyled {
    margin-top: 0.5rem;
  }
`;

export const QuestionInner = styled.div.attrs({
  className: 'QuestionInner',
})`
  padding-left: 4.1rem;
  margin-top: 0.75rem;

  .Text {
    text-align: left;
  }
`;

export const AnswerNumberCta = styled.button.attrs({
  className: 'AnswerNumberCta',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0 0.75rem;
  cursor: pointer;

  .Text {
    font-size: 0.75rem;
    margin: 0 0.25rem;
  }

  .Text + .Text {
    margin: 0;
  }

  .Icon {
    width: 1rem;
    height: 1rem;
    pointer-events: none;

    svg {
      width: 95%;
    }
  }
`;

export const Answer = styled.button.attrs({
  className: 'Answer',
})`
  border-left: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  margin: 0.75rem 0;
  width: 100%;
  padding-left: 1rem;
  box-sizing: border-box;

  .CustomerInfo {
    width: 90%;
  }

  > .Text {
    margin-top: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    padding-left: 1rem;
    margin-left: 2rem;
    width: 90%;

    > .Text {
      padding-left: 4rem;
    }
  }
`;
