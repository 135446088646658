const dbFormatPhone = input => {
  if (!input) return null;
  return '+1' + input.replace(/\D/g, '');
};
const prettyFormatPhone = input => {
  if (!input) return null;
  let cleanInput = input.replace(/-/g, '');
  return '(' + cleanInput.slice(2, 5) + ') ' + cleanInput.slice(5, 8) + '-' + cleanInput.slice(8);
};
const rawFormatPhone = input => {
  if (!input) return null;
  if (input.startsWith('+1')) {
    return input.slice(2);
  } else {
    return input;
  }
};

export { dbFormatPhone, prettyFormatPhone, rawFormatPhone };
