const contactFormSubjects = {
  GROWING: 'GROWING',
  FARMSTAND: 'FARMSTAND',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY',
  GENERAL: 'GENERAL',
  DEMO: 'SCHEDULE A DEMO',
};

export default contactFormSubjects;
