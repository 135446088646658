import styled from 'styled-components/macro';

const LoadingStyled = styled.section.attrs({ className: 'Loading' })`
  height: ${props => (props.isFullHeight ? '100vh' : props.noImage ? 'auto' : '150px')};
  text-align: center;
  background: ${({ theme, isFullHeight }) => (isFullHeight ? theme.farmProject.gray_3 : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  .Image {
    margin-bottom: 10vh;
  }
  img {
    width: ${({ isFullHeight }) => (isFullHeight ? '150px' : '75px')};
    height: auto;
  }
`;

export const LoadIconWrapper = styled.div.attrs({
  className: 'LoadIconWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 7.5rem;
  height: 7.5rem;
  
  .Icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 60px;
      height: 60px;
    }
  }

  * {
    transition: all 0.3s;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoaderCircle = styled.div.attrs({
  className: 'LoaderCircle',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(232, 232, 227, 1);
`;

export const LoaderLineMask = styled.div.attrs({
  className: 'LoaderLineMask',
})`
  height: 120px;
  overflow: hidden;
  mask-image: linear-gradient(0deg,rgba(0,0,0,1) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 50%,rgba(0,0,0,0)100%);
  animation: rotate 1.2s infinite linear;
`;

export const LoaderLine = styled.div.attrs({
  className: 'LoaderLine',
})`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(76, 148, 98, 1);
`;

export default LoadingStyled;
