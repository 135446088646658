import React from 'react';
import PropTypes from 'prop-types';

import { TextTooltipWrapperStyled } from './TextTooltipWrapper.styled';
import { Text, Tooltip } from 'ui-redesign/elements';

const TextTooltipWrapper = ({ label, isLabelHTML, tooltipText, labelModifiers, tooltipModifiers }) => {
  return (
    <TextTooltipWrapperStyled>
      <Text isHTML={isLabelHTML} content={label} modifiers={labelModifiers} />
      {tooltipText && <Tooltip iconModifiers={tooltipModifiers} text={tooltipText} />}
    </TextTooltipWrapperStyled>
  );
};

TextTooltipWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  isLabelHTML: PropTypes.bool,
  tooltipText: PropTypes.string,
  labelModifiers: PropTypes.any,
  tooltipModifiers: PropTypes.any,
};

TextTooltipWrapper.defaultProps = {
  tooltipText: null,
  isLabelHTML: false,
  labelModifiers: 'brandColor',
  tooltipModifiers: 'invertedStroke',
};

export default TextTooltipWrapper;
