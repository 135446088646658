import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

import { ButtonText, SquaredButton, Title } from 'ui-redesign/elements';
import { ContentSection } from 'ui-redesign/components';
import QuestionForm from './components/QuestionForm';
import ReviewForm from './components/ReviewForm';
import ReviewListComponent from './components/ReviewsList';
import QuestionListComponent from './components/QuestionList';
import {
  RateButtonGroup,
  ReviewsSection,
  ReviewToggleWrapper,
  ReviewListWrapper,
  QuestionListWrapper,
  ReviewsAndQuestionsStyled,
} from './ReviewsAndQuestions.styled';

import scrollIntoView from 'utils/scrollIntoView';
import { productMap } from 'redux/userGenerated/userGenerated';
import { useContent } from 'utils/hooks';
import { REVIEWS_PAGE } from 'constants/contentful';

const ReviewsAndQuestions = ({ productId }) => {
  const reviewsContent = useContent(REVIEWS_PAGE);
  const [areQuestionsVisible, setAreQuestionsVisible] = useState(false);
  const [visibleForm, setVisibleForm] = useState('');
  const history = useHistory();

  const scrollRef = useCallback(
    (node) => {
      if (node && history.location) {
        scrollIntoView(history.location, node);
      }
    },
    [history.location]
  );

  const containerRef = useCallback(
    (node) => {
      if (node && history.location) {
        scrollIntoView(history.location, node);
      }
    },
    [history.location]
  );

  const handleTabChange = () => {
    setAreQuestionsVisible(!areQuestionsVisible);
  };
  const toggleVisibleForm = (type) => () => {
    if (type) {
      history.push(`${history.location.pathname}#review-form`); // needed to trigger scroll into view
    } else {
      // form submitted, close form section
      history.push(history.location.pathname);
    }
    setVisibleForm(type);
  };
  return (
    <ReviewsAndQuestionsStyled ref={containerRef} id='reviews-and-questions'>
      {!!productId && <Title modifiers='secondarySmall' content={reviewsContent?.[0]?.fields?.sections?.[0]?.fields?.title} />}
      <ContentSection>
        <RateButtonGroup>
          <SquaredButton label='WRITE A REVIEW' onClick={toggleVisibleForm('review')} />
          <SquaredButton label='ASK A QUESTION' onClick={toggleVisibleForm('question')} />
        </RateButtonGroup>
      </ContentSection>
      {!!visibleForm && (
        <ContentSection>
          <div ref={scrollRef} id='review-form'>
            <QuestionForm isVisible={visibleForm === 'question'} onSubmit={toggleVisibleForm('')} productId={productId} />
            <ReviewForm isVisible={visibleForm === 'review'} onSubmit={toggleVisibleForm('')} productId={productId} />
          </div>
        </ContentSection>
      )}
      <ContentSection>
        <ReviewsSection>
          <ReviewToggleWrapper>
            <ButtonText modifiers={['secondary', !areQuestionsVisible && 'underline']} label='Reviews' onClick={handleTabChange} />
            <ButtonText modifiers={['secondary', areQuestionsVisible && 'underline']} label='Questions' onClick={handleTabChange} />
          </ReviewToggleWrapper>
          <ReviewListWrapper>
            {areQuestionsVisible && (
              <QuestionListWrapper>
                <QuestionListComponent openQuestionForm={toggleVisibleForm('question')} productId={productId} />
              </QuestionListWrapper>
            )}
            {!areQuestionsVisible && (
              <ReviewListComponent openReviewForm={toggleVisibleForm('review')} history={history} productId={productId} />
            )}
          </ReviewListWrapper>
        </ReviewsSection>
      </ContentSection>
    </ReviewsAndQuestionsStyled>
  );
};

ReviewsAndQuestions.propTypes = {
  productId: PropTypes.oneOf(Object.keys(productMap)),
};

export default ReviewsAndQuestions;
