import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { isEmpty as _isEmpty, findIndex as _find } from 'lodash';

import { Image } from 'ui-redesign/elements';
import { PillLabel } from 'ui-redesign/components';

import { ProductHeroSliderStyled, HeroSliderInner, ProductSlideStyled } from './FarmCarouselMobile.styled';

const sliderConfig = {
  dots: true,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const FarmCarouselMobile = forwardRef(({ data = [], promo }, ref) => {
  const sliderRef = useRef();
  const isDataEmpty = _isEmpty(data);
  const [hasImageSku, setHasImageSku] = useState(!!data?.[0]?.description);

  useImperativeHandle(ref, () => ({
    onSelectFarmstand: (sku) => {
      const farmImgIndex = _find(data, { description: sku });
      if (((farmImgIndex >= 0 && (sliderRef || {}).current) || {}).slickGoTo) {
        sliderRef.current.slickGoTo(farmImgIndex, true);
      }
    },
  }));

  const renderSlide = (slideItem, index) => {
    const image = slideItem?.file?.url || '';

    if (!image) return null;
    return (
      <ProductSlideStyled key={index}>
        <Image picture={image} nativeWidth={550} />
      </ProductSlideStyled>
    );
  };

  const onSlideIndexChange = (_, newIndex) => setHasImageSku(!!data?.[newIndex]?.description);

  if (isDataEmpty) return null;

  return (
    <ProductHeroSliderStyled>
      {!!promo && hasImageSku && <PillLabel content={promo} />}
      <HeroSliderInner>
        <Slider ref={sliderRef} beforeChange={onSlideIndexChange} {...sliderConfig}>
          {data.map(renderSlide)}
        </Slider>
      </HeroSliderInner>
    </ProductHeroSliderStyled>
  );
});

FarmCarouselMobile.displayName = 'FarmCarouselMobile';

FarmCarouselMobile.propTypes = {
  data: PropTypes.array,
  promo: PropTypes.string,
};

export default FarmCarouselMobile;
