import { environment } from 'utils/envConfig';

export default function logError(error) {
  const readableError = error?.response?.data || error?.response || error;
  if (environment === 'production') {
    if (!window.logRocketInit) {
      window.LogRocket && window.LogRocket.init('yibjp2/lg-prod', { release: 'lettucegrow-' + process.env.REACT_APP_VERSION });
    }

    // timeout here to allow log rocket initialization before capturing the error
    setTimeout(() => {
      window.LogRocket && window.LogRocket.captureException(readableError);
    }, 2000);
  }
  console.error(readableError);
}
