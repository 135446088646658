import firebase from 'firebase/app';
import 'firebase/auth';
import { environment, authDomain } from './envConfig';

/**
 * * Firebase config variables
 */

let config = {
  apiKey: 'AIzaSyDcsBcfp2vtGuNRzrW_78nb_jdY7SBRtVs',
  authDomain,
  databaseURL: 'https://lg-shop-dev.firebaseio.com',
  projectId: 'lg-shop-dev',
  storageBucket: 'lg-shop-dev.appspot.com',
  messagingSenderId: '503487698221',
};

if (environment === 'production') {
  config = {
    apiKey: 'AIzaSyA2e_sAZKs0Htbxx-yfFApc7zdXP-VPZMc',
    authDomain,
    databaseURL: 'https://lg-production.firebaseio.com',
    projectId: 'lg-production',
    storageBucket: 'lg-production.appspot.com',
    messagingSenderId: '676992336298',
  };
} else if (environment === 'staging' || process.env.REACT_APP_BACKEND === 'staging') {
  config = {
    apiKey: 'AIzaSyCQ2GHfb4iKTPYakZZ2bfmr1AWcLkZQxJ4',
    authDomain,
    databaseURL: 'https://lg-staging.firebaseio.com',
    projectId: 'lg-staging',
    storageBucket: 'lg-staging.appspot.com',
    messagingSenderId: '914973842407',
  };
}

const firebaseApp = firebase.initializeApp(config);

export default firebaseApp;
