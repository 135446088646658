import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'ui-redesign/components';
import { Text, Title } from 'ui-redesign/elements';
import ResponsiveIframe from 'components/deprecated/ResponsiveIframe';

import { FarmstandReviewedStyled, ContentWrapper } from './FarmstandReviewed.styled';

const FarmstandReviewed = ({ title, body, video, modifiers }) => {
  const embedCode = `<iframe src='${video}' width='100%' height='150px' frameBorder='0' allow='autoplay; fullscreen' allowFullScreen title='Lettuce Grow' />`;
  return (
    <FarmstandReviewedStyled modifiers={modifiers}>
      <ContentSection>
        <ContentWrapper>
          <Title content={title} modifiers='secondarySmall' />
          <Text content={body} />
        </ContentWrapper>
        <ResponsiveIframe embedCode={embedCode} />
      </ContentSection>
    </FarmstandReviewedStyled>
  );
};

FarmstandReviewed.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  video: PropTypes.string,
  modifiers: PropTypes.any,
};

export default FarmstandReviewed;
