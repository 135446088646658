import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { ContentSection } from 'ui-redesign/components';
import { Text, Title, SquaredButton } from 'ui-redesign/elements';

import { ContentWrapper, FeaturedSpecsSection, AccordionWrapper } from './Featured.styled';

import AccordionSection from 'components/elements/AccordionSection';

const Item = ({ percentages }) =>
  percentages.map((item) => {
    const id = _get(item, 'sys.id');
    const title = _get(item, 'fields.title');
    const body = _get(item, 'fields.body');
    return (
      <AccordionSection key={id} title={title}>
        <Text content={body} isHTML />
      </AccordionSection>
    );
  });

const Featured = ({ title, body, percentages, callToAction, modifiers }) => {
  const url = _get(callToAction, 'fields.linkUrl') || _get(callToAction, 'fields.url');
  const isShowMoreButton = url === undefined;

  const [expanded, setExpanded] = useState(url === undefined);
  const showMoreLabel = expanded ? 'SHOW MORE' : 'SHOW LESS';
  const label = isShowMoreButton ? showMoreLabel : _get(callToAction, 'fields.linkText') || _get(callToAction, 'fields.text');

  const callToActionButtonRendered = isShowMoreButton ? (
    <SquaredButton label={label} onClick={() => setExpanded(!expanded)} />
  ) : (
    <SquaredButton as='button' label={label} href={url} />
  );

  const percentagesFiltered = expanded ? percentages.slice(0, 3) : percentages;
  return (
    <FeaturedSpecsSection modifiers={modifiers}>
      <ContentSection>
        <ContentWrapper>
          {title && <Title as='h3' modifiers='secondarySmall' content={title} />}
          {body && <Text content={body} />}
        </ContentWrapper>
        <AccordionWrapper>
          {percentages && <Item percentages={percentagesFiltered} />}
          {callToAction && callToActionButtonRendered}
        </AccordionWrapper>
      </ContentSection>
    </FeaturedSpecsSection>
  );
};

Featured.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  percentages: PropTypes.array,
  callToAction: PropTypes.object,
  modifiers: PropTypes.any,
};

export default Featured;
