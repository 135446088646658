import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { fontSize } from 'theme-redesign/Mixins';
import breakpoints from 'theme-redesign/Media';

export const modifiers = {
  xLarge: () => ({
    styles: fontSize(80) + `line-height: 1.25;`,
  }),
  small: () => ({
    styles: fontSize(45),
  }),
  xSmall: () => ({
    styles: fontSize(24) + `line-height: 1.25;`,
  }),
  tiny: () => ({
    styles: fontSize(14) + `line-height: normal; letter-spacing: normal;`,
  }),
  secondary: () => ({
    styles: fontSize(48) + `line-height: 1.25;`,
  }),
  secondarySmall: () => ({
    styles: fontSize(36) + `line-height: 1.25;`,
  }),
  secondaryLarge: () => ({
    styles: fontSize(65),
  }),
  tertiary: () => ({
    styles: fontSize(37),
  }),
  tertiaryXSmall: () => ({
    styles: fontSize(18) + 'line-height: 1.25; letter-spacing: 0.15em;',
  }),
  tertiarySmall: () => ({
    styles: fontSize(28) + 'line-height: 1.25;',
  }),
  tertiaryLarge: () => ({
    styles: fontSize(50),
  }),
  inverted: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.gray_3};
    `,
  }),
  brandColor: ({ theme }) => ({
    styles: `
      color: ${theme.farmProject.brand_primary};
    `,
  }),
  lineHeightNormal: () => ({
    styles: `
    line-height: normal;
  `,
  }),
  semiBold: () => ({
    styles: `
      font-weight: 500;
    `,
  }),
};

export const TitleStyled = styled.h1.attrs({
  className: 'Title',
})`
  font-family: ${({ theme }) => theme.farmProject.font_family};
  ${fontSize(60)};
  font-weight: 300;
  line-height: 4.6875rem;
  letter-spacing: 0.2rem;
  color: ${({ theme }) => theme.farmProject.dark_gray};
  padding: 0;
  margin: 0;

  @media (min-width: ${breakpoints.medium_break}) {
    letter-spacing: 0.5rem;
  }

  ${applyStyleModifiers(modifiers)};
`;
