import styled from 'styled-components/macro';

export default styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .Text {
    margin-left: 1em;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.farmProject.green : null)};
  border: ${({ theme }) => `1px solid ${theme.farmProject.green}`};
  width: 15px;
  color: ${({ theme }) => theme.farmProject.gray_3};
  height: 15px;
  border-radius: 100%;
  cursor: pointer;
`;

export const Input = styled.input`
  height: 7px;
  width: 7px;
  margin: 0;
  background-color: white;
  border-radius: 50%;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
`;
